export const shouldInputUpdate = (props, nextProps) => {
    if (props.name !== nextProps.name) {
        return true;
    }

    if (_.get(props.formik.values, props.name) !== _.get(nextProps.formik.values, props.name)) {
        return true;
    }

    if (_.get(props.formik.errors, props.name) !== _.get(nextProps.formik.errors, props.name)) {
        return true;
    }

    if (_.get(props.formik.touched, props.name) !== _.get(nextProps.formik.touched, props.name)) {
        return true;
    }

    if (props.placeholder !== nextProps.placeholder) {
        return true;
    }

    return Object.keys(props).length !== Object.keys(nextProps).length
};

export const getFieldError = (name, formik, callBack) => {
    if (!hasFieldError(name, formik)) {
        return '';
    }

    let errorText = _.get(formik.errors, name);

    if (typeof errorText !== 'string') {
        return '';
    }

    if (callBack) {
        errorText = callBack(errorText);
    }

    return errorText;
};

export const hasFieldError = (name, formik) => {
    return !!(_.get(formik.errors, name) && _.get(formik.touched, name));
};

export const getFieldValue = (name, formik, defaultValue) => {
    return _.get(formik.values, name, defaultValue);
};

export const handleFieldChange = (name, value, formik) => {
    formik.setFieldValue(name, value);
};

export const handleFieldBlur = (name, formik) => {
    formik.setFieldTouched(name);
};