import React, {useEffect, useState} from 'react';
import {Resource} from "@/services/transform/resource/resource";
import notification from "@/services/notification/notification";
import styles from '../../styles/styles';
import {Box, FormControlLabel, Switch, Typography} from "@mui/material";
import MemberAddressTransformer
    from "@/modules/UnionMember/services/transform/resource/transformers/Address/MemberAddressTransformer";
import {getMemberAddresses, updateMemberAddress} from "@/modules/UnionMember/services/api/addresses/addresses";
import CreateOrUpdateAddressModal
    from "@/layouts/components/Header/components/ProfileInformation/components/Addresses/components/CreateOrUpdateAddressModal";
import useTranslate from "@/components/UseTranslate/useTranslate";
import Pencil from "@/icons/Pencil";
import {
    addressTypeOptions
} from "@/layouts/components/Header/components/ProfileInformation/staticData/staticData";

const Addresses = () => {
    const [addresses, setAddresses] = useState(null);
    const [usedTypes, setUsedTypes] = useState([]);
    const [isOpenChangeAddressModal, setIsOpenChangeAddressModal] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);

    const {t} = useTranslate();

    useEffect(() => {
        fetchData().then(resp => {
            setUsedTypes(resp && resp.map(item => item.type));
            setAddresses(resp);
        })
    }, [])

    const fetchData = async () => {
        const response = await getMemberAddresses();
        return Resource.transform(MemberAddressTransformer, response.data, response.meta).data;
    }

    const updateAddressesData = () => {
        fetchData().then(resp => {
            setUsedTypes(resp && resp.map(item => item.type));
            setAddresses(resp);
        })
    }

    const updateIsDefaultStatus = async (data) => {
        data.is_default = !data.is_default;

        return await updateMemberAddress(data.id, data).then(() => {
            updateAddressesData();
            notification.success(t('alert_address_status_change'))
        });
    }

    const closeChangeAddressModal = () => {
        setIsOpenChangeAddressModal(false);
        setCurrentRow(null);
    }

    const openChangeAddressModal = (data) => {
        setIsOpenChangeAddressModal(true)
        setCurrentRow(data)
    }

    const addressTypeTranslations = {
        legal_residence: t('legal_residence'),
        residence: t('residence'),
        other: t('other'),
    };

    if (!addresses) {
        return;
    }

    return (
        <>
            <Box>
                <Typography variant={'p2'} className={'title'}>{t('residence')}</Typography>
                {
                    addresses.map((item, index) => {
                        return (
                            <Box key={index} sx={styles.itemBox}>
                                <Box sx={styles.preTitleBlock}>
                                    <Typography variant='p3Bold'
                                                className='item-name'>{addressTypeTranslations[_.find(addressTypeOptions, (option) => option.value === item.type).label]}</Typography>
                                    <Box className={'update-block'}>
                                        <Pencil sx={styles.pencilIcon} onClick={() => openChangeAddressModal(item)}
                                                color={'primary'}/>
                                    </Box>
                                </Box>

                                <Box sx={styles.switchBox}>
                                    <Typography variant={'p3'}>
                                        {`${item.address}, ${!!item.zip_code ? item.zip_code + ',' : ''} ${!!item.city ? item.city + ',' : ''} ${item.country}`}
                                    </Typography>
                                    <Box sx={styles.switch}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    focusVisibleClassName=".Mui-focusVisible" disableRipple
                                                    checked={item.is_default}
                                                    onChange={() => updateIsDefaultStatus(item)}
                                                    disabled={item.is_default}
                                                />
                                            }
                                            label=""
                                        />
                                        <Typography variant={'p3'}
                                                    className={'is-default'}>{t('default')}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                }
                <Box sx={styles.newAddressButton}>
                    <Typography className={'add-new-title'} variant={'p4'} onClick={() => openChangeAddressModal([])}>
                        {t('add_new_address')}
                    </Typography>
                </Box>
            </Box>

            <CreateOrUpdateAddressModal
                id={currentRow && currentRow.id}
                usedTypes={usedTypes}
                open={isOpenChangeAddressModal}
                onSuccess={updateAddressesData}
                onClose={closeChangeAddressModal}
            />
        </>
    )
}

export default Addresses;