import React, {useState} from 'react';
import styles from './styles/styles';
import {Box, Button, Card, CardContent, CardHeader, CardMedia, Typography} from "@mui/material";
import Map from "@/icons/Map";
import Link from "@/icons/Link";
import Mail from "@/icons/Mail";
import useTranslate from "@/components/UseTranslate/useTranslate";
import {kronaFormatter} from "@/services/formatters/kronaFromatter/kronaFormatter";
import {SERVICE_TYPES} from "@/modules/PVC/pages/Offer/staticData/staticData";
import OfferSkeleton from "@/modules/PVC/pages/Offer/components/Offers/components/OfferSkeleton/OfferSkeleton";

const Offer = props => {
    const {offer, onClick} = props;
    const [loading, setLoading] = useState(true);

    const {t} = useTranslate();

    const regions = _.uniq(offer.branches.filter(branch => branch.region).map(branch => branch.region.name)).join(', ');

    const serviceTypeIds = offer.branches.map(branch => branch.service_type_id);
    const checkServiceType = (type) => serviceTypeIds.find(typeId => typeId === type);

    if (loading) {
        return (
            <>
                <OfferSkeleton/>
                <img style={{'display': 'none'}} src={_.get(offer, 'image.url', '')} onLoad={() => setLoading(false)}/>
            </>
        )
    }

    return (
        <React.Fragment>
            <Card>
                <CardHeader sx={styles.header} title={
                    <>
                        <Map sx={styles.mapIcon}/>
                        <Typography variant={'p4Bold'}>{regions}</Typography>
                    </>
                }
                />

                <CardMedia
                    sx={styles.media}
                    image={_.get(offer, 'image.url', '')}
                    children={
                        <Box sx={styles.cardType}>
                            {
                                checkServiceType(SERVICE_TYPES.HOTEL) &&
                                <Typography variant={'p3'}>{t('hotel')}</Typography>
                            }
                            {
                                checkServiceType(SERVICE_TYPES.TRAVEL) &&
                                <Typography
                                    variant={'p3'}
                                    sx={checkServiceType(SERVICE_TYPES.HOTEL) && styles.travel}
                                >
                                    {t('travel')}
                                </Typography>
                            }
                        </Box>
                    }
                />

                <CardContent sx={styles.cardContent}>
                    <Box overflow={'hidden'} maxHeight={112}>
                        <Typography variant={'h4'} sx={styles.strongTitle}>
                            {_.get(offer, 'account.name', '')}
                        </Typography>

                        <Typography variant={'p3'} sx={styles.subTitle}>
                            {offer.short_description}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant={'p3Bold'} sx={styles.subTitlePrice}>
                            {t('price_from')}:
                            <Typography variant={'p3'} sx={styles.price}>
                                {kronaFormatter(offer.min_price, true)}
                            </Typography>
                        </Typography>

                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Link onClick={() => window.open(_.get(offer, 'account.url', ''))} color={'primary'}
                                      sx={styles.link}/>
                                <Mail
                                    onClick={() => window.location = `mailto:${_.get(offer, 'account.email', '')}`}
                                    color={'primary'} sx={styles.mail}
                                />
                            </Box>

                            <Button onClick={onClick} variant={'contained'} color={"primary"} sx={styles.cardBtn}>
                                <Typography variant={'p3'}>{t('details')}</Typography>
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </React.Fragment>
    )
};

export default Offer;