import React from 'react';
import Box from '@mui/material/Box';
import {withRouter} from "react-router-dom";
import Button from "@mui/material/Button";
import styles from './styles/styles';
import useTranslate from "@/components/UseTranslate/useTranslate";
import HamburgerIconLinearGradient from "@/icons/HamburgerIconLinearGradient";

const LeftSection = props => {
    const {onClick} = props;
    const {t} = useTranslate();

    return (
        <Box>
            <Button
                sx={styles.menuButton}
                aria-label={'menu'}
                onClick={onClick}
                size={'medium'}
                disableTouchRipple
                startIcon={<HamburgerIconLinearGradient/>}
            >
                {t('menu')}
            </Button>
        </Box>
    )
};

export default withRouter(LeftSection);