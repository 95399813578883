import React from "react";
import {SHOW_TYPE} from "@/components/Announcement/staticData/staticData";
import {GeneralAnnouncements, StaticAnnouncements} from "@/components/Announcement/Announcement";
import {useAnnouncements} from "@/providers/Announcements/Announcements";


const withAnnouncements = (WrappedComponent) => {
    return (props) => {
        const {announcements, closeAnnouncement, closeStaticAnnouncements} = useAnnouncements();

        const notStaticAnnouncements = [...announcements].filter(
            ({show_type, is_closed}) => show_type !== SHOW_TYPE.static && !is_closed
        );

        const staticAnnouncements = [...announcements].filter(
            ({show_type, is_closed}) => !is_closed && show_type === SHOW_TYPE.static
        );

        return (
            <>
                <StaticAnnouncements announcements={staticAnnouncements} onClose={closeStaticAnnouncements}/>
                <WrappedComponent {...props}/>

                {
                    <GeneralAnnouncements
                        announcements={notStaticAnnouncements}
                        onClose={closeAnnouncement}
                    />
                }
            </>
        )
    }
}

export default withAnnouncements;