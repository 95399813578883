import React from "react";
import Transformer from 'services/transform/resource/transformers/Transformer';
import {
    Resource,
} from 'services/transform/transform';
import ServiceDetailTransformer
    from "@/modules/Accommodation/services/transform/resource/transformers/ServiceDetail/ServiceDetail";

class ServiceTransformer extends Transformer {
    static transform(services, meta) {
        const {serviceDetail,...result} = services;

        if (serviceDetail) {
            result.serviceDetail = Resource.transform(ServiceDetailTransformer, serviceDetail.data).data;
        }

        return result;
    }
}

export default ServiceTransformer;