import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

const HamburgerIconLinearGradient = (props) => {
    return (
        <SvgIcon  {...props}>
                <path fillRule="evenodd" clipRule="evenodd" d="M0.664062 7.33317C0.664062 6.59679 1.26102 5.99984 1.9974 5.99984H20.6641C21.4004 5.99984 21.9974 6.59679 21.9974 7.33317C21.9974 8.06955 21.4004 8.6665 20.6641 8.6665H1.9974C1.26102 8.6665 0.664062 8.06955 0.664062 7.33317Z" fill="url(#paint0_linear_7555_23324)"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0.664062 1.99984C0.664062 1.26346 1.26102 0.666504 1.9974 0.666504H25.9974C26.7338 0.666504 27.3307 1.26346 27.3307 1.99984C27.3307 2.73622 26.7338 3.33317 25.9974 3.33317H1.9974C1.26102 3.33317 0.664062 2.73622 0.664062 1.99984Z" fill="url(#paint1_linear_7555_23324)"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0.664062 12.6665C0.664062 11.9301 1.26102 11.3332 1.9974 11.3332H25.9974C26.7338 11.3332 27.3307 11.9301 27.3307 12.6665C27.3307 13.4029 26.7338 13.9998 25.9974 13.9998H1.9974C1.26102 13.9998 0.664062 13.4029 0.664062 12.6665Z" fill="url(#paint2_linear_7555_23324)"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0.664062 17.9998C0.664062 17.2635 1.26102 16.6665 1.9974 16.6665H20.6641C21.4004 16.6665 21.9974 17.2635 21.9974 17.9998C21.9974 18.7362 21.4004 19.3332 20.6641 19.3332H1.9974C1.26102 19.3332 0.664062 18.7362 0.664062 17.9998Z" fill="url(#paint3_linear_7555_23324)"/>
                <defs>
                    <linearGradient id="paint0_linear_7555_23324" x1="27.3307" y1="0.666503" x2="9.79158" y2="25.7224" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF9174"/>
                        <stop offset="1" stopColor="#F04A4F"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_7555_23324" x1="27.3307" y1="0.666503" x2="9.79158" y2="25.7224" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF9174"/>
                        <stop offset="1" stopColor="#F04A4F"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_7555_23324" x1="27.3307" y1="0.666503" x2="9.79158" y2="25.7224" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF9174"/>
                        <stop offset="1" stopColor="#F04A4F"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_7555_23324" x1="27.3307" y1="0.666503" x2="9.79158" y2="25.7224" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF9174"/>
                        <stop offset="1" stopColor="#F04A4F"/>
                    </linearGradient>
                </defs>
        </SvgIcon>
    );
};

export default HamburgerIconLinearGradient;