import React from "react";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import HotelInfoCard from "@/modules/PVC/pages/HotelInfoCard/HotelInfoCard";
import Details from "@/modules/PVC/pages/Details/Details";
import NotFound from "@/pages/NotFound/NotFound";
import Login from "@/pages/Login/Login";
import PaymentResult from "@/modules/Accommodation/pages/PaymentResult/PaymentResult";
import Loader from "@/components/Loader/Loader";
import AudkenniLogin from "@/pages/AudkenniLogin/AudkenniLogin";
import DisabledAccount from "../../pages/DisabledAccount/DisabledAccount";

const PublicRoutes = (props) => {

    return (
        <React.Suspense fallback={<Loader/>}>
            <Switch>
                {/*Disabled Account*/}
                <Route path="/:lang(is|en)?/disabled_account" component={DisabledAccount}/>

                <Route path='/:lang(is|en)?/:page(not_found)' component={NotFound}/>
                <Route path={"/:lang(is|en)?/:page(login)"} component={Login}/>
                <Route path={"/:lang(is|en)?/:page(audkenni_login)"} component={AudkenniLogin}/>
                <Route exact path="/" component={Login}/>

                {/*Payment result pages*/}
                <Route path={"/:lang(is|en)?/:module(acm)/:page(payment_result)"} component={PaymentResult}/>

                {/*PVC routes*/}
                <Route path="/:lang(is|en)?/:module(pvc)/:page(hotel)" component={HotelInfoCard}/>
                <Route path="/:lang(is|en)?/:module(pvc)/:page(details)" component={Details}/>

                {/*Non Union Member routes*/}
                {
                    // <Route path="/:lang(is|en)?/:module(non_unm)/:page(login)" component={NonUNMLogin}/>
                }

                <Redirect
                    to={{
                        pathname: '/login',
                        search: `?redirect=${props.location.pathname}`,
                    }}
                />
            </Switch>
        </React.Suspense>
    )
};

export default withRouter(PublicRoutes);