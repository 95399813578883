import * as api from 'services/api/api';

export const loginPageOpened = (data) => {
    return api.postRequest('/unm/tracker', data)
}
export const clickOnLogin = (data) => {
    return api.postRequest('/unm/tracker', data)
}

export const clickOnRegisterProperty = (data) => {
    return api.postRequest('/unm/tracker', data)
}
