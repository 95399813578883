const styles = {
    price: (theme) => ({
        minWidth: 85,
        marginRight: theme.spacing(1),
        fontFamily: 'OpenSans',
        paddingLeft: theme.spacing(1),

        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(1)} 0 0`
        },
    }),
    periodDates: (theme) => ({
        fontFamily: 'OpenSans',
        color: theme.palette.grey[600],
        paddingLeft: theme.spacing(0.5),
    }),
    periodTitleBox: (theme) => ({
        display: 'flex',
        paddingBottom: theme.spacing(0.75),

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    }),
    periodTitle: (theme) => ({
        color: theme.palette.grey[600]
    }),
    viewOfferBtn: () => ({
        height: 24,
        width: 24,
        '& svg': {
            cursor: 'pointer',
        }
    }),
    offer: (theme) => ({
        margin: 0,
        padding: `${theme.spacing(2)} 0`
    }),
    offerBox: (theme) => ({
        width: '100%',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    }),
    buyBtn: (theme) => ({
        padding: '13px 16px',

        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
    }),
    divider: () => ({
        width: '100%',
    }),
    valueBox: (theme) => ({
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.spacing(2)} ${theme.spacing(2)} 0`,
        margin: `${theme.spacing(3)} ${theme.spacing(3)} 0`,
        background: theme.palette.common.white,
        borderRadius: '5px',
        border: `1px solid ${theme.palette.grey[200]}`
    }),
    periodBox: (theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(2),
    })
};

export default styles;