import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

const Plus = (props) => {
    return (
        <SvgIcon  {...props}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5ZM13 11H11V13H13V11Z"/>
            <path d="M13 11H11V13H13V11Z"/>
        </SvgIcon>
    );
};

export default Plus;