import {palette} from "@/themes/main/options/options";

const styles = {
    selectFieldWrapper: () => ({
        position: 'relative',
        cursor: 'pointer',

        '& input, & .MuiInputBase-root': {
            cursor: 'pointer'
        },

        "& .MuiPopper-root[data-popper-placement='bottom-start']": {
            '& + div': {
                "& .Mui-focused": {
                    '&:not(.Mui-readOnly):not(.Mui-error)': {
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: `1px solid ${palette.grey[200]}`,
                            borderBottom: 'none',
                            boxShadow: 'unset'
                        },
                    },
                },

                '.MuiOutlinedInput-notchedOutline': {
                    borderBottom: 'none',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                },
            },
        },

        "& .MuiPopper-root[data-popper-placement='top-start']": {
            '& + div': {
                "& .Mui-focused": {
                    '&:not(.Mui-readOnly):not(.Mui-error)': {
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: `1px solid ${palette.grey[200]}`,
                            borderTop: 'none',
                            boxShadow: 'unset'
                        },
                    },
                },

                '.MuiOutlinedInput-notchedOutline': {
                    borderTop: 'none',
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                },
            },
        },
    }),
    selectPopper: (theme) => ({
        borderRadius: '0 0 3px 3px',
        width: '100%',
        zIndex: theme.zIndex.drawer,
        backgroundColor: theme.palette.common.white,

        "&[data-popper-placement='bottom-start']": {
            border: `1px solid ${theme.palette.grey[200]}`,
            borderTop: 'unset',
            borderRadius: `0 0 4px 4px`
        },

        "&[data-popper-placement='top-start']": {
            border: `1px solid ${theme.palette.grey[200]}`,
            borderBottom: 'unset',
            borderRadius: `4px 4px 0 0`
        },

        "& .MuiPaper-root": {
            border: 'unset !important',
            boxShadow: 'unset !important'
        }
    }),
    arrowIcon: (theme, disabled) => ({
        color: theme.palette.primary.main,
        opacity: disabled ? 0.4 : 1,
    })
}

export default styles
