const styles = {
    selectFieldWrapper: () => ({
        position: 'relative',
        cursor: 'pointer',

        '& input, & .MuiInputBase-root': {
            cursor: 'pointer'
        },
    }),
    selectPopper: (theme) => ({
        borderRadius: '0 0 3px 3px',
        width: '100%',
        zIndex: theme.zIndex.drawer,
        backgroundColor: theme.palette.common.white,
    }),
    arrowIcon: (theme, disabled) => ({
        color: theme.palette.primary.main,
        opacity: disabled ? 0.4 : 1,
    })
}

export default styles