import Transformer from 'services/transform/resource/transformers/Transformer';
import {Resource} from 'services/transform/transform';
import {PropertyTransformer} from 'modules/Accommodation/services/transform/transform';

class PropertyGroupTransformer extends Transformer {
    static transform(propertyGroup, meta) {
        const {properties, ...result} = propertyGroup;

        if (properties) {
            result.properties = Resource.transform(PropertyTransformer, properties.data).data;
        }

        return result;
    }
}

export default PropertyGroupTransformer;