import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Heading from './components/Heading/Heading';
import styles from './styles/styles';

const Header =  React.forwardRef((props, ref) => {
    const {openMenu, position, opacity, hasOffset, hasShadow} = props;

    return (
        <>
            <AppBar
                ref={ref}
                color={'inherit'}
                position={position}
                sx={(theme) => styles.header(theme, {hasShadow, opacity})}
            >
                <Toolbar sx={styles.toolbar}>
                    <Heading
                        openMenu={openMenu}
                    />
                </Toolbar>
            </AppBar>
            {hasOffset && <Box sx={styles.offset}/>}
        </>
    );
});

Header.defaultProps = {
    position: 'relative',
    hasOffset: true,
    hasShadow: false,
    opacity: 1,
};

Header.propTypes = {
    position: PropTypes.string,
    hasOffset: PropTypes.bool,
    hasShadow: PropTypes.bool,
    opacity: PropTypes.number,
};

export default Header;