import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from './styles/styles'
import Button from "@mui/material/Button";
import useTranslate from "@/components/UseTranslate/useTranslate";
import {useAuth} from "providers/Auth/AuthProvider";
import {clickOnLogin, loginPageOpened} from "@/modules/NonUNM/services/api/tracker/tracker";
import NotFoundMemberModal
    from "@/pages/Login/components/IslandisLoginPage/components/NotFoundMemberModal/NotFoundMemberModal";
import {withRouter} from "react-router-dom";

const TRACKER_TYPES = {
    OPENED_PAGE: 1,
    CLICKED_LOGIN_BTN: 2,
    CLICKED_REGISTER_PROPERTY: 3
}
const STATUS_NOT_FOUND_MEMBER = 403;

const IslandisLoginPage = props => {
    const {loginPageUrl, isSpecificUnion} = props;
    const [openMemberNotFoundModal, setOpenMemberNotFoundModal] = useState(false);

    const {accountInterface} = useAuth();
    const {t} = useTranslate();

    const query = new URLSearchParams(props.location.search);
    const statusCode = query.get('statusCode');

    useEffect(() => {
        if (isSpecificUnion) {
            loginPageOpened({type: TRACKER_TYPES.OPENED_PAGE})
                .then(() => {
                })
        }

        if (+statusCode === STATUS_NOT_FOUND_MEMBER) {
            setOpenMemberNotFoundModal(prevState => !prevState);
        }
    }, []);

    const redirectToIslandis = () => {
        if (isSpecificUnion) {
            clickOnLogin({type: TRACKER_TYPES.CLICKED_LOGIN_BTN})
                .then(() => {
                    window.location.replace(loginPageUrl);
                })
        } else {
            window.location.replace(loginPageUrl);
        }
    }

    return (
        <Box sx={styles.wrapper}>
            <Box sx={styles.contentBox}>
                <Box sx={(theme) => styles.titleBox(theme, isSpecificUnion)}>
                    {
                        isSpecificUnion &&
                        <Typography sx={styles.contentTitle}>
                            {`${accountInterface?.account?.name}`}
                        </Typography>
                    }
                    {
                        !isSpecificUnion &&
                        <Typography sx={styles.contentTitle}>
                            {`${accountInterface?.account?.name}, `}
                            {t('title_orlofsvefur')}
                        </Typography>
                    }
                    <Typography sx={styles.contentParagraph}>{t('subtitle_orlofsvefur')}</Typography>
                </Box>
                <Box sx={styles.buttonBox}>
                    <Button variant={'contained'} color={'primary'}
                            onClick={redirectToIslandis}>{t('check_in')}</Button>
                </Box>
                {
                    isSpecificUnion &&
                    <>
                        <Box mt={17.5}>
                            <Typography variant={'h2'} sx={styles.leigutorg_text}>
                                {t('do_you_have_property')}
                            </Typography>
                        </Box>
                        <Box mt={3}>
                            <Button
                                variant={'contained'}
                                sx={styles.leigutorg_btn}
                                onClick={() => {
                                    clickOnLogin({type: TRACKER_TYPES.CLICKED_REGISTER_PROPERTY})
                                        .then(() => {
                                        })
                                    window.open('https://island.is/skra-fasteign-til-leigu', '_blank')
                                }}
                            >
                                {t('register_property')}
                            </Button>
                        </Box>
                    </>
                }

                <Box sx={styles.footerContentBox}>
                    {
                        !isSpecificUnion &&
                        <Typography variant='p4' sx={styles.footerContentBoxTitle}>
                            {t('description_privacy_policy')}
                            {` ${accountInterface?.account?.name}.`}
                        </Typography>
                    }
                </Box>
            </Box>
            <NotFoundMemberModal
                open={openMemberNotFoundModal}
                onClose={() => setOpenMemberNotFoundModal(false)}
                isSpecificUnion={isSpecificUnion}
            />
        </Box>
    );
}

export default withRouter(IslandisLoginPage);