const styles = {
    root: (theme) => ({
        padding: theme.spacing(3)
    }),
    title: (theme) => ({
        color: theme.palette.grey[600],
    }),
    description: (theme) => ({
        color: theme.palette.grey[700],

        '& p': {
            marginBottom: 0
        }
    }),
    btnBlock: () => ({
        height: 20,
        width: 'max-content',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer'
    }),
    viewTermsBtn: (theme) => ({
        '& svg': {
            height: 24,
            width: 24,
            marginRight: theme.spacing(0.5),
            cursor: 'pointer',
            verticalAlign: 'middle'
        }
    })
};

export default styles;