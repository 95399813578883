import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'formik';
import {SelectField} from './components/SelectField/SelectField';
import {getFieldValue, handleFieldBlur, handleFieldChange} from "../../services";

const ActiveSelect = props => {
    const {formik, onBlur, onChange, ...selectProps} = props;
    const handleChange = (name, option) => {
        if (onChange) {
            onChange(props.name, option);
        } else {
            handleFieldChange(name, option, formik);
        }
    };

    const handleBlur = (name, option) => {
        if (onBlur) {
            onBlur(name, option);
        } else {
            handleFieldBlur(name, formik);
        }
    };

    const value = selectProps.value || getFieldValue(props.name, formik);

    return (
        <SelectField
            {...selectProps}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            formik={formik}
        />
    )
};

ActiveSelect.defaultProps = {
    margin: 'dense',
    fullWidth: true,
};

ActiveSelect.propTypes = {
    name: PropTypes.string.isRequired,
    onError: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

export default connect(ActiveSelect);