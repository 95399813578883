import Transformer from "services/transform/resource/transformers/Transformer";
import {Resource} from "@/services/transform/resource/resource";
import {PropertyTransformer, BookingTransformer} from 'modules/Accommodation/services/transform/transform';

class ApplicationRowTransformer extends Transformer {
    static transform(applicationRow, meta) {
        const {property, propertyGroup, booking, ...result} = applicationRow;

        if (property) {
            result.property = Resource.transform(PropertyTransformer, property.data, property.meta).data;
        }

        if (propertyGroup) {
            result.propertyGroup = propertyGroup.data
        }

        if (booking) {
            result.booking = Resource.transform(BookingTransformer, booking.data, booking.meta).data;
        }

        return result;
    }
}

export default ApplicationRowTransformer;