import Transformer from 'services/transform/resource/transformers/Transformer';
import {Resource, UserTransformer} from 'services/transform/resource/resource';

class ActivityTransformer extends Transformer {
    static transform(activity, meta) {
        const {user, ...result} = activity;

        if (user) {
            result.user = Resource.transform(UserTransformer, user.data, user.meta).data;
        }

        return result;
    }
}

export default ActivityTransformer;