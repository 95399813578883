const styles = {
    mainBox: theme => ({
        position: "absolute",
        marginLeft: "129px",
        maxWidth: "472px",
        width: "100%",
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            maxWidth: "343px",
        },
        [theme.breakpoints.only('sm')]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            maxWidth: "343px",
        }
    }),
    formBox: theme => ({
        maxWidth: "472px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: '7px',
        boxShadow: "0px 4px 16px 0px"
    }),
    inputBox: () => ({
        display: "flex",
        flexDirection: "column",
    }),
    buttonBox: theme => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(3),
    }),
    titleBox: theme => ({
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(2),
        }
    }),
    title: theme => ({
        ...theme.typography.h1,
        color: theme.palette.grey[50],
        [theme.breakpoints.down('md')]: {
            ...theme.typography.h3,
            color: theme.palette.grey[50],
        }
    })
}

export default styles;