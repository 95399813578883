import React, {useContext, useEffect, useState} from "react";
import {Box, ClickAwayListener, Drawer, IconButton} from "@mui/material";
import {TreeItem, TreeView} from "@mui/lab";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {withRouter} from "react-router-dom";
import styles from "./styles/styles";
import Close from "@/icons/Close";
import {MenuContext} from "@/providers/MenuProvider/MenuProvider";
import getMenuItems from "@/layouts/components/Menu/items";
import RefundModal from "@/modules/PVC/components/RefundModal/RefundModal";
import useTranslate from "@/components/UseTranslate/useTranslate";
import {useAuth} from "@/providers/Auth/AuthProvider";

const Menu = props => {
    const {accountInterface} = useAuth();

    const {onClose, open: menuOpen} = props;
    const [refundModalOpen, setRefundModalOpen] = useState(null);
    const [expandedItems, setExpandedItems] = useState([]);
    const menuData = useContext(MenuContext);
    const menuItems = getMenuItems(menuData);

    const {t} = useTranslate();

    const path = props.location.pathname;

    useEffect(() => {
        const [expandedItemsNode] = getExpandedActiveItem(menuItems, path);
        setExpandedItems([expandedItemsNode]);
    }, [path]);


    const {account} = accountInterface;
    const unionLogo = account?.union_logo?.value;

    const goToPage = (item) => {
        const {callback} = item;

        if (callback) {
            return callback();
        }

        if (item.modal) {
            openModal(item.modal);
        } else {
            const {history} = props;
            history.push(item.path);
        }
    };

    const openModal = (modal) => {
        if (modal === 'refund-modal') {
            setRefundModalOpen(true);
        }
    }

    const redirectTo = (path) => {
        if (!path) {
            return;
        }

        window.location.href = path;
    };

    const onMenuItemClick = (item) => {
        const isItemIncludes = _.includes(expandedItems, item.nodeId);

        if (isItemIncludes) {
            const newExpandedItems = expandedItems.filter((nodeId) => nodeId !== item.nodeId);

            setExpandedItems(newExpandedItems);
        } else {
            if (item.items && item.items.length > 0) {
                setExpandedItems([item.nodeId, ...expandedItems]);
            }
        }
        return goToPage(item);
    }

    const drawListItems = (items) => {

        return items.map((item, index) => {
            if (!item.show) {
                return null;
            }

            const visibleItems = _.filter(item.items || [], {show: true});

            let activeClassName = '';
            if (item.path === path) {
                activeClassName = item?.items ? 'Mui-selected' : 'Mui-selected one-root-item';
            }

            const isRootItem = item?.sx?.name === 'itemRoot';
            const sxClassName = isRootItem ? styles.itemRoot : styles.itemChild;

            if (!visibleItems.length) {
                return (
                    <TreeItem
                        key={`${item.nodeId}${index}`}
                        nodeId={item.nodeId}
                        label={item?.no_translate ? item.label : t(item.label)}
                        onClick={() => onMenuItemClick(item)}
                        className={activeClassName}
                        sx={sxClassName}
                    />
                )
            } else {
                return (
                    <TreeItem
                        key={`${item.nodeId}${index}`}
                        nodeId={item.nodeId}
                        label={item?.no_translate ? item.label : t(item.label)}
                        onClick={() => onMenuItemClick(item)}
                        className={activeClassName}
                        sx={sxClassName}
                    >
                        {drawListItems(item.items)}
                    </TreeItem>
                )
            }
        })
    }

    const getExpandedActiveItem = (menuItems, path) => {
        let expandedItemsNode = null;
        let activeItemNode = null;

        for (let menuItem of menuItems) {

            if (menuItem.path === path) {
                activeItemNode = menuItem.nodeId;
                break;
            }

            let activeItem = _.find(menuItem.items, {path: path});

            if (activeItem) {
                expandedItemsNode = menuItem.nodeId;
                activeItemNode = activeItem.nodeId;
                break;
            }
        }

        return [expandedItemsNode, activeItemNode];
    }

    return (
        <Box>
            <ClickAwayListener
                touchEvent='onTouchStart'
                mouseEvent='onMouseDown'
                onClickAway={() => menuOpen && onClose()}
            >
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={menuOpen}
                    sx={styles.drawer}
                >
                    <Box sx={styles.menuLogoContainer}>
                        <IconButton
                            onClick={onClose}
                            sx={styles.menuButton}
                            aria-label={'menu'}
                        >
                            <Close/>
                        </IconButton>
                        <Box
                            onClick={() => redirectTo(account && account.url)}
                            sx={(theme) => styles.logo(theme, unionLogo)}
                        >
                        </Box>
                    </Box>
                    <TreeView
                        defaultCollapseIcon={<ExpandLessIcon/>}
                        defaultExpandIcon={<ExpandMoreIcon/>}
                        sx={styles.root}
                        expanded={expandedItems}
                    >
                        {drawListItems(menuItems)}
                    </TreeView>
                </Drawer>
            </ClickAwayListener>

            <RefundModal
                open={refundModalOpen}
                onClose={() => setRefundModalOpen(false)}
            />
        </Box>
    )
};

export default withRouter(Menu);