import Box from "@mui/material/Box";
import React from 'react';
import storage from "@/services/storage/storage";
import styles from "./styles/styles"

const logo = () => {
    const {account} = storage.get('interface', {})
    const unionLogo = account?.union_logo?.value

    return (
        <Box
            component={'img'}
            src={unionLogo}
            sx={styles.logo}
        />
    )
}

export default logo;