import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Box from '@mui/material/Box';
import styles from './styles/styles'
import Menu from "@/layouts/components/Menu/Menu";
import withAnnouncements from "@/components/Announcement/hoc/withAnnouncements";

const footerHeight = {
    footerLGHeight: 165,
    footerMDHeight: 284,
    footerSMHeight: 346,
}

const Main = props => {
    const {HeaderProps, showFooter} = props;
    const [menuOpen, setMenuOpen] = useState(false);

    const openMenu = () => setMenuOpen(true);
    const closeMenu = () => setMenuOpen(false);

    return (
        <Box sx={styles.root}>
            <Box className={classNames('layout-main', {'withoutFooter': !showFooter})}>
                <Box className={`page-${props.page}`} sx={showFooter ?(theme) => styles.wrapper(theme, footerHeight): ''}>

                    <Header openMenu={openMenu} {...HeaderProps}/>

                    <div className={`page page-${props.page}`}>
                        <main>
                            {props.children}
                        </main>
                    </div>

                    <Menu
                        open={menuOpen}
                        onClose={closeMenu}
                    />
                </Box>

                {showFooter && <Footer footerHeight={footerHeight}/>}
            </Box>
        </Box>
    )
};

Main.propTypes = {
    HeaderProps: PropTypes.object,
    showFooter: PropTypes.bool,
};

Main.defaultProps = {
    HeaderProps: {},
    showFooter: true,
};

export default withAnnouncements(Main);