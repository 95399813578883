export const styles = {
    root: (theme) => ({
        marginLeft: theme.spacing(0.5),
    }),
    menu: (theme) => ({
        '& .MuiPaper-root': {
            marginTop: `-${theme.spacing(1)}`,
            right: 48,
            left: 'unset !important',
            maxWidth: 'unset',
            maxHeight: 'unset',
            boxShadow: `0 2px ${theme.spacing(2)} 0 ${theme.palette.hexToRGBA(theme.palette.common.black, 0.08)}`,
            backgroundColor: theme.palette.common.white,
            borderRadius: '5px',
        },

        '& .MuiList-root': {
            padding: 0,
        },

        [theme.breakpoints.down('md')]: {
            '& .MuiPaper-root': {
                top: '117px !important',
                left: `calc(50% - 164px) !important`,
                right: `calc(50% - 164px)`,
            },
        },
    }),
}