import Transformer from 'services/transform/resource/transformers/Transformer';

class BranchOfferTransformer extends Transformer {
    static transform(offer, meta) {
        const {...result} = offer;

        return result;
    }
}

export default BranchOfferTransformer;