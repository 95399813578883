import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import useTranslate from "@/components/UseTranslate/useTranslate";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";

let AutoCompleteField = props => {
    const {
        name,
        value,
        label,
        placeholder,
        variant,
        size,
        loadOptions,
        onBlur,
        onChange,
        loadingText,
        noOptionsText,
        hasError,
        ...autoCompleteProps
    } = props;

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState(props.options);

    const {t} = useTranslate();

    const loading = open && options.length === 0;

    useEffect(() => {
        if (!open) {
            loadOptions().then(resp => {
                setOptions([...resp])
            })
        }
    }, open)

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        onBlur(name, value);
    };

    const handleChange = (e, value, reason) => {
        onChange(name, value, reason);
    };

    return (
        <Autocomplete
            {...autoCompleteProps}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionLabel={option => option.value}
            options={options}
            onChange={handleChange}
            loading={loading}
            disablePortal={true}
            loadingText={t(loadingText)}
            noOptionsText={t(noOptionsText)}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    defaultValue={value?.value}
                    variant={variant}
                    size={size}
                    error={hasError}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
};

AutoCompleteField.defaultProps = {
    hasError: false,
    options: [],
    loadingText: 'auto_complete.loading',
    noOptionsText: 'auto_complete.no_options',
    onBlur: () => undefined,
    onChange: () => undefined,
};

AutoCompleteField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    loadOptions: PropTypes.func,
    loadingText: PropTypes.string,
    noOptionsText: PropTypes.string,
    hasError: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

export {AutoCompleteField}