import Transformer from '../Transformer';
import {AccountTransformer, UserTransformer, Resource} from 'services/transform';

class InvoiceTransformer extends Transformer {
    static transform(invoice, meta) {
        const {createdByUser, providedFromAccount, payer, items, ...result} = invoice;

        if (providedFromAccount) {
            result.by_account = Resource.transform(AccountTransformer, providedFromAccount.data, providedFromAccount.meta).data;
        }

        if (createdByUser) {
            result.created_by = Resource.transform(UserTransformer, createdByUser.data, createdByUser.meta).data;
        }

        if (payer) {
            result.payer = Resource.transform(UserTransformer, payer.data, payer.meta).data;
        }

        if (items) {
            result.items = items.data;
        }

        return result;
    }
}

export default InvoiceTransformer;