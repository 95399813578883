import Transformer from "services/transform/resource/transformers/Transformer";
import {Resource} from "services/transform/resource/resource";
import {PropertyGroupTransformer, PropertyTransformer} from "modules/Accommodation/services/transform/transform";

class ApplicationPeriodTransformer extends Transformer {
    static transform(applicationPeriod, meta) {
        const {season, properties, propertyGroups, ...result} = applicationPeriod;

        if (season) {
            result.season = season.data
        }

        if (properties) {
            result.properties = Resource.transform(PropertyTransformer, properties.data).data;
        }

        if (propertyGroups) {
            result.propertyGroups = Resource.transform(PropertyGroupTransformer, propertyGroups.data).data;
        }

        return result;
    }
}

export default ApplicationPeriodTransformer;