import Transformer from 'services/transform/resource/transformers/Transformer';
import {Resource} from 'services/transform/transform';
import {PropertyParameterTransformer} from 'modules/Accommodation/services/transform/transform';

class PropParamGroupTransformer extends Transformer {
    static transform(propParamGroup, meta) {
        const {parameters, icon, ...result} = propParamGroup;

        if (parameters) {
            result.parameters = Resource.transform(PropertyParameterTransformer, parameters.data, parameters.meta).data;
        }

        if (icon) {
            result.icon = icon.data;
        }

        return result;
    };
}

export default PropParamGroupTransformer;