import React from 'react';
import Box from "@mui/material/Box";

import {styles} from './styles/styles';

const Loader = () => (
    <Box sx={styles.root}>
        <Box sx={styles.loader}>
            <Box/>
            <Box/>
            <Box/>
            <Box/>
            <Box/>
            <Box/>
            <Box/>
            <Box/>
            <Box/>
        </Box>
    </Box>
);

export default Loader;