import Transformer from "../Transformer";
import {ImageTransformer, AccountTransformer, Resource} from 'services/transform';
import {BranchTransformer} from 'modules/PVC/services/transform';

class OfferTransformer extends Transformer {
    static transform(offer, meta) {
        const {image, account, branches, periods, ...result} = offer;

        if (image && image.data) {
            result.image = Resource.transform(ImageTransformer, image.data, image.meta).data;
        }

        if (account && account.data) {
            result.account = Resource.transform(AccountTransformer, account.data, account.meta).data;
        }

        if (branches && branches.data) {
            result.branches = Resource.transform(BranchTransformer, branches.data, branches.meta).data;
        }

        if (periods && periods.data) {
            result.periods = periods.data;
        }

        return result;
    }
}

export default OfferTransformer;