export const styles = {
    root: (theme) => ({
        width: 328,
        height: 'auto',
        maxHeight: 380,
    }),
    closeIconBox: () => ({
        height: 40,
    }),
    closeIcon: () => ({
        cursor: 'pointer',
    }),
    content: (theme) => ({
        margin: `0 ${theme.spacing(3)}`,
    }),
    userInfo: (theme) => ({
        marginBottom: `${theme.spacing(2)}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }),
    name: (theme) => ({
        marginBottom: '6px',
        color: theme.palette.grey[800],
    }),
    divider: (theme) => ({
        borderColor: theme.palette.grey[200],
    }),
    menuItemTitle: (theme) => ({
        color: theme.palette.primary.main,
        cursor: 'pointer',
        height: 20,
    }),
    amountTitle: (theme) => ({
        marginRight: '4px',
        color: theme.palette.grey[800],
    }),
}