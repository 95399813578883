import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import main from './themes/main/main';
import {createRoot} from "react-dom/client";
import App from './app/App';
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import {init as initSentry} from '@sentry/react';
import AuthProvider from "@/providers/Auth/AuthProvider";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const root = createRoot(document.getElementById('frimann-member-side'));

initSentry({dsn: "https://381a3371b883477099e9da509d67ed66@o415234.ingest.sentry.io/5392033"});

root.render((
    <ThemeProvider theme={main}>
        <BrowserRouter>
            <AuthProvider>
                <Switch>
                    <Route path="/:lang(is|en)/:page(\w+)" component={App}/>
                    <Route path="/:lang(is|en)" component={App}/>
                    <Route path="/:page(\w+)" component={App}/>
                    <Route path="/" component={App}/>
                </Switch>
            </AuthProvider>
        </BrowserRouter>
    </ThemeProvider>
));