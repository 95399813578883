import React from 'react';
import {connect} from 'formik';
import Box from "@mui/material/Box";

import ProfileImage from "@/layouts/components/Header/components/ProfileImage/ProfileImage";
import {styles} from './styles/styles';
import {getAuthData, updateAuthUser} from "@/services/api/auth/auth";
import notification from "@/services/notification/notification";
import {useAuth} from "@/providers/Auth/AuthProvider";
import useTranslate from "@/components/UseTranslate/useTranslate";

const ImageInput = () => {
    const {user, updateUser} = useAuth();
    const {t} = useTranslate();
    const acceptedFileTypes = ['image/jpg', 'image/png', 'image/jpeg'];

    const saveChangeValue = (e) => {
        const source = e.target.files[0];
        const url = URL.createObjectURL(e.target.files[0]);
        const data = {};
        const avatar = {source, url}


        if (avatar && avatar.source) {
            data.avatar = avatar.source;
        }

        const formData = new FormData();

        for (let key in data) {
            formData.append(key, data[key]);
        }

        return updateAuthUser(formData);
    }

    const onChange = (e) => {
        const [firstFile] = e.target.files;
        const extension = firstFile.type;

        const acceptedFileExtensions = acceptedFileTypes.map((file) => file.split('/').pop() + ' ') + '';

        if (!acceptedFileTypes.includes(extension)) {
            return notification.error(t('profile_image.invalid_file', {extensions: acceptedFileExtensions}))
        }

        saveChangeValue(e).then(() => {
            getAuthData().then(data => {
                updateUser(data.user.data);
            });
        });
    }

    return (
        <label htmlFor="avatar">
            <ProfileImage
                size={48}
                iconSize={48}
                status={!!user.status}
                src={_.get(user, 'avatar.url')}
                user={user}
                showStatus={false}
            />
            <Box sx={styles.input}>
                <input
                    type={'file'}
                    id='avatar'
                    name={'avatar'}
                    onChange={(e => onChange(e))}
                />
            </Box>
        </label>
    )
};

export default connect(ImageInput);