import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {kronaFormatter} from "services/formatters/kronaFromatter/kronaFormatter";
import useTranslate from "components/UseTranslate/useTranslate";
import styles from "./styles/styles";
import {useAuth} from "providers/Auth/AuthProvider";

const MemberInfo = props => {
    const {t} = useTranslate();

    const {user, checkAccess} = useAuth();

    return (
        user &&
        <>
            <Box display={'flex'}>
                <Typography component={'span'} variant={'p4Bold'} sx={styles.amountTitle}>
                    {t(`total_points`)}:
                </Typography>
                <Typography component={'span'} variant={'p4'}>
                    {user?.points ? user.points : 0} {t('points')}
                </Typography>
            </Box>
            {
                !!user.member_credits &&
                <Box display={'flex'} mt={0.5}>
                    <Typography component={'span'} variant={'p4Bold'} sx={styles.amountTitle}>
                        {t('credit_history')}:
                    </Typography>
                    <Typography component={'span'} variant={'p4'}>
                        {kronaFormatter(user.member_credits, true, 0)}
                    </Typography>
                </Box>
            }
            {
                checkAccess('pvc_member_privileges') &&
                <Box display={'flex'} mt={0.5}>
                    <Typography component={'span'} variant={'p4Bold'} sx={styles.amountTitle}>
                        {t('travelers_check')}:
                    </Typography>
                    <Typography component={'span'} variant={'p4'}>
                        {kronaFormatter(user.pvc, true, 0)}
                    </Typography>
                </Box>
            }
        </>
    )
};

export default MemberInfo;