import Transformer from 'services/transform/resource/transformers/Transformer';
import {Resource} from 'services/transform/transform';
import {PropertyTypeNameTransformer} from 'modules/Accommodation/services/transform/transform';

class PropertyTypeTransformer extends Transformer {
    static transform(type, meta) {
        const {name, names, ...result} = type;

        if (name) {
            result.name = Resource.transform(PropertyTypeNameTransformer, name.data).data;
        }

        if (names) {
            result.names = Resource.transform(PropertyTypeNameTransformer, names.data, names.meta).data;
        }

        return result;
    }
}

export default PropertyTypeTransformer;