import Transformer from "services/transform/resource/transformers/Transformer";
import {ApplicationRowTransformer} from "@/modules/Accommodation/services/transform/resource/transformers/transformers";
import {Resource} from 'services/transform/transform';

class ApplicationTransformer extends Transformer {
    static transform(application, meta) {
        const {rows, period, user, creator, ...result} = application;

        if (user) {
            result.user = user.data
        }

        if (creator) {
            result.creator = creator.data
        }

        if (rows) {
            result.rows = Resource.transform(ApplicationRowTransformer, rows.data, rows.meta).data;
        }

        if (period) {
            result.period = period.data
        }


        return result;
    }
}

export default ApplicationTransformer;