import React from "react";
import {Box, Button, Dialog, Typography} from "@mui/material";
import styles from "@/components/Announcement/styles/styles";
import useTranslate from "@/components/UseTranslate/useTranslate";
import {parseToHTML} from "@/services/formatters/parseToHtml/parseToHtml";

const AnnouncementModal = (props) => {
    const {announcement, open, onClose} = props;

    const {t} = useTranslate();

    return (
        <Dialog
            open={open}
            maxWidth={false}
            className={'modal'}
            sx={styles.announcementModal}
            onClose={onClose}
        >
            <Box className={'modalHeader'}>
                <Box className={'modalHeaderInner'}>
                    <Typography variant={'h4'}>{announcement.name}</Typography>
                </Box>
            </Box>
            <Box className={'modalBody'}>
                <Typography
                    variant={'p3'}
                    dangerouslySetInnerHTML={{__html: parseToHTML(announcement.content)}}
                />
            </Box>
            <Box className={'modalFooter'}>
                <Box className={'modalFooterInner'}>
                    <Button
                        type={'button'}
                        variant={'contained'}
                        color={'secondary'}
                        onClick={onClose}
                        fullWidth
                    >
                        {t('close')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}

export default AnnouncementModal;