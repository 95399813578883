import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import styles from './styles/styles';
import Heading from "@/layouts/Public/components/Header/components/Heading/Heading";

const Header = React.forwardRef((props, ref) => {
    const {position, opacity, hasOffset, hasShadow} = props;

    return (
        <Box>
            <AppBar
                ref={ref}
                color={'inherit'}
                position={position}
                sx={(theme) => styles.header(theme, {hasShadow, opacity})}
            >
                <Heading/>
            </AppBar>
            {hasOffset && <Box sx={styles.offset}/>}
        </Box>
    );
});

Header.defaultProps = {
    position: 'relative',
    hasOffset: true,
    hasShadow: false,
    opacity: 1,
};

Header.propTypes = {
    position: PropTypes.string,
    hasOffset: PropTypes.bool,
    hasShadow: PropTypes.bool,
    opacity: PropTypes.number,
};

export default Header;