import React from 'react';
import PropTypes from "prop-types";
import Box from '@mui/material/Box';
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import useTranslate from "components/UseTranslate/useTranslate";
import {styles} from './styles/styles';


const PrivacyPolicyModal = props => {
    const {open, onClose} = props;

    const {t} = useTranslate();

    return (
        <Dialog
            open={open}
            className={'modal'}
            onClose={onClose}
            sx={styles.root}
        >
            <Box className={'modalHeader'}>
                <Box className={'modalHeaderInner'}>
                    <Typography variant={'h4'}>
                        {t('title_privacy_policy')}
                    </Typography>
                </Box>
            </Box>

            <Box className={'modalBody'}>
                <Typography component={'p'} variant="p3" sx={styles.text}>
                    {t('privacy_policy_start')}
                </Typography>
                <Box mt={2}>
                    <Typography component={'p'} variant="p3" sx={styles.text}>
                        {t('privacy_policy_end')}
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography component={'p'} variant="p3Bold" sx={styles.text}>
                        {t('privacy_policy_info')}
                    </Typography>
                    <Typography component={'p'} variant="p3" sx={styles.text}>
                        {t('privacy_policy_first_text')}
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography component={'p'} variant="p3Bold" sx={styles.text}>
                        {t('legal_info')}
                    </Typography>
                    <Typography component={'p'} variant="p3" sx={styles.text}>
                        {t('text_legal_info')}
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography component={'p'} variant="p3Bold" sx={styles.text}>
                        {t('info_sharing')}
                    </Typography>
                    <Typography component={'p'} variant="p3" sx={styles.text}>
                        {t('text_info_sharing')}
                    </Typography>
                </Box>
            </Box>

            <Box className={'modalFooter'}>
                <Box className={'modalFooterInner'}>
                    <Button
                        variant={'contained'}
                        color={'secondary'}
                        fullWidth
                        onClick={onClose}
                    >
                        {t('close')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}

PrivacyPolicyModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

PrivacyPolicyModal.defaultProps = {
    open: false,
    onClose: () => undefined,
};

export default PrivacyPolicyModal;