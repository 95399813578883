const styles = {
    header: (theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.grey[50],

        '& .header-title': {
            padding: theme.spacing(3),
            color: theme.palette.grey[800],
        },

        '& .close-icon': {
            margin: theme.spacing(2),
            cursor: 'pointer'
        }
    }),
    divider: (theme) => ({
        borderColor: theme.palette.grey[200],
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        width: 'calc(100% + 48px)',
        marginLeft: '-24px'
    }),
    modalFooter: (theme) => ({
        backgroundColor: theme.palette.grey[50],
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        padding: theme.spacing(2),
        bottom: 0,
        position: 'fixed',
        width: '652px',

        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(1),
        },
        '& .modalFooterInner': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    }),
    emailBox: (theme) => ({
        background: theme.palette.common.white,
        padding: theme.spacing(3),

        '& .title': {
            color: theme.palette.grey[800],
            fontWeight: 700
        },

        '& .item-name': {
            color: theme.palette.grey[800],
        },

        '& .is-default': {
            color: theme.palette.grey[700],
            marginLeft: theme.spacing(1)
        },
    }),

    switchBox: (theme) => ({
        '& .MuiFormControlLabel-root': {
            marginRight: 0,
        },

        [theme.breakpoints.up('md')]: {
            display: 'flex', justifyContent: 'space-between', alignItems: 'center',

        },

        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(1),
            width: '100%',
        },
    }),

    switch: (theme) => ({
        marginLeft: 'auto',
        position: 'relative',
        bottom: '30px',

        [theme.breakpoints.down('md')]: {
            bottom: 0,
            marginLeft: '10px',
            marginTop: '10px',

        },
    }),

    itemBox: (theme) => ({
        paddingTop: theme.spacing(3),
    }),

    newAddressButton: (theme) => ({
        marginTop: theme.spacing(3),

        '& .add-new-title': {
            cursor: 'pointer',
            color: theme.palette.primary.main
        }
    }),

    preTitleBlock: (theme) => ({
        display: 'flex',
        alignItems: 'center',
        height: 32,

        [theme.breakpoints.down('md')]: {
            width: '100%'
        },

        '& .update-block': {
            display: 'flex',
            alignSelf: 'center',
            width: 'max-content',
            cursor: 'pointer',
            marginLeft: theme.spacing(1),
        }
    }),

    pencilIcon: () => ({
        width: 18,
        height: 18
    }),

    emailModal: (theme) => ({
        '& .MuiDialog-paper': {
            maxWidth: 630,
            width: 630,
        },


        '& .delete-email-btn-disabled': {
            color: theme.palette.hexToRGBA(theme.palette.error.main, 0.3),
            width: 'max-content'
        },

        '& .delete-email-btn': {
            color: theme.palette.error.main,
            cursor: 'pointer',
            width: 'max-content',
        }
    }),

    deleteModalText: (theme) => ({
        color: theme.palette.grey[800],
        paddingBottom: theme.spacing(3)
    }),

    deleteModalValue: (theme) => ({
        color: theme.palette.grey[800],
        fontWeight: 700
    }),

    codeNumberRootBlock: (theme) => ({
        display: 'flex',
        justifyContent: 'space-between',

        '& .code': {
            width: 160,
        },

        '& .number': {
            width: '100%',
            paddingLeft: theme.spacing(3),

            '& .MuiInputBase-root': {
                width: '100%'
            }
        }
    }),

    addressRow: () => ({
        display: 'flex',
        justifyContent: 'space-between',

        '& .MuiInputBase-root': {
            width: '100%'
        }
    }),
    countryLabel: (theme) => ({
        color: theme.palette.grey[700],
        marginBottom: theme.spacing(0.5),
        fontWeight: 700,
        display: 'flex',
    }),
    countryLabelSign: (theme) => ({
        color: theme.palette.error.main,
        marginLeft: '2px',
    }),
    selectOptionBox: () => ({
        '& .MuiInputBase-input': {
            padding: '0px !important'
        },

        '& .MuiInputBase-root': {
            padding: '13px 16px !important'
        }
    })
}

export default styles