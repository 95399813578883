import React from "react";
import {modalsConfig} from './modalConfigs';

let subs = new Set();
let modal = null;

export const currentModal = {
    subscribe(handler) {
        if (typeof handler === 'function') {
            subs.add(handler);
        }

        return () => {
            subs.delete(handler);
        };
    },

    set(currentModal) {
        modal = currentModal;
        subs.forEach((handler) => {
            handler(modal);
        });
    },

    get(modal) {
        return modalsConfig[modal] || null;
    }
};
