import React, {useEffect, useRef, useState} from 'react';
import {parseToHTML} from "@/services/formatters/parseToHtml/parseToHtml";
import {Box, Typography} from "@mui/material";
import useTranslate from "@/components/UseTranslate/useTranslate";
import styles from './styles/styles';
import Map from "@/icons/Map";
import ArrowDropDown from "@/icons/ArrowDropDown";
import ArrowDropUp from "@/icons/ArrowDropUp";

const lessDescHeight = 90;

const GeneralInfo = props => {
    const {data} = props;
    const [showToggleBtn, setShowToggleBtn] = useState(false);
    const [showAllDesc, setShowAllDesc] = useState(false);

    let timer = null;
    const descRef = useRef();

    const {t} = useTranslate();

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const handleResize = () => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            updateToggleBtn();
        })
    };

    useEffect(() => {
        updateToggleBtn();
    }, [descRef]);

    const updateToggleBtn = () => {
        if (descRef.current) {
            const descHeight = descRef.current.scrollHeight;
            const showToggleBtn = descHeight > lessDescHeight;

            setShowToggleBtn(showToggleBtn);
        }
    };

    const toggleShowAllDesc = () => {
        setShowAllDesc(!showAllDesc);
    };

    return (
        <Box sx={styles.root}>
            <Box sx={styles.branchBlock}>
                <Typography variant={'p1Bold'} sx={styles.branchTitle}>
                    {data.name}
                </Typography>
                <Box sx={styles.mapBtnBox}>
                    <Map sx={styles.mapIcon}/>
                    <Typography variant={'p4Bold'}>{data.region}</Typography>
                </Box>
            </Box>

            <Box sx={styles.content}>
                <Box sx={(theme) => styles.branchImage(theme, props.data.image_url)}/>
                <Box sx={styles.infoBox}>
                    <Typography variant={'p3Bold'}>
                        {data.address}
                    </Typography>
                    <Box className={'phone-info'}>
                        <Typography variant={'p4'}>
                            {data.phone}
                        </Typography>
                        <Typography
                            onClick={() => window.location = `mailto:${data.email}`}
                            className={'email-info'} variant={'p4'}
                        >
                            {data.email}
                        </Typography>
                        <Typography
                            onClick={() => window.open(data.url)}
                            className={'url-info'} variant={'p4'}
                        >
                            {data.url}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {
                data.description &&
                <Box ref={descRef} sx={showAllDesc ? styles.allDesc : styles.minDesc}>
                    <Typography
                        sx={styles.description}
                        variant={'p3'}
                        component={'p'}
                        align={'left'}
                        dangerouslySetInnerHTML={{
                            __html: parseToHTML(data.description)
                        }}
                    >
                    </Typography>
                </Box>
            }
            {
                showToggleBtn &&
                <Box sx={styles.moreBox}>
                    <Box onClick={toggleShowAllDesc} sx={styles.viewMoreBtn}>
                        {showAllDesc ? <ArrowDropDown color={'primary'}/> : <ArrowDropUp color={'primary'}/>}
                    </Box>
                    <Typography variant={'p4Bold'} sx={styles.viewMoreText}>
                        {showAllDesc ? t('view_less') : t('view_more')}
                    </Typography>
                </Box>
            }
        </Box>
    )
};

export default GeneralInfo;