import Transformer from "services/transform/resource/transformers/Transformer";
import {ImageTransformer} from "@/services/transform/resource/transformers/transformers";
import {BranchOfferTransformer} from "@/modules/PVC/services/transform/resource/transformers/transformers";
import {Resource} from "@/services/transform/resource/resource";

class BranchTransformer extends Transformer {
    static transform(branch) {
        const {image, region, offers, ...result} = branch;

        if (image && image.data) {
            result.image = Resource.transform(ImageTransformer, image.data, image.meta).data;
        }

        if (region && region.data) {
            result.region = region.data;
        }

        if (offers && offers.data) {
            result.offers = Resource.transform(BranchOfferTransformer, offers.data, offers.meta).data;
        }

        return result;
    }
}

export default BranchTransformer;