export const ICELAND = {
    country: 'Iceland',
    code: '+354',
}

export const emailTypeOptions = [
    {label: 'personal', value: 0},
    {label: 'work', value: 1},
    {label: 'other', value: 2},
]

export const phoneNumberTypeOptions = [
    {label: 'mobile', value: 0},
    {label: 'home', value: 1},
    {label: 'work', value: 2},
    {label: 'other', value: 3},
]

export const addressTypeOptions = [
    {label: 'legal_residence', value: 0},
    {label: 'residence', value: 1},
    {label: 'other', value: 2},
]