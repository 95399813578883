import React from "react";
import {currentModal} from "@/components/GlobalModals/currentModal";

export const useModal = (name) => {
    const open = (props) => {
        currentModal.set({name, props: props})
    }

    const close = () => {
        currentModal.set(null)
    }

    return {open, close}
}