import React, {useEffect, useState} from 'react';
import notification from 'services/notification/notification';
import Alert from './components/Alert/Alert';
import storage from "@/services/storage/storage";
import styles from "./styles/styles";
import {Box} from "@mui/material";

const Notifications = () => {
    const [notifications, setNotifications] = useState(null);

    useEffect(() => {
        storage.watch('notifications', handleChangeNotifications);
    }, []);

    const handleChangeNotifications = (notifications) => {
        setNotifications(notifications);
    };

    if (!notifications) {
        return null;
    }

    return (
        <Box sx={styles.notificationsWrapper}>
            {
                notifications.map((alert,key) => (
                    <Alert key={key} data={alert} hideTimeout={10000} onClose={notification.remove}/>
                ))
            }
        </Box>
    );
}

export default Notifications;