const styles = {
    logo: (theme) => ({
        maxWidth: 200,
        maxHeight: 43,

        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(2)
        },
    }),
}

export default styles;