import React from 'react';
import MuiSlider from '@mui/material/Slider'

const Slider = props => {
    return (
        <MuiSlider {...props}/>
    )
};

Slider.defaultProps = {
    color: 'primary',
};

export {Slider};