import * as api from 'services/api/api';

export const getMemberEmails = (data) => {
    return api.getRequest('/unm/member_emails', data)
}

export const getMemberEmail = (id, data) => {
    return api.getRequest(`/unm/member_emails/${id}`, data)
}

export const updateMemberEmail = (id, data) => {
    return api.putRequest(`/unm/member_emails/${id}`, data);
}

export const createMemberEmail = (data) => {
    return api.postRequest(`/unm/member_emails`, data);
}

export const deleteMemberEmail = (id) => {
    return api.deleteRequest(`/unm/member_emails`, id);
}