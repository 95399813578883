import Transformer from 'services/transform/resource/transformers/Transformer';
import {Resource} from 'services/transform/transform';
import {ApplicationPeriodTransformer} from 'modules/Accommodation/services/transform/transform';

class RentingTransformer extends Transformer {
    static transform(renting, meta) {
        const {activeApplicationPeriods, applicationPeriods, ...result} = renting;

        if (applicationPeriods) {
            result.applicationPeriods = Resource.transform(ApplicationPeriodTransformer, applicationPeriods.data, applicationPeriods.meta).data;
        }

        if (activeApplicationPeriods) {
            result.activeApplicationPeriods = Resource.transform(ApplicationPeriodTransformer, activeApplicationPeriods.data).data;
        }

        return result;
    }
}

export default RentingTransformer;