import React from 'react'
import {Redirect, Route} from 'react-router-dom';
import Forbidden from "pages/Forbidden/Forbidden";
import {useAuth} from "@/providers/Auth/AuthProvider";

const PrivateRoute = ({component: Component, permission, ...rest}) => {
    const {user, checkAccess} = useAuth();

    const isGuest = !user;

    if (isGuest) {
        return <Redirect to={'/login'}/>;
    }

    let hasAccess = true;

    if (permission) {
        const permissions = _.isArray(permission) ? permission : [permission];
        hasAccess = permissions.some(checkAccess);
    }

    const ComponentToRender = hasAccess ? Component : Forbidden;

    return (
        <Route {...rest} render={props => {
            return <ComponentToRender {...props}/>;
        }}/>
    );
};

export default PrivateRoute;