const styles = {
    card: (theme) => ({
        width: "100%",
        height: 542,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "3px 3px 0px 0px",
        border: `1px solid ${theme.palette.grey[200]}`,
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(3),
    }),

    cardHeader: (theme) => ({
        width: "100%",
        padding: theme.spacing(1.5)
    }),

    cardContent: (theme) => ({
        padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
        width: "100%",
        height: 246,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2)
    }),

    cardHeaderLocation: () => ({
        display: "flex",
        justifyContent: "space-around",
        width: 100
    }),
    cardHeaderBox: () => ({
        display: "flex",
        justifyContent: "space-between",
    }),
    smallIcon: (theme) => ({
        width: theme.spacing(2),
        height: theme.spacing(2)
    }),
    region: () => ({
        width: 80,
        height: 16,
    }),
    size: () => ({
        width: 51,
        height: 16,
    }),
    offerImage: () => ({
        width: '100%',
        height: 252,
    }),
    title: () => ({
        width: '100%',
        height: 34,
    }),
    description: () => ({
        display: "flex",
        gap: "11px",
        flexDirection: "column"
    }),
    fullRaw: () => ({
        width: '100%',
        height: 22,
    }),
    halfRaw: () => ({
        width: 150,
        height: 22,
    }),
    descriptionContentBox: () => ({
        width: '100%',
        height: 48,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }),
    bigIcon: (theme) => ({
        width: 24,
        height: 24,
        marginRight: theme.spacing(0.5)
    }),
    button: () => ({
        width: 83,
        height: 48,
        borderRadius: "3px"
    }),
};

export default styles