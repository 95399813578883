import Warning from "@/icons/Warning";
import Info from "@/icons/Info";
import Error from "@/icons/Error";
import Success from "@/icons/Success";
import theme from '@/themes/main/main'

const notificationTypes = {
    'success': {icon: Success, color: theme.palette.success.main},
    'warning': {icon: Warning, color: theme.palette.warning.main},
    'error': {icon: Error, color: theme.palette.error.main},
    'info': {icon: Info, color: theme.palette.info.main}
}

export default notificationTypes;