const styles = {
    langButton: (theme, isLight) => ({
        backgroundColor: isLight ? theme.palette.common.white : theme.palette.grey[800],
        padding: '7px 10px 7px 8px',
        borderRadius: theme.spacing(0.5),
        color: isLight ? theme.palette.grey[700] : theme.palette.grey[50],

        '&:hover': {
            backgroundColor: isLight ? theme.palette.common.white : theme.palette.grey[800],
        },
    }),
    langIcon: (theme, isLight) => ({
        fontSize: `22px !important`,
        color: isLight ? theme.palette.grey[700] : theme.palette.grey[50],
    }),
    langMenu: (theme) => ({
        width: '256px',
        backgroundColor: theme.palette.common.white,
        paddingBottom: theme.spacing(3),
        borderRadius: '5px',
    }),
    closeIconBox: () => ({
        height: 40,
    }),
    closeIcon: () => ({
        cursor: 'pointer',
    }),
    langOptionsBox: (theme) => ({
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    }),
    langOption: () => ({
        cursor: 'pointer',
    }),
    langLabel: (theme, isActive) => ({
        color: isActive ? theme.palette.primary.main : theme.palette.grey[700],
        '&:hover': {
            color: theme.palette.primary.main,
        },
    }),
    divider: (theme) => ({
        height: '1px',
        backgroundColor: theme.palette.grey[200],
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    })
}

export default styles;