import React from 'react';
import {withRouter} from 'react-router-dom';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';

import CloseIcon from "icons/Close";
import ImageInput from "@/layouts/components/Header/components/ProfileForm/components/ImageInput/ImageInput";
import Status from "./components/Status/Status";
import useTranslate from "components/UseTranslate/useTranslate";
import {styles} from "./styles/styles";
import {useAuth} from "@/providers/Auth/AuthProvider";
import MemberInfo from "modules/UnionMember/components/MemberInfo/MemberInfo";

const ProfileView = props => {
    const {onDetailsButtonClick, onClose} = props;
    const {t} = useTranslate();

    const {user, logout} = useAuth();


    return (
        user &&
        <Box sx={styles.root}>
            <Box textAlign={'right'} mb={1}>
                <Box p={1} sx={styles.closeIconBox}>
                    <CloseIcon color={'primary'} onClick={onClose} sx={styles.closeIcon}/>
                </Box>
            </Box>

            <Box sx={styles.content}>
                <Box sx={styles.userInfo}>
                    <Box>
                        <ImageInput name={'avatar'} {...user}/>
                    </Box>
                    <Box>
                        <Status active={!!user.status}/>
                    </Box>
                </Box>

                <Box mb={2}>
                    <Typography component={'p'} variant={'p3Bold'} sx={styles.name}>
                        {user.name}
                    </Typography>
                    <Typography component={'p'} variant={'p4'}>
                        <Box component={'span'} mr={1}>
                            {t(`ssn_short`)}
                        </Box>
                        {`${user.ssn.substring(0, 6)}` + '-' + `${user.ssn.substring(6, 10)}`}
                    </Typography>
                </Box>

                <Divider sx={styles.divider}/>

                <Box mt={2} mb={2}>
                    <MemberInfo/>
                </Box>

                <Divider sx={styles.divider}/>

                <Box mt={2} mb={2}>
                    <Typography variant={'p4'} component={'p'} sx={styles.menuItemTitle} onClick={onDetailsButtonClick}>
                        {t('personal_banking_info')}
                    </Typography>
                </Box>

                <Divider sx={styles.divider}/>

                <Box mt={2} mb={2}>
                    <Typography variant={'p4'} component={'p'} sx={styles.menuItemTitle} onClick={logout}>
                        {t('logout')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
};

export default withRouter(ProfileView);