const styles = {
    header: (theme) => ({
        padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
        height: 44,
        borderWidth: '1px 1px 0 1px',
        borderStyle: 'solid',
        borderColor: theme.palette.grey[200],
        borderRadius: '3px 3px 0 0',
        backgroundColor: theme.palette.common.white,
        gap: theme.spacing(0.5),
        '& span': {
            display: 'flex',
            alignItems: 'center'
        },
    }),
    media: (theme) => ({
        position: 'relative',
        height: 252,

        [theme.breakpoints.down('md')]: {
            height: 196
        },

        [theme.breakpoints.down('sm')]: {
            height: 193
        },
    }),
    cardType: (theme) => ({
        position: 'absolute',
        height: 38,
        borderRadius: '3px',
        backgroundColor: theme.palette.common.white,
        bottom: 16,
        left: 16,
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        '& span': {
            fontFamily: 'OpenSansBold',
            color: theme.palette.grey[800]
        }
    }),
    subTitle: (theme) => ({
        maxHeight: 66,
        overflow: 'hidden',
        fontFamily: 'OpenSans',
        color: theme.palette.grey[800],
        paddingBottom: theme.spacing(2)
    }),
    subTitlePrice: (theme) => ({
        paddingBottom: theme.spacing(2)
    }),
    price: (theme) => ({
        fontFamily: 'OpenSans',
        color: theme.palette.grey[800],
        paddingLeft: theme.spacing(0.5)
    }),

    cardBtn: (theme) => ({
        padding: '13px 16px',
        borderRadius: '3px',
        gap: theme.spacing(2),
        '& span': {
            color: theme.palette.grey[50]
        }
    }),
    strongTitle: (theme) => ({
        paddingBottom: theme.spacing(2)
    }),
    mapIcon: (theme) => ({
        fontSize: 16,
        marginRight: theme.spacing(0.5)
    }),

    cardContent: (theme) => ({
        height: 246,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
        borderWidth: '0 1px 1px 1px',
        borderStyle: 'solid',
        borderColor: theme.palette.grey[200],
        borderRadius: '0 0 3px 3px',

        '&:last-child': {
            paddingBottom: theme.spacing(2),
        }
    }),

    travel: (theme) => ({
        borderLeft: `1px solid ${theme.palette.grey[200]}`,
        marginLeft: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    }),

    link: (theme) => ({
        marginRight: theme.spacing(2),
        cursor: 'pointer'
    }),
    mail: () => ({
        cursor: 'pointer'
    }),
}

export default styles;