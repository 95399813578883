import * as api from "../";

export const getSettings = async (accountId, data) => {
    const result = {};

    await api.getRequest(`/acc/settings`, data).then(resp => {
        resp.data.forEach(item => {
            result[item.name] = item;
        });
    });

    return result;
};