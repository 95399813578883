const styles = {
    root: (theme) => ({
        background: theme.palette.grey[50],
        padding: `0 ${theme.spacing(3)}`
    }),
    branchBlock: (theme) => ({
        display: 'flex',
        paddingBottom: theme.spacing(3),
        flexDirection: 'column',
    }),
    content: (theme) => ({
        display: 'flex',
        paddingBottom: theme.spacing(3),

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },
    }),
    infoBox: (theme) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(3),

        '& .email-info, .url-info': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            fontFamily: 'OpenSans',
            paddingTop: theme.spacing(0.5),
        },

        '& .phone-info': {
            color: theme.palette.grey[700],
            display: 'flex',
            flexDirection: 'column'
        },

        [theme.breakpoints.down('md')]: {
            height: 142,
            marginTop: theme.spacing(3),
            paddingLeft: 0,
        }
    }),
    branchImage: (theme, image_url) => ({
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${image_url})`,
        width: 252,
        height: 142,

        [theme.breakpoints.down('md')]: {
            height: 194,
            width: '100%',
        }
    }),
    branchTitle: (theme) => ({
        color: theme.palette.grey[800],
    }),
    mapBtnBox: (theme) => ({
        paddingTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center'
    }),
    mapIcon: (theme) => ({
        fontSize: 16,
        marginRight: theme.spacing(0.5),
    }),
    minDesc: () => ({
        maxHeight: 86,
        overflow: 'hidden',
    }),

    allDesc: () => ({
        height: 'max-content',
        overflow: 'visible',
    }),
    description: () => ({
        '& p': {
            margin: 0,
        }
    }),
    moreBox: (theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: `${theme.spacing(2.5)} 0 0`
    }),
    viewMoreBtn: (theme) => ({
        '& svg': {
            cursor: 'pointer',
            width: 24,
            height: 24,
            marginRight: theme.spacing(0.5),
            verticalAlign: 'middle'
        }
    }),
    viewMoreText: (theme) => ({
        color: theme.palette.grey[600],
    })
};

export default styles;