import React, {useEffect, useState} from 'react';
import {Resource} from "@/services/transform/resource/resource";
import useTranslate from "@/components/UseTranslate/useTranslate";
import notification from "@/services/notification/notification";
import styles from '../../styles/styles';
import {Box, FormControlLabel, Switch, Typography} from "@mui/material";
import MemberPhoneNumberTransformer
    from "@/modules/UnionMember/services/transform/resource/transformers/PhoneNumber/MemberPhoneNumberTransformer";
import {
    getMemberPhoneNumbers,
    updateMemberPhoneNumber
} from "@/modules/UnionMember/services/api/phoneNumbers/phoneNumbers";
import CreateOrUpdatePhoneNumberModal
    from "@/layouts/components/Header/components/ProfileInformation/components/PhoneNumbers/components/CreateOrUpdatePhoneNumberModal";
import Pencil from "@/icons/Pencil";
import {
    phoneNumberTypeOptions
} from "@/layouts/components/Header/components/ProfileInformation/staticData/staticData";

const PhoneNumbers = () => {
    const [phoneNumbers, setPhoneNumbers] = useState(null);
    const [usedTypes, setUsedTypes] = useState([]);
    const [isOpenChangePhoneNumberModal, setIsOpenChangePhoneNumberModal] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);

    const {t} = useTranslate();

    useEffect(() => {
        fetchData().then(resp => {
            setUsedTypes(resp && resp.map(item => item.type));
            setPhoneNumbers(resp);
        })
    }, [])

    const fetchData = async () => {
        const response = await getMemberPhoneNumbers();
        return Resource.transform(MemberPhoneNumberTransformer, response.data, response.meta).data;
    }

    const updatePhoneNumbersData = () => {
        fetchData().then(resp => {
            setUsedTypes(resp && resp.map(item => item.type));
            setPhoneNumbers(resp);
        })
    }

    const updateIsDefaultStatus = async (data) => {
        data.is_default = !data.is_default;

        return await updateMemberPhoneNumber(data.id, data).then(() => {
            updatePhoneNumbersData();
            notification.success(t('alert_phone_status_change'))
        });
    }

    const closeChangePhoneNumberModal = () => {
        setIsOpenChangePhoneNumberModal(false);
        setCurrentRow(null);
    }

    const openChangePhoneNumberModal = (data) => {
        setIsOpenChangePhoneNumberModal(true)
        setCurrentRow(data)
    }

    const phoneNumberTypeTranslations = {
        mobile: t('mobile'),
        home: t('home'),
        work: t('work'),
        other: t('other'),
    };

    if (!phoneNumbers) {
        return;
    }

    return (
        <>
            <Box>
                <Typography variant={'p2'} className={'title'}>{t('phone_number')}</Typography>
                {
                    phoneNumbers.map((item, index) => {
                        return (
                            <Box key={index} sx={styles.itemBox}>
                                <Box sx={styles.preTitleBlock}>
                                    <Typography variant='p3Bold'
                                                className='item-name'>{phoneNumberTypeTranslations[_.find(phoneNumberTypeOptions, (option) => option.value === item.type).label]}</Typography>
                                    <Box className={'update-block'}>
                                        <Pencil sx={styles.pencilIcon} onClick={() => openChangePhoneNumberModal(item)}
                                                color={'primary'}/>
                                    </Box>
                                </Box>
                                <Box sx={styles.switchBox}>
                                    <Typography variant={'p3'}>
                                        {`+${item.code} ${item.number.slice(0, 3)}-${item.number.slice(3)}`}
                                    </Typography>
                                    <Box sx={styles.switch}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    focusVisibleClassName=".Mui-focusVisible" disableRipple
                                                    checked={item.is_default}
                                                    onChange={() => updateIsDefaultStatus(item)}
                                                    disabled={item.is_default}
                                                />
                                            }
                                            label=""
                                        />
                                        <Typography variant={'p3'}
                                                    className={'is-default'}>{t('default')}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                }
                <Box sx={styles.newAddressButton}>
                    <Typography className={'add-new-title'} variant={'p4'}
                                onClick={() => openChangePhoneNumberModal([])}>
                        {t('add_new_phone_number')}
                    </Typography>
                </Box>
            </Box>

            <CreateOrUpdatePhoneNumberModal
                id={currentRow && currentRow.id}
                usedTypes={usedTypes}
                open={isOpenChangePhoneNumberModal}
                onSuccess={updatePhoneNumbersData}
                onClose={closeChangePhoneNumberModal}
            />
        </>
    )
}

export default PhoneNumbers;