import React from 'react';
import {Grid} from "@mui/material";

export default () => (
    <div className="page-content page-forbidden">
        <Grid container>
            <Grid item lg={12}>
                <p>Page is forbidden</p>
            </Grid>
        </Grid>
    </div>
)
