const styles = {
    wrapper: () => ({
        position: 'absolute'
    }),

    contentBox: (theme) => ({
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(5),
            marginLeft: '129px'
        },
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(10),
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3)
        },
        [theme.breakpoints.only('sm')]: {
            marginTop: theme.spacing(5),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2)
        }

    }),
    titleBox: (theme, isSpecificUnion) => ({
        marginBottom: theme.spacing(isSpecificUnion ? 3 : 6)
    }),
    contentTitle: theme => ({
        ...theme.typography.h1,
        color: theme.palette.grey[50],

        [theme.breakpoints.down('md')]: {
            ...theme.typography.h3
        },
        maxWidth: '897px',
        marginBottom: theme.spacing(2),
    }),
    contentParagraph: theme => ({
        ...theme.typography.p1,
        [theme.breakpoints.down('md')]: {
            ...theme.typography.p3
        },
        maxWidth: '638px',
        color: theme.palette.common.white,
    }),
    buttonBox: theme => ({
        marginBottom: theme.spacing(3)
    }),
    footerContentBox: () => ({
        maxWidth: "596px",
        height: '74px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }),
    footerContentBoxTitle: theme => ({
        color: theme.palette.common.white
    }),
    leigutorg_text: theme => ({
        color: theme.palette.common.white,
    }),
    leigutorg_btn: theme => ({
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black
    }),
    not_found_member_modal: (theme) => ({
        '& .MuiDialog-paper': {
            maxWidth: 732,
            width: 732,
        },
    }),
}

export default styles;