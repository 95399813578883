const styles = {
    root: (theme) => ({
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        textAlign: 'left',
        paddingTop: theme.spacing(2)
    }),
    generalDetails: (theme) => ({
        padding: `0 ${theme.spacing(3)}`
    }),
    periodTitle: (theme) => ({
        paddingRight: theme.spacing(0.5)
    }),
    periodsBlock: (theme) => ({
        paddingTop: theme.spacing(1)
    }),
    longDesc: (theme) => ({
        padding: `${theme.spacing(1)} 0 ${theme.spacing(3)}`,
        color: theme.palette.grey[700],

        '& p': {
            padding: 0,
            margin: 0
        },
    }),
};

export default styles;