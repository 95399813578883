const drawerWidthDesktop = 378;
const drawerWidthTablet = 396;
const drawerWidthMobile = 359;

const styles = {
    menuButton: (theme) => ({
        height: 36,
        width: 36,
        padding: 0,
        margin: 0,
        backgroundColor: theme.palette.hexToRGBA(theme.palette.common.white),
        '&:hover': {
            backgroundColor: theme.palette.hexToRGBA(theme.palette.common.white),
        },

        '& svg': {
            height: 36,
            width: 36,
            backgroundColor: theme.palette.common.white,
            padding: '2px',
            fill: theme.palette.grey[700],
        }
    }),
    menuLogoContainer: (theme) => ({
        display: "flex",
        justifyContent: "space-between",
        padding: '22px 24px 18px 24px',
        backgroundColor: theme.palette.common.white,
        border: '0px 0px 1px 0px',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        width: '100%',
    }),
    drawer: (theme) => ({
        flexShrink: 0,

        '& .MuiDrawer-paper': {
            width: drawerWidthDesktop,
            boxShadow: theme.shadows[16],
            backgroundColor: theme.palette.grey[50],

            [theme.breakpoints.down('lg')]: {
                width: drawerWidthTablet,
            },

            [theme.breakpoints.down('md')]: {
                width: drawerWidthMobile,
            },

            [theme.breakpoints.down('sm')]: {
                width: '100%',
                boxShadow: 'none',
                borderRight: 'none',
            }
        },
    }),

    logo: (theme, unionLogo) => ({
        height: 40,
        width: '100%',
        maxWidth: 54,
        background: `url(${unionLogo})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        cursor: 'pointer',
    }),
    root: (theme) => ({
        backgroundColor: theme.palette.grey[50],
        gap: '16px',
        paddingTop: '8px',
        '& li:last-child': {
            '& .MuiTreeItem-label': {
                borderBottom: 'unset',
            }
        },
    }),
    itemRoot: (theme) => ({
        position: 'relative',
        '& .MuiTreeItem-content': {
            '&:hover': {
                backgroundColor: 'unset !important',
            },
            padding: 0,
            flexDirection: 'row-reverse',
            '& .MuiTreeItem-label': {
                ...theme.typography.p2,
                padding: '16px 0',
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
                margin: '0 24px',
                fontFamily: 'OpenSansBold',
                fontSize: '18px',
                lineHeight: '26px',
                color: theme.palette.grey[800],
            },
            '& .MuiTreeItem-iconContainer': {
                position: 'absolute',
                right: 24,
                margin: 0,
                padding: 0,
                '& svg': {
                    fontSize: '24px',
                    fill: theme.palette.grey[700]
                }
            }
        },
        '& > .MuiTreeItem-content.Mui-selected.Mui-focused': {
            backgroundColor: 'unset',
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
        '& > .MuiTreeItem-content.Mui-selected': {
            backgroundColor: 'unset',
        },
        '&:hover': {
            backgroundColor: 'unset',
        },
        '& .MuiCollapse-root': {
            margin: 0
        },
        '&.one-root-item': {
            "&:before": {
                pointerEvents: "none",
                content: '""',
                position: "absolute",
                width: 4,
                height: 26,
                background: theme.palette.primary.main,
                bottom: 16,
                borderTopRightRadius: 3,
                borderBottomRightRadius: 3
            },
        }
    }),
    itemChild: (theme) => ({
        position: "relative",
        '& .MuiTreeItem-content': {
            padding: '0 40px',
            margin: 0,
            flexDirection: 'row-reverse',
            '& .MuiTreeItem-label': {
                ...theme.typography.p2,
                padding: '8px 0',
                margin: 0,
                borderBottom: 'unset',
                fontFamily: 'OpenSans',
                fontSize: '18px',
                lineHeight: '26px',
                color: theme.palette.grey[800],
            }
        },
        '& > .MuiTreeItem-content.Mui-selected.Mui-focused': {
            backgroundColor: 'unset',
            '&:hover': {
                backgroundColor: 'unset',
            },
            '& .MuiTreeItem-label': {
                color: theme.palette.grey[600],
            },
        },
        '& > .MuiTreeItem-content.Mui-selected': {
            backgroundColor: 'unset',
        },
        '&:hover': {
            "&:before": {
                pointerEvents: "none",
                content: '""',
                position: "absolute",
                width: 4,
                height: 26,
                background: theme.palette.hexToRGBA(theme.palette.primary.main, 0.4),
                bottom: 8,
                borderTopRightRadius: 3,
                borderBottomRightRadius: 3
            },
            '& .MuiTreeItem-label': {
                color: theme.palette.grey[600],
            },
            backgroundColor: 'unset',
        },
        '&.Mui-selected': {
            "&:before": {
                pointerEvents: "none",
                content: '""',
                position: "absolute",
                width: 4,
                height: 26,
                background: theme.palette.primary.main,
                bottom: 8,
                borderTopRightRadius: 3,
                borderBottomRightRadius: 3
            },
            '& .MuiTreeItem-label': {
                color: theme.palette.grey[600],
            },
        },
    }),
}

export default styles;