export const getGeneralInfoData = (branch, account) => {
    const phone = branch.phone_code && branch.phone_number && (`+${branch.phone_code} ${branch.phone_number}`);

    return {
        email: branch.email,
        address: branch.address,
        region: branch.region && branch.region.name,
        phone: phone,
        url: account.url,
        name: branch.name,
        image_url: branch.image ? branch.image.url : null,
        description: branch.description,
    };
};

export const groupOffersPricesWithPeriods = (offers, periods) => {
    let offersGroupedByName = [];
    const notDisabledOffers = offers.filter(offer => offer.status && offer.price);

    // group offers by name and add prices
    notDisabledOffers.map(offer => {
        let index = _.findIndex(offersGroupedByName, {name: offer.name});
        if (index === -1) {
            offersGroupedByName.push({
                name: offer.name,
                prices: [{..._.pick(offer, ['price', 'period_id'])}],
            });
        } else {
            offersGroupedByName[index].prices.push({..._.pick(offer, ['price', 'period_id'])});
        }
    });


    let offersWithPeriodPrices;

    //for each period find offer period, if offer does not have add with null price
    offersWithPeriodPrices = offersGroupedByName.map(offer => {
        let periodsWithPrices = [];

        periods.map(period => {
            let price = offer.prices.find(price => price.period_id === period.id);

            if (!price) {
                price = {
                    period_id: period.id,
                    price: null
                };
            }

            periodsWithPrices.push(price);
        });

        return {
            name: offer.name,
            prices: periodsWithPrices
        }
    });

    return offersWithPeriodPrices;
};