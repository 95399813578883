import React from 'react';
import Box from "@mui/material/Box";
import styles from './styles/styles'
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {withRouter} from "react-router-dom";
import useTranslate from "@/components/UseTranslate/useTranslate";
import {useAuth} from "@/providers/Auth/AuthProvider";


const DisabledAccount = () => {
    const {accountInterface} = useAuth();
    const {t} = useTranslate()

    return (
        <Box className="page-content page-not-found">
            <Box>
                <Box>
                    <Box sx={styles.wrapper}>
                        <Box sx={styles.titleBox}>
                            <Typography sx={styles.title}>
                                {t('closed_site')}
                            </Typography>
                        </Box>
                        <Box sx={styles.subtitleBox}>
                            <Typography sx={styles.subtitle}>
                                {t('closed_site_description', {union_name: accountInterface?.account?.name})}
                            </Typography>
                        </Box>
                        {
                            accountInterface &&
                            <Box>
                                <Button
                                    variant={'contained'}
                                    color={'secondary'}>
                                    <Typography
                                        variant='p3'
                                        onClick={() => window.location.replace(accountInterface?.account?.url)}
                                    >
                                        {t('home')}
                                    </Typography>
                                </Button>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default withRouter(DisabledAccount);

