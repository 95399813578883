import React from 'react';
import styles from "@/layouts/components/Menu/styles/styles";
import storage from "@/services/storage/storage";
import {useAuth} from "@/providers/Auth/AuthProvider";

export default () => {
    const {checkAccess} = useAuth();

    return [
        {
            nodeId: '3',
            label: 'travelers_check',
            sx: styles.itemRoot,
            show: checkAccess('pvc_member_privileges'),

            items: [{
                nodeId: '31',
                label: 'offers',
                path: `/${storage.get('lang')}/pvc/offers`,
                show: checkAccess('pvc_member_privileges'),
            }, {
                nodeId: '32',
                label: 'buy_travelers_check',
                path: `/${storage.get('lang')}/pvc/purchase`,
                show: checkAccess('pvc_member_privileges'),
            }, {
                nodeId: '33',
                label: 'menu_refund',
                path: '#',
                modal: 'refund-modal',
                show: checkAccess('pvc_member_privileges'),
            },]
        },
    ];
}