import * as src from "@/pages/NotFound/images/notFoundBg";

const styles = {
    wrapper: theme => ({
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        paddingTop: theme.spacing(15),
        paddingLeft: theme.spacing(3),
        display: "flex",
        flexDirection: 'column',
        paddingBottom: "668px",
        [theme.breakpoints.only('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(5),
            paddingBottom: "21px"
        }
    }),
    titleBox: theme => ({
        marginBottom: theme.spacing(5),
    }),
    description: theme => ({
        marginBottom: theme.spacing(5),
    }),
    subtitleBox: theme => ({
        marginBottom: theme.spacing(2),
    }),
    title: theme => ({
        ...theme.typography.h1,
        color: theme.palette.grey[50],

        [theme.breakpoints.down('md')]:{
            ...theme.typography.h2,
            color: theme.palette.grey[50],
        }
    }),
    subtitle: theme => ({
        ...theme.typography.p1,
        color: theme.palette.grey[50],

        [theme.breakpoints.down('md')]:{
            ...theme.typography.h2,
            color: theme.palette.grey[50],
        }
    }),
    descriptionItem: theme => ({
        ...theme.typography.p1,
        color: theme.palette.grey[50],
        fontFamily: 'OpenSans',
    }),

}

export default styles