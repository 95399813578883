import React, {useEffect, useState} from 'react';
import {Resource} from '@/services/transform/resource/resource';
import useTranslate from '@/components/UseTranslate/useTranslate';
import notification from '@/services/notification/notification';
import styles from '../../styles/styles';
import {getMemberEmails, updateMemberEmail} from '@/modules/UnionMember/services/api/emails/emails';
import MemberEmailTransformer
    from '@/modules/UnionMember/services/transform/resource/transformers/Email/MemberEmailTransformer';
import {Box, FormControlLabel, Switch, Typography} from '@mui/material';
import CreateOrUpdateEmailModal
    from '@/layouts/components/Header/components/ProfileInformation/components/Emails/components/CreateOrUpdateEmailModal';
import Pencil from "@/icons/Pencil";
import {emailTypeOptions} from "@/layouts/components/Header/components/ProfileInformation/staticData/staticData";

const Emails = () => {
    const [emails, setEmails] = useState(null);
    const [usedTypes, setUsedTypes] = useState([]);
    const [isOpenChangeEmailModal, setIsOpenChangeEmailModal] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);

    const {t} = useTranslate();

    useEffect(() => {
        fetchData().then(resp => {
            setUsedTypes(resp && resp.map(item => item.type));
            setEmails(resp);
        })
    }, [])

    const fetchData = async () => {
        const response = await getMemberEmails();
        return Resource.transform(MemberEmailTransformer, response.data, response.meta).data;
    }

    const updateEmailsData = () => {
        fetchData().then(resp => {
            setUsedTypes(resp && resp.map(item => item.type))
            setEmails(resp)
        })
    }

    const closeChangeEmailModal = () => {
        setIsOpenChangeEmailModal(false);
        setCurrentRow(null);
    }

    const updateIsDefaultStatus = async (data) => {
        data.is_default = !data.is_default;

        return await updateMemberEmail(data.id, data).then(() => {
            updateEmailsData();
            notification.success(t('alert_email_status_change'))
        });
    }

    const openChangeEmailModal = (data) => {
        setIsOpenChangeEmailModal(true)
        setCurrentRow(data)
    }

    const emailTypeTranslations = {
        work: t('work'),
        personal: t('personal'),
        other: t('other'),
    };

    if (!emails) {
        return;
    }

    return (
        <>
            <Box>
                <Typography variant='p2' className='title'>{t('title_emails')}</Typography>
                {emails.map((item, index) => (
                    <Box key={index} sx={styles.itemBox}>
                        <Box sx={styles.preTitleBlock}>
                            <Typography variant='p3Bold'
                                        className='item-name'>{emailTypeTranslations[_.find(emailTypeOptions, (option) => option.value === item.type).label]}</Typography>
                            <Box className='update-block'>
                                <Pencil sx={styles.pencilIcon} onClick={() => openChangeEmailModal(item)}
                                        color='primary'/>
                            </Box>
                        </Box>
                        <Box sx={styles.switchBox}>
                            <Typography variant='p3' className='item-name'>{item.email}</Typography>
                            <Box sx={styles.switch}>
                                <FormControlLabel
                                    control={<Switch focusVisibleClassName='.Mui-focusVisible' disableRipple
                                                     checked={item.is_default}
                                                     onChange={() => updateIsDefaultStatus(item)}
                                                     disabled={item.is_default}/>}
                                    label=''
                                />
                                <Typography variant='p3'
                                            className='is-default'>{t('default')}</Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
                <Box sx={styles.newAddressButton}>
                    <Typography className='add-new-title' variant='p4'
                                onClick={() => openChangeEmailModal([])}>{t('add_new_email')}</Typography>
                </Box>
            </Box>

            <CreateOrUpdateEmailModal
                id={currentRow && currentRow.id}
                usedTypes={usedTypes}
                open={isOpenChangeEmailModal}
                onSuccess={updateEmailsData}
                onClose={closeChangeEmailModal}
            />
        </>
    )
}

export default Emails;