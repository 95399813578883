const styles = {
    audkenni_header: (theme) => ({
        textAlign: 'center',
        color: theme.palette.grey[50],
    }),
    audkenni: (theme) => ({
        display: 'flex',
        justifyContent: 'center',
    }),
    audkenni_login_box: (theme) => ({
        width: '472px',
        backgroundColor: theme.palette.common.white,
        borderRadius: '7px',
    }),
    audkenni_login_box_footer: (theme) => ({
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.grey[50],
        borderBottomRightRadius: '7px',
        borderBottomLeftRadius: '7px',
    }),
    audkenni_tabList: (theme) => ({
        marginTop: theme.spacing(3),
        borderBottom: 1,
        borderBottomColor: theme.palette.grey[200],
        '& .MuiTab-root:first-child': {
            marginLeft: theme.spacing(3)
        },
        '& .MuiTabs-indicator': {
            height: '4px',
            backgroundColor: theme.palette.primary,
            borderRadius: '4px 4px 0 0'
        },
    }),
    audkenni_tab: (theme) => ({
        '&.MuiTab-root': {
            height: '34px',
            marginRight: '16px',
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            ...theme.typography.p3Bold,
            textTransform: 'none',
        },
    }),

    audkenni_tab_panel: (theme) => ({
        paddingTop: 0,
        paddingBottom: 0
    }),
    audkenni_sim_response_box: (theme) => ({
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(4),
        boxShadow:  theme.palette.hexToRGBA(theme.palette.common.black, 0.08),
    }),
    audkenni_sim_waiting_title: (theme) => ({
        ...theme.typography.p3Bold,
        marginLeft: theme.spacing(1),
        background: "-webkit-linear-gradient(45deg, #FF9174 100%, #F04A4F 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
    }),
    audkenni_circular: (theme) => ({
        color: '#FF9174',
        width: theme.spacing(3),
        height: theme.spacing(3),
    }),
    audkenni_sim_timeout_alert: (theme) => ({
        background: theme.palette.warning.main,
        width: '40px',
        height: '56px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,

        '& .MuiAlert-icon': {
            margin: 0,
            padding: 0,
        }
    }),
    audkenni_privacy: (theme) => ({
        ...theme.typography.p4,
        color: theme.palette.common.white,
        textAlign: 'center',
        width: '596px',
        display: 'block',

        '& a':{
            color: theme.palette.common.white,
            textDecoration: 'none',
            fontWeight: 700,
        }
    }),

}

export default styles;