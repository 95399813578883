export class Query {
    constructor() {
        this._params = {};
        this._criteria = {};
        this._fields = [];
        this._page = 1;
        this._perPage = 10;
        this._sort = [];
        this._includes = [];
    }

    /**
     *
     * @param {Object} params
     * @returns {Query}
     */
    params = (params) => {
        this._params = params;

        return this;
    };

    /**
     *
     * @param {array} fields
     * @returns {Query}
     */
    fields = (fields) => {
        this._fields = fields;

        return this;
    };

    /**
     *
     * @param {int} page
     * @returns {Query}
     */
    page = (page) => {
        this._page = page;

        return this;
    };

    /**
     *
     * @param {int} perPage
     * @returns {Query}
     */
    perPage = (perPage) => {
        this._perPage = perPage;

        return this;
    };

    /**
     *
     * @param {array }sort
     * @returns {Query}
     */
    sort = (sort) => {
        this._sort = sort;

        return this;
    };

    /**
     *
     * @param {Criteria} criteria
     * @returns {Query}
     */
    criteria = (criteria) => {
        criteria.getConditions().forEach(condition => {
            this._criteria[condition.field] = exp(condition.operation, condition.value);
        });

        return this;
    };

    /**
     *
     * @param {Include|string} include
     * @returns {Query}
     */
    include = (include) => {
        if (typeof include === 'string') {
            include = new Include(include);
        }

        this._includes.push(include);

        return this;
    };

    /**
     *
     * @returns {Object}
     */
    make = () => {
        const result = {
            page: this._page,
            per_page: this._perPage,
        };

        if (this._fields.length) {
            result.fields = this._fields;
        }

        if (this._sort.length) {
            result.sort = this._sort;
        }

        if (this._includes.length) {
            result.include = [];

            this._includes.forEach(include => {
                result.include.push(include.toString());
            });
        }

        const params = {...this._params, ...this._criteria};

        return {...params, ...result};
    }
}

export class Criteria {
    static equals = 'eq';
    static notEquals = 'neq';
    static greater = 'gte';
    static less = 'lte';
    static isNull = 'null';
    static notNull = 'not_null';
    static starts = 'start';
    static ends = 'end';
    static contains = 'like';
    static inList = 'in';
    static notInList = 'not_in';

    constructor() {
        this._conditions = [];
    }

    /**
     *
     * @param {string} field
     * @param {string} operation
     * @param value
     * @returns {Criteria}
     */
    condition = (field, operation = Criteria.equals, value = '') => {
        this._conditions.push({
            field: field,
            operation: operation,
            value: value,
        });

        return this;
    };

    /**
     *
     * @param {String} field
     * @param value
     * @returns {Criteria}
     */
    equals = (field, value) => {
        return this.condition(field, Criteria.equals, value);
    };

    /**
     *
     * @param {String} field
     * @param value
     * @returns {Criteria}
     */
    notEquals = (field, value) => {
        return this.condition(field, Criteria.notEquals, value);
    };

    /**
     *
     * @param {String} field
     * @param value
     * @returns {Criteria}
     */
    greater = (field, value) => {
        return this.condition(field, Criteria.greater, value);
    };

    /**
     *
     * @param {String} field
     * @param value
     * @returns {Criteria}
     */
    less = (field, value) => {
        return this.condition(field, Criteria.less, value);
    };

    /**
     *
     * @param {String} field
     * @returns {Criteria}
     */
    isNull = (field) => {
        return this.condition(field, Criteria.isNull);
    };

    /**
     *
     * @param {String} field
     * @returns {Criteria}
     */
    notNull = (field) => {
        return this.condition(field, Criteria.notNull);
    };

    /**
     *
     * @param {String} field
     * @param value
     * @returns {Criteria}
     */
    starts = (field, value) => {
        return this.condition(field, Criteria.starts, value);
    };

    /**
     *
     * @param {String} field
     * @param value
     * @returns {Criteria}
     */
    ends = (field, value) => {
        return this.condition(field, Criteria.ends, value);
    };

    /**
     *
     * @param {String} field
     * @param value
     * @returns {Criteria}
     */
    contains = (field, value) => {
        return this.condition(field, Criteria.contains, value);
    };

    /**
     *
     * @param {String} field
     * @param value
     * @returns {Criteria}
     */
    inList = (field, value) => {
        return this.condition(field, Criteria.inList, value.join(','));
    };

    /**
     *
     * @param {String} field
     * @param value
     * @returns {Criteria}
     */
    notInList = (field, value) => {
        return this.condition(field, Criteria.notInList, value.join(','));
    };

    /**
     *
     * @returns {Array}
     */
    getConditions = () => {
        return this._conditions;
    }
}

export class Include {
    constructor(name) {
        this._name = name;
        this._page = '';
        this._perPage = '';
        this._sort = '';
        this._criteria = '';
    }

    /**
     *
     * @param {int} page
     * @returns {Include}
     */
    page = (page) => {
        this._page = `:page(${page})`;

        return this;
    };

    /**
     *
     * @param {int} perPage
     * @returns {Include}
     */
    perPage = (perPage) => {
        this._perPage = `:per_page(${perPage})`;

        return this;
    };

    /**
     *
     * @param {array} sort
     * @returns {Include}
     */
    sort = (sort) => {
        this._sort = `:sort(${sort.join('|')})`;

        return this;
    };

    /**
     *
     * @param {Criteria} criteria
     * @returns {Include}
     */
    criteria = (criteria) => {
        const conditions = criteria.getConditions().map(
            condition => exp(condition.operation, condition.value, condition.field)
        );

        this._criteria = `:criteria(${conditions.join('|')})`;

        return this;
    };

    /**
     *
     * @returns {string}
     */
    toString = () => {
        return this._name + this._page + this._perPage + this._sort + this._criteria;
    }
}

export const exp = (operation = Criteria.equals, value, field = '') => {
    if (!value) {
        return '';
    }

    let exp = `[${operation}]${value}`;

    if (field) {
        exp = `${field}=${exp}`
    }

    return exp;
};

export const equals = (value, field) => {
    return exp(Criteria.equals, value, field)
};

export const notEquals = (value, field) => {
    return exp(Criteria.notEquals, value, field);
};

export const greater = (value, field) => {
    return exp(Criteria.greater, value, field);
};

export const less = (value, field) => {
    return exp(Criteria.less, value, field);
};

export const isNull = (field) => {
    return exp(Criteria.isNull, '', field);
};

export const notNull = (field) => {
    return exp(Criteria.notNull, '', field);
};

export const starts = (value, field) => {
    return exp(Criteria.starts, value, field);
};

export const ends = (value, field) => {
    return exp(Criteria.ends, value, field);
};

export const contains = (value, field) => {
    return exp(Criteria.contains, value, field);
};

export const inList = (value, field) => {
    return exp(Criteria.inList, value.join(','), field);
};

export const notInList = (value, field) => {
    return exp(Criteria.notInList, value.join(','), field);
};