import React from 'react';
import {withRouter} from "react-router-dom";
import {useAuth} from "@/providers/Auth/AuthProvider";
import storage from "@/services/storage/storage";

const Home = (props) => {
    const {settings} = useAuth();
    const landingPageUrl = _.get(settings, 'landing_page_url.value', null);
    const url = window.location.href;
    const redirectUrl = storage.get('redirect_url');

    if (redirectUrl) {
        storage.remove('redirect_url');

        return props.history.push(redirectUrl);
    }

    if (!landingPageUrl) {
        props.history.push(`/${storage.get('lang')}/not_found`);
        return null;
    }

    if (landingPageUrl.startsWith(url)) {
        props.history.push(landingPageUrl.substring(url.length))
    } else {
        window.location.replace(landingPageUrl)
    }

    return null;
};

export default withRouter(Home)