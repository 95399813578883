import Transformer from 'services/transform/resource/transformers/Transformer';
import {Resource} from 'services/transform/transform';
import {DailyStatusTransformer} from 'modules/Accommodation/services/transform/transform';

class PropertyDayTransformer extends Transformer {
    static transform(day, meta) {
        const {dailyStatuses, ...result} = day;

        if (dailyStatuses) {
            result.dailyStatuses = Resource.transform(DailyStatusTransformer, dailyStatuses.data, dailyStatuses.meta).data;
        }

        return result;
    }
}

export default PropertyDayTransformer;