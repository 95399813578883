import Transformer from 'services/transform/resource/transformers/Transformer';
import {Resource} from 'services/transform/transform';
import {PropParamGroupTransformer} from 'modules/Accommodation/services/transform/transform';

class PropertyTplTransformer extends Transformer {
    static transform(template, meta) {
        const {parameterGroups, ...result} = template;

        if (parameterGroups) {
            result.parameter_groups = Resource.transform(PropParamGroupTransformer, parameterGroups.data, parameterGroups.meta).data;
        }

        return result;
    }
}

export default PropertyTplTransformer;