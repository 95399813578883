const styles = {
    notificationsWrapper: (theme) => ({
        position: 'fixed',
        width: '300px',
        height: 'auto',
        top: '68px',
        right: '16px',
        zIndex: theme.zIndex.modal + 1
    })
}

export default styles;