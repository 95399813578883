import {metaToSelectOption} from "../../meta";

class Transformer {
    /**
     *
     * @param {Object} metaData
     * @param {array} skip
     */
    static meta(metaData, skip = ['pagination']) {
        const meta = {};

        Object.keys(metaData).forEach(metaKey => {
            let metaItem = {...metaData[metaKey]};

            if (!skip.includes(metaKey)) {
                metaItem = metaToSelectOption(metaItem);
            }

            meta[metaKey] = metaItem;
        });

        return meta;
    }
}

export default Transformer;