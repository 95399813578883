import Transformer from "../Transformer";
import {ImageTransformer, Resource} from 'services/transform';

class UserTransformer extends Transformer {
    static transform(user, meta) {
        const {avatar, ...result} = user;

        if (avatar && avatar.data) {
            result.avatar = Resource.transform(ImageTransformer, avatar.data, avatar.meta).data;
        }

        return result;
    }
}

export default UserTransformer;