export const styles = {

    paymentResultBox: theme => ({
        maxWidth: 500,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(12.5),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3)
    })

};
export default styles;