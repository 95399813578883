import React from 'react';
import styles from "@/layouts/components/Menu/styles/styles";
import storage from "@/services/storage/storage";
import {useAuth} from "@/providers/Auth/AuthProvider";

export default () => {
    const {checkAccess} = useAuth();

    return [
        {
            nodeId: '5',
            label: 'faq',
            path: `/${storage.get('lang')}/unm/faq`,
            sx: styles.itemRoot,
            show: checkAccess('unm_member_privileges'),
        },
    ];
}