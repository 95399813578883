import React from "react";
import Transformer from 'services/transform/resource/transformers/Transformer';


class ServiceDetailTransformer extends Transformer {
    static transform(serviceDetail, meta) {
        const {service, ...result} = serviceDetail;

        if (service) {
            result.service = service.data
        }

        return result;
    }
}

export default ServiceDetailTransformer;