import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    textField: {
        margin: theme.spacing(1),
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const VerticalForm = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h4" component="h2">
                {props.name}
            </Typography>
            <form className={classes.form} onSubmit={(event) => {
                event.preventDefault();
                alert('Form is sent');
            }}>
                <TextField
                    label="SSN"
                    variant="outlined"
                    className={classes.textField}
                    value={''}
                    onChange={() => {
                    }}
                />
                <TextField
                    label="Name"
                    variant="outlined"
                    className={classes.textField}
                    value={''}
                    onChange={() => {
                    }}
                />
                <TextField
                    label="Address"
                    variant="outlined"
                    className={classes.textField}
                    value={''}
                    onChange={() => {
                    }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                >
                    Submit
                </Button>
            </form>
        </div>
    );
}

export default VerticalForm;
