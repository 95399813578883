import * as api from 'services/api/api';

export const getMemberAddresses = (data) => {
    return api.getRequest('/unm/member_addresses', data)
}

export const getMemberAddress = (id, data) => {
    return api.getRequest(`/unm/member_addresses/${id}`, data)
}

export const updateMemberAddress = (id, data) => {
    return api.putRequest(`/unm/member_addresses/${id}`, data);
}

export const createMemberAddress = (data) => {
    return api.postRequest(`/unm/member_addresses`, data);
}

export const deleteMemberAddress = (id) => {
    return api.deleteRequest(`/unm/member_addresses`, id);
}