import {createTheme} from '@mui/material/styles';
import {palette, typography, components, breakpoints, name} from "./options/options";

const theme = createTheme({
    name,
    palette,
    typography,
    components,
    breakpoints,
});

export default theme;