const styles = {
    alert: (color, theme) => ({
        background: theme.palette.common.white,
        padding: 0,
        display: "flex",
        gap: "12px",
        justifyContent: 'flex-start',
        minHeight: "56px",
        maxWidth: "360px",
        "& .MuiAlert-icon": {
            display: 'flex',
            alignItems: 'center',
            background: color,
            padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
            margin: 0,
            "& svg": {
                fontsize: "24px",
                color: theme.palette.common.white
            }
        },
        "& .MuiAlert-action": {
            marginRight: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            "& svg": {
                fontSize: '24px',
                color: theme.palette.primary.main
            }
        },
        "& .MuiAlert-message": {
            display: "flex",
            alignItems: "center"
        }
    }),
    data: theme => ({
        ...theme.typography.p4,
    })
}

export default styles