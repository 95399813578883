import Transformer from 'services/transform/resource/transformers/Transformer';

class DailyStatusTransformer extends Transformer {
    static transform(status, meta) {
        const {...result} = status;

        return result;
    }
}

export default DailyStatusTransformer;