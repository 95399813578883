import OpenSansLight from './OpenSans/OpenSans-Light.woff2';
import OpenSansLightItalic from './OpenSans/OpenSans-LightItalic.woff2';
import OpenSans from './OpenSans/OpenSans-Regular.woff2';
import OpenSansItalic from './OpenSans/OpenSans-Italic.woff2';
import OpenSansSemiBold from './OpenSans/OpenSans-SemiBold.woff2';
import OpenSansSemiBoldItalic from './OpenSans/OpenSans-SemiBoldItalic.woff2';
import OpenSansBold from './OpenSans/OpenSans-Bold.woff2';
import OpenSansBoldItalic from './OpenSans/OpenSans-BoldItalic.woff2';
import OpenSansExtraBold from './OpenSans/OpenSans-ExtraBold.woff2';
import OpenSansExtraBoldItalic from './OpenSans/OpenSans-ExtraBoldItalic.woff2';

export const openSansLight = `
    @font-face {
    font-family: 'OpenSansLight';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('OpenSansLight'), url(${OpenSansLight}) format('woff2');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
`;

export const openSansLightItalic = `
    @font-face {
    font-family: 'OpenSansLightItalic';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: local('OpenSansLightItalic'), url(${OpenSansLightItalic}) format('woff2');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
`;

export const openSans = `
    @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('OpenSans'), local('OpenSans-Regular'), url(${OpenSans}) format('woff2');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
`;

export const openSansItalic = `
    @font-face {
    font-family: 'OpenSansItalic';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: local('OpenSansItalic'), url(${OpenSansItalic}) format('woff2');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
`;

export const openSansSemiBold = `
    @font-face {
    font-family: 'OpenSansSemiBold';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local('OpenSansSemiBold'), url(${OpenSansSemiBold}) format('woff2');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
`;

export const openSansSemiBoldItalic = `
    @font-face {
    font-family: 'OpenSansSemiBoldItalic';
    font-style: italic;
    font-display: swap;
    font-weight: 600;
    src: local('OpenSansSemiBoldItalic'), url(${OpenSansSemiBoldItalic}) format('woff2');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
`;

export const openSansBold = `
    @font-face {
    font-family: 'OpenSansBold';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('OpenSansBold'), url(${OpenSansBold}) format('woff2');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
`;

export const openSansBoldItalic = `
    @font-face {
    font-family: 'OpenSansBoldItalic';
    font-style: italic;
    font-display: swap;
    font-weight: 700;
    src: local('OpenSansBoldItalic'), url(${OpenSansBoldItalic}) format('woff2');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
`;

export const openSansExtraBold = `
    @font-face {
    font-family: 'OpenSansExtraBold';
    font-style: normal;
    font-display: swap;
    font-weight: 800;
    src: local('OpenSansExtraBold'), url(${OpenSansExtraBold}) format('woff2');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
`;

export const openSansExtraBoldItalic = `
    @font-face {
    font-family: 'OpenSansExtraBoldItalic';
    font-style: italic;
    font-display: swap;
    font-weight: 800;
    src: local('OpenSansExtraBoldItalic'), url(${OpenSansExtraBoldItalic}) format('woff2');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
`;