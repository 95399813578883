import React from 'react';
import Box from "@mui/material/Box";
import Layout from "@/layouts/Main/Main";
import PublicLayout from "@/layouts/Public/Public";
import styles from './styles/styles'
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {withRouter} from "react-router-dom";
import useTranslate from "@/components/UseTranslate/useTranslate";
import {useAuth} from "@/providers/Auth/AuthProvider";


const NotFoundContent = props => {
    const {accountInterface} = useAuth();
    const {t} = useTranslate()

    return (
        <Box className="page-content page-not-found">
            <Box>
                <Box>
                    <Box sx={styles.wrapper}>
                        <Box sx={styles.titleBox}>
                            <Typography sx={styles.title}>
                                {t('title_404')}
                            </Typography>
                        </Box>
                        <Box sx={styles.subtitleBox}>
                            <Typography sx={styles.subtitle}>
                                {t('subtitle_404')}
                            </Typography>
                        </Box>
                        <Box sx={styles.description}>
                            <Typography component="div" sx={styles.descriptionItem}>
                                <ul>
                                    <li>{t('description_404_1')}</li>
                                    <li>{t('description_404_2')}</li>
                                    <li>{t('description_404_3')}</li>
                                </ul>
                            </Typography>
                        </Box>
                        {
                            accountInterface &&
                            <Box>
                                <Button
                                    variant={'contained'}
                                    color={'secondary'}>
                                    <Typography
                                        variant='p3'
                                        onClick={() => props.history.push('/')}
                                    >
                                        {t('home')}
                                    </Typography>
                                </Button>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const NotFound = props => {
    const {user} = useAuth();

    if (user) {
        return (
            <Layout showFooter={true} HeaderProps={{hasOffset: false}} sx={styles.root} page={'not-found'}>
                <NotFoundContent {...props}/>
            </Layout>
        )
    }

    return (
        <PublicLayout showFooter={true} HeaderProps={{hasOffset: false}}>
            <NotFoundContent {...props}/>
        </PublicLayout>
    )
}

export default withRouter(NotFound);

