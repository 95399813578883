import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import {getFieldError, hasFieldError} from "@/components/ActiveForm/services/services";
import FormHelperText from "@mui/material/FormHelperText";
import styles from './styles/styles';
import ArrowDropDown from "@/icons/ArrowDropDown";
import ArrowDropUp from "@/icons/ArrowDropUp";

const SelectField = props => {
    const {
        name,
        value,
        label,
        options,
        onBlur,
        fullWidth,
        onChange,
        TextFieldProps,
        onError,
        formik,
        helperText,
        ...selectProps
    } = props;
    const {required, disabled} = selectProps;
    const [open, setOpen] = useState(false);
    const inputRef = useRef();

    const onOptionClick = option => {
        onChange(name, option);
        setOpen(false);
    };

    const valueText = _.get(value, 'label');
    const IconComponent = open ? ArrowDropDown : ArrowDropUp;

    const errorText = getFieldError(props.name, formik, onError);
    const hasError = hasFieldError(props.name, formik);
    const showHelperText = hasError || helperText;

    const onClickAway = () => {
        open && onBlur(name, value);
        setOpen(false);
    };

    const onClick = () => {
        setOpen(!open);
    };

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <Box sx={styles.selectFieldWrapper} flexGrow={+fullWidth}>
                {
                    !!label && <InputLabel required={required} disabled={disabled}>
                        {label}
                    </InputLabel>
                }

                {!!options.length && <Popper
                    sx={styles.selectPopper}
                    open={open}
                    anchorEl={inputRef.current}
                    placement='bottom-start'
                    disablePortal={true}
                >
                    <Paper>
                        {options.map((option, i) => {
                            return (
                                <MenuItem key={i} onClick={() => onOptionClick(option)}>{option.label}</MenuItem>
                            );
                        })}
                    </Paper>
                </Popper>}
                <TextField
                    {...TextFieldProps}
                    fullWidth={fullWidth}
                    readOnly
                    id={name}
                    name={name}
                    value={valueText || ''}
                    onClick={onClick}
                    disabled={disabled}
                    error={hasError}
                    InputProps={{
                        ref: inputRef,
                        endAdornment: <IconComponent sx={(theme) => styles.arrowIcon(theme, disabled)}/>,
                    }}
                />
                {
                    showHelperText &&
                    <FormHelperText error={hasError} disabled={disabled}>
                        {hasError ? errorText : helperText}
                    </FormHelperText>
                }
            </Box>
        </ClickAwayListener>
    )
};

SelectField.defaultProps = {
    options: [],
    fullWidth: true,
    onChange: () => undefined,
    onBlur: () => undefined,
    TextFieldProps: {},
    helperText: '',
    disabled: false,
};

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.object,
    options: PropTypes.array,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    TextFieldProps: PropTypes.object,
    helperText: PropTypes.string,
    onError: PropTypes.func,
    disabled: PropTypes.bool,
};

export {SelectField};