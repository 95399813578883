const parseError = (resp) => {
    let error = {
        status: 0,
        error: 'unknown'
    };

    if (resp.response && resp.response.data) {
        error = {...error, ...resp.response.data};
    } else {
        error = resp;
    }

    return error;
};

const parseSuccess = (response) => {
    return {...{status: response.status}, ...(response.data || response)};
};

export const transformResponse = (resp) => {
    const isSuccess = resp.status >= 200 && resp.status < 300;
    let result;

    if (isSuccess) {
        result = parseSuccess(resp);
    } else {
        result = parseError(resp);
    }

    return result;
};