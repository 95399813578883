import React from "react";
import Grid from "@material-ui/core/Grid";
import VerticalForm from "@/modules/NonUNM/pages/Applications/components/VerticalForm/VerticalForm";

const Applications = () => {
    return (
        <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}>
            <Grid item xs={2} sm={4} md={4}>
                <VerticalForm name={'Death compensation'}/>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
                <VerticalForm name={'Application Form'}/>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
                <VerticalForm name={'Day off'}/>
            </Grid>
        </Grid>
    )
}


export default Applications;