export const styles = {
    root: (theme) => ({
        '&.modal': {

            '& .MuiPaper-root': {
                width: 732,
                maxWidth: 732,
            },
        },
    }),
    text: (theme) => ({
        color: theme.palette.grey[800],
    }),
};