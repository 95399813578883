import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'formik';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

import {getFieldError, hasFieldError, getFieldValue, handleFieldChange, handleFieldBlur} from '../../services/services';

const ActiveTextInput = props => {
    const {formik, onError, onChange, onBlur, inputProps, label, helperText, required, ...fieldProps} = props;
    const {disabled} = fieldProps;

    const handleChange = e => {
        if (onChange) {
            onChange(props.name, e.target.value);
        } else {
            handleFieldChange(props.name, e.target.value, formik);
        }
    };

    const handleBlur = e => {
        if (onBlur) {
            onBlur(props.name, e.target.value);
        } else {
            handleFieldBlur(props.name, formik);
        }
    };

    const errorText = getFieldError(props.name, formik, onError);
    const hasError = hasFieldError(props.name, formik);
    const value = getFieldValue(props.name, formik, '');
    const showHelperText = hasError || helperText;

    return (
        <>
            {
                label &&
                <InputLabel required={required} disabled={disabled}>
                    {label}
                </InputLabel>
            }
            <OutlinedInput
                {...fieldProps}
                value={value}
                onChange={handleChange}
                error={hasError}
                onBlur={handleBlur}
            />
            {
                showHelperText &&
                <FormHelperText disabled={disabled} error={hasError}>
                    {hasError ? errorText : helperText}
                </FormHelperText>
            }

        </>
    )
};

ActiveTextInput.defaultProps = {
    required: false,
    label: '',
    helperText: '',
    disabled: false,
    inputProps: {},
};

ActiveTextInput.propTypes = {
    onError: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    helperText: PropTypes.string,
};

export default connect(ActiveTextInput);