import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

const Pencil = (props) => {
    return (
        <SvgIcon  {...props}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.00002 19.0002C9.00002 18.4479 9.44774 18.0002 10 18.0002H19C19.5523 18.0002 20 18.4479 20 19.0002C20 19.5525 19.5523 20.0002 19 20.0002H10C9.44774 20.0002 9.00002 19.5525 9.00002 19.0002Z"
                  />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16 2.87891C15.7026 2.87891 15.4174 2.99705 15.2071 3.20733L2.90299 15.5115L2.37439 17.6259L4.48877 17.0973L16.7929 4.79312C16.897 4.689 16.9796 4.56538 17.036 4.42934C17.0923 4.29329 17.1213 4.14748 17.1213 4.00023C17.1213 3.85297 17.0923 3.70716 17.036 3.57112C16.9796 3.43507 16.897 3.31146 16.7929 3.20733C16.6888 3.10321 16.5652 3.02061 16.4291 2.96426C16.2931 2.90791 16.1473 2.87891 16 2.87891ZM13.7929 1.79312C14.3783 1.20776 15.1722 0.878906 16 0.878906C16.4099 0.878906 16.8158 0.959642 17.1945 1.1165C17.5732 1.27336 17.9173 1.50328 18.2071 1.79312C18.497 2.08296 18.7269 2.42705 18.8837 2.80575C19.0406 3.18444 19.1213 3.59033 19.1213 4.00023C19.1213 4.41012 19.0406 4.81601 18.8837 5.1947C18.7269 5.5734 18.497 5.91749 18.2071 6.20733L5.70713 18.7073C5.57897 18.8355 5.41839 18.9264 5.24256 18.9704L1.24256 19.9704C0.901782 20.0556 0.541295 19.9557 0.292914 19.7073C0.0445341 19.459 -0.055315 19.0985 0.0298787 18.7577L1.02988 14.7577C1.07384 14.5819 1.16476 14.4213 1.29291 14.2931L13.7929 1.79312Z"
                  />
        </SvgIcon>
    );
};

export default Pencil;