import Box from "@mui/material/Box";
import classNames from 'classnames';
import styles from "./styles/styles";
import React from 'react'
import Header from "./components/Header/Header";
import Footer from "@/layouts/components/Footer/Footer";

const footerHeight = {
    footerLGHeight: 165,
    footerMDHeight: 284,
    footerSMHeight: 346,
}

const Public = props => {
    const {HeaderProps, showFooter} = props;

    return (
        <Box className={classNames('layout-main', {withoutFooter: !showFooter})}>
            <Box sx={showFooter ? (theme) => styles.wrapper(theme, footerHeight) : ''}>
                <Header {...HeaderProps}/>
                <div className={`page-${props.page}`}>
                    <main>
                        {props.children}
                    </main>
                </div>
            </Box>
            {showFooter && <Footer footerHeight={footerHeight}/>}
        </Box>
    )
}

export default Public;