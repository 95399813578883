import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

const WarningOutlined = (props) => {
    return (
        <SvgIcon  {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5309 1.2833C10.9802 1.03035 11.4871 0.897461 12.0027 0.897461C12.5183 0.897461 13.0252 1.03035 13.4745 1.2833C13.9238 1.53626 14.3003 1.90074 14.5677 2.34158L14.5706 2.34633L23.0406 16.4863L23.0487 16.5001C23.3106 16.9538 23.4492 17.4681 23.4507 17.9919C23.4522 18.5158 23.3165 19.0308 23.0571 19.4859C22.7977 19.941 22.4236 20.3203 21.9721 20.5859C21.5207 20.8516 21.0075 20.9944 20.4837 21.0001L20.4727 21.0003L3.52172 21.0002C2.99791 20.9944 2.48475 20.8516 2.03328 20.5859C1.5818 20.3203 1.20776 19.941 0.948356 19.4859C0.688952 19.0308 0.553233 18.5158 0.554699 17.9919C0.556166 17.4681 0.694768 16.9538 0.956716 16.5001L0.964839 16.4863L9.43769 2.34157C9.7051 1.90073 10.0816 1.53626 10.5309 1.2833ZM12.0027 2.89746C11.8308 2.89746 11.6619 2.94176 11.5121 3.02607C11.363 3.11001 11.238 3.23079 11.1489 3.37684L2.68539 17.506C2.60022 17.6558 2.55517 17.8251 2.55469 17.9975C2.5542 18.1721 2.59944 18.3438 2.68591 18.4955C2.77238 18.6472 2.89706 18.7736 3.04755 18.8622C3.19673 18.95 3.3661 18.9975 3.5391 19.0002H20.4663C20.6393 18.9975 20.8087 18.95 20.9579 18.8622C21.1084 18.7736 21.233 18.6472 21.3195 18.4955C21.406 18.3438 21.4512 18.1721 21.4507 17.9975C21.4502 17.8251 21.4052 17.6559 21.3201 17.5061L12.8577 3.37883L12.8565 3.37684C12.7674 3.23079 12.6424 3.11001 12.4933 3.02607C12.3435 2.94176 12.1746 2.89746 12.0027 2.89746Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0027 7.00021C12.555 7.00021 13.0027 7.44792 13.0027 8.00021V12.0002C13.0027 12.5525 12.555 13.0002 12.0027 13.0002C11.4504 13.0002 11.0027 12.5525 11.0027 12.0002V8.00021C11.0027 7.44792 11.4504 7.00021 12.0027 7.00021Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0027 16.0002C11.0027 15.4479 11.4504 15.0002 12.0027 15.0002H12.0127C12.565 15.0002 13.0127 15.4479 13.0127 16.0002C13.0127 16.5525 12.565 17.0002 12.0127 17.0002H12.0027C11.4504 17.0002 11.0027 16.5525 11.0027 16.0002Z"
            />
        </SvgIcon>
    );
};

export default WarningOutlined;