import React from "react";
import {parseToHTML} from "@/services/formatters/parseToHtml/parseToHtml";
import {Box, Divider, Typography} from "@mui/material";
import CloseIcon from "@/icons/Close";
import styles from "../styles/styles";


const StaticAnnouncements = (props) => {
    const {announcements, onClose} = props;


    if (_.isEmpty(announcements)) {
        return null;
    }

    return (
        <Box sx={styles.announcementContentBox}>
            <Box sx={styles.announcementBox}>
                <Box sx={styles.closeIconBox} onClick={onClose}>
                    <CloseIcon color={'primary'}/>
                </Box>
                <Box sx={styles.announcements}>
                    {
                        announcements.map((announcement, index) => {
                            return (
                                <Box key={index}>
                                    <Box>
                                        <Typography
                                            component={'p'}
                                            variant={'h6'}
                                            dangerouslySetInnerHTML={{__html: parseToHTML(announcement.content)}}
                                        />
                                    </Box>
                                    {(announcements.length - 1 !== index) && <Divider sx={styles.divider}/>}
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default StaticAnnouncements;