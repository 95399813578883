import Transformer from "../Transformer";

class TranslationTransformer extends Transformer {
    static transform(translation, meta) {
        const {...result} = translation;

        return result;
    }
}

export default TranslationTransformer;