export const transformErrors = (errors) => {
    let parsed = {};

    for (let key in errors) {
        let message = errors[key];

        if (message instanceof Array) {
            message = message[0];

            if (message instanceof Array) {
                message = message[0];
            }
        }

        message = message.replace('validation\.', '').replace('.', '_');

        parsed = _.set(parsed, key, message)
    }

    return parsed;
};