import React, {useState, useRef, forwardRef} from 'react';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MuiGrow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';

import ProfileImage from '../ProfileImage/ProfileImage';
import ProfileView from '../ProfileView/ProfileView';
import globalHook from "../../state/state";
import {styles} from './styles/styles';

//TODO: should be removed after removing material v4
const Grow = forwardRef((props, ref) => {
    return <MuiGrow  {...props} ref={ref} timeout={10}/>
});

const ProfileDropDown = props => {
    const {user, onDetailsButtonClick} = props;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const profileImageRef = useRef();
    const [state, actions] = globalHook();
    const {image} = state;

    const handleDetailsButtonClick = () => {
        setIsMenuOpen(false);
        onDetailsButtonClick();
    };

    return (
        <Box display={'flex'} alignItems={'center'} sx={styles.root}>
            <IconButton onClick={() => setIsMenuOpen(true)}>
                <ProfileImage
                    ref={profileImageRef}
                    src={image ? image : _.get(user, 'avatar.url')}
                    size={32}
                    iconSize={18}
                    showStatus={true}
                    status={_.get(user, 'status')}
                    user={user}
                />
            </IconButton>

            {
                user &&
                <Menu
                    open={isMenuOpen}
                    anchorEl={profileImageRef.current}
                    onClose={() => setIsMenuOpen(false)}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    TransitionComponent={Grow}
                    sx={styles.menu}
                >
                    <ProfileView
                        onDetailsButtonClick={handleDetailsButtonClick}
                        onClose={() => setIsMenuOpen(false)}
                    />
                </Menu>
            }
        </Box>
    )
};

export default ProfileDropDown;