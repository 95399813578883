export const PARAM_TYPES = {
    YES_NO: 0,
    NUMERIC: 1,
    TEXT: 2,
    DATE: 3,
    BED: 4,
};

export const INITIAL_FILTERS = {
    startDate: null,
    endDate: null,
    adults_count: 1,
    babies_count: 0,
    type: null,
    location: null,
    nights_count: 2,
    floors_count: 1,
    bedrooms_count: 1,
    rooms_count: 1,
    price_range: [0, 20000],
    size: [0, 5000],
    checkin_weekday: null,
    regions: null,
    parameters: [],
};

export const BOOKING_STATUSES = {
    RESERVED: 0,
    RESERVATION_CANCELLED: 1,
    PRE_BOOKED: 2,
    PRE_BOOKED_CANCELLED: 3,
    BOOKED: 4,
    CANCELLED: 5,
    REFUND_REQUESTED: 6,
    REFUNDED: 7,
}

export const ERROR_CODES = {
    'HAS_ALREADY_RESERVED_BOOKING_FOR_THE_SAME_PROPERTY': 4001,
    'ACHIEVED_MAXIMUM_ALLOWED_RESERVATION_OF_BOOKINGS': 4002,
    'PROPERTY_IS_NOT_AVAILABLE_FOR_THE_PERIOD': 4003,
    'NOT_ENOUGH_POINTS': 4008
}

export const PERIOD_TYPE = {
    FCFG: 0,
    RENTING: 1,
}
export const DAILY_STATUSES = {
    blocked: 0,
    booked: 1,
    locked: 2,
};
export const DAY_TYPES = {
    FCFG: 0,
    RENTING: 1,
}

export const APPLICATION_STATUSES = {
    CREATED: 0,
    ACCEPTED: 1,
    REJECTED: 2,
    CANCELLED: 3,
    PAID: 4,
    PROCESS: null,
}

export const APPLICATION_ROW_STATUSES = {
    CREATED: 0,
    ACCEPTED: 1,
    REJECTED: 2,
    CANCELLED: 3,
    PAID: 4,
    PROCESS: null,
}

export const PAYMENT_STATUSES = {
    CREATED: 1,
    COMPLETED: 2,
    CANCELLED: 3,
    FAILED: 4,
};

export const CREATORS = {
    SYSTEM: 0,
    ADMIN: 1,
    MEMBER: 2,
}
