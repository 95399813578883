import useGlobalHook from 'components/UseGlobalHook/UseGlobalHook';
import React from "react";


const initialState = {
    image: '',
};

export const actions = {
    setImage: (store, image, callBack)=> {
        store.setState({image}, callBack)
    }
};

const globalHook = useGlobalHook(React, initialState, actions);

export default globalHook;