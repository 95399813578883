import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'formik';
import FormHelperText from "@mui/material/FormHelperText";

import {AutoCompleteField} from 'components/Inputs/Inputs';
import {getFieldError, getFieldValue, handleFieldBlur, handleFieldChange, hasFieldError} from "../../services/services";


const ActiveAutoComplete = props => {
    const {formik, onBlur, onError, onChange, ...autoCompleteProps} = props;

    const handleChange = (name, option) => {
        if (onChange) {
            onChange(name, option);
        } else {
            handleFieldChange(name, option, formik);
        }
    };

    const handleBlur = (name, option) => {
        if (onBlur) {
            onBlur(name, option);
        } else {
            handleFieldBlur(name, formik);
        }
    };

    const value = getFieldValue(props.name, formik);
    const hasError = hasFieldError(props.name, formik);
    const errorText = getFieldError(props.name, formik, onError);

    return (
        <>
            <AutoCompleteField
                {...autoCompleteProps}
                value={value}
                onBlur={handleBlur}
                onChange={handleChange}
                hasError={hasError}
            />
            {
                hasError &&
                <FormHelperText error={hasError}>
                    {errorText}
                </FormHelperText>
            }
        </>
    );
};

ActiveAutoComplete.propTypes = {
    name: PropTypes.string.isRequired,
    onError: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

export default connect(ActiveAutoComplete);