import * as api from 'services/api/api';

export const getMemberPhoneNumbers = (data) => {
    return api.getRequest('/unm/member_phone_numbers', data)
}

export const getMemberPhoneNumber = (id, data) => {
    return api.getRequest(`/unm/member_phone_numbers/${id}`, data)
}

export const updateMemberPhoneNumber = (id, data) => {
    return api.putRequest(`/unm/member_phone_numbers/${id}`, data);
}

export const createMemberPhoneNumber = (data) => {
    return api.postRequest(`/unm/member_phone_numbers`, data);
}

export const deleteMemberPhoneNumber = (id) => {
    return api.deleteRequest(`/unm/member_phone_numbers`, id);
}