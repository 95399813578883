import React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

import {styles} from './styles/styles';
import useTranslate from "components/UseTranslate/useTranslate";

const Status = props => {
    const {active} = props;

    const {t} = useTranslate();

    const statusText = active ? `${t(`active`)}` : `${t(`disabled`)}`;

    return (
        <Box sx={(theme) => styles.status(theme, active)}>
            <Typography variant={'p4Bold'} sx={styles.statusText}>
                {statusText}
            </Typography>
        </Box>
    );
};

export default Status;