import Transformer from 'services/transform/resource/transformers/Transformer';

class PropertyParameterTransformer extends Transformer {
    static transform(parameter, meta) {
        const {name, icon, ...result} = parameter;

        if (name) {
            result.name = name.data;
        }

        if (icon) {
            result.icon = icon.data;
        }

        return result;
    }
}

export default PropertyParameterTransformer;