import React from 'react';
import ActiveForm, {ActiveInput} from "@/components/ActiveForm/ActiveForm";
import {Form} from "formik";
import notification from "@/services/notification/notification";
import useTranslate from "@/components/UseTranslate/useTranslate";
import {Dialog} from "@mui/material";
import styles from '../../../styles/styles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {updateAuthUser} from "@/services/api/auth/auth";
import * as Yup from "yup";

const UpdateBankInformationModal = (props) => {
    const {bankInformation, open, updateData, onClose} = props;
    const {t} = useTranslate();

    const onUpdate = async (data) => {
        const formData = new FormData();

        for (let key in data) {
            formData.append(key, data[key]);
        }

        return await updateAuthUser(formData);
    }

    const onSuccess = (mode, form, values, resp) => {
        notification.success(t('msg_bank_info_update'));
        updateData(values);
        onClose();
    }

    return (
        <Dialog
            open={open}
            sx={styles.emailModal}
            className={'modal'}
            onClose={onClose}
        >
            <Box className={'modalHeader'}>
                <Box className={'modalHeaderInner'}>
                    <Typography variant='h4'>
                        {t('title_bank_info')}
                    </Typography>
                </Box>
            </Box>

            <ActiveForm
                initialValues={{...bankInformation}}
                data={{...bankInformation}}
                onSubmit={onUpdate}
                onSuccess={onSuccess}
                onError={() => {
                    notification.warning(t('error'))
                }}
                validationSchema={Yup.object().shape({
                    bank_name: Yup.string().required('required').length(4, 'length'),
                    bank_account_type: Yup.string().required('required').length(2, 'length'),
                    bank_account_number: Yup.string().required('required').length(6, 'length'),
                })}
                render={(formProps) => {
                    const {handleSubmit, isSubmitting} = formProps;

                    return (
                        <Form onSubmit={handleSubmit} className='p-0'>
                            <Box className={'modalBody'}>
                                <Box pb={3} sx={styles.addressRow}>
                                    <Box width={'100%'} pr={3} className={'zip_code'}>
                                        <ActiveInput
                                            name='bank_name'
                                            placeholder={t('bank')}
                                            label={`${t('bank')}`}
                                            onError={error => t(`error_bank_name_${error}`, {
                                                length: 4
                                            })}
                                        />
                                    </Box>
                                    <Box width={'100%'} className={'address'}>
                                        <ActiveInput
                                            fullWidth
                                            name='bank_account_type'
                                            placeholder={t('ledger')}
                                            label={`${t('ledger')}`}
                                            onError={error => t(`${error}`, {
                                                length: 2
                                            })}
                                        />
                                    </Box>
                                </Box>
                                <Box width={'100%'}>
                                    <ActiveInput
                                        fullWidth
                                        name='bank_account_number'
                                        placeholder={t('account_number')}
                                        label={`${t('account_number')}`}
                                        onError={error => t(`error_account_number_${error}`, {
                                            length: 6
                                        })}
                                    />
                                </Box>
                            </Box>
                            <Box className={'modalFooter'}>
                                <Box className={'modalFooterInner'}>
                                    <Button variant='contained' color="secondary" onClick={onClose}>
                                        {t('cancel')}
                                    </Button>
                                    <Button type={'button'} onClick={handleSubmit} variant='contained'
                                            disabled={isSubmitting}>
                                        {t('save')}
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    )
                }}
            />
        </Dialog>
    )
}

export default UpdateBankInformationModal;