import Transformer from "../Transformer";

class ImageTransformer extends Transformer {
    static transform(image, meta) {
        const {...result} = image;

        return result;
    }
}

export default ImageTransformer;