import React, {useEffect} from 'react';
import moment from 'moment/moment';
import GeneralDetails from '../../components/GeneralDetails/GeneralDetails';
import {parseToHTML} from "@/services/formatters/parseToHtml/parseToHtml";
import {Box, Typography} from "@mui/material";
import styles from './styles/styles';
import useTranslate from "@/components/UseTranslate/useTranslate";
import Terms from "@/modules/PVC/pages/Offer/components/Details/components/Terms/Terms";
import {displayDateFormat} from "@/config";

let timer = null;

const DetailContent = props => {
    const {item} = props;

    const {t} = useTranslate();

    item.branches = item.branches.filter(branch => branch.offers.some(offer => offer.status && offer.price));

    const today = new Date();
    const availablePeriods = item.periods.filter(period => {
        const periodAvailable = period.status && (new Date(period.end_date) > today);

        const periodHasOffer = item.branches.some(
            branch => branch.offers.some(
                offer => offer.status && offer.price && offer.period_id === period.id,
            )
        );

        return periodAvailable && periodHasOffer;
    });

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const handleResize = () => {
        clearTimeout(timer);
    };

    return (
        <Box sx={styles.root}>
            <Box sx={styles.generalDetails}>
                <Typography variant={'h3'}>
                    {`${item.account.name}`}
                </Typography>
                <Typography
                    variant={'p3'}
                    sx={styles.longDesc}
                    component={'p'}
                    dangerouslySetInnerHTML={{__html: parseToHTML(item.long_description)}}
                >
                </Typography>

                <Typography variant={'p2Bold'}>
                    {t('periods')}
                </Typography>
                {
                    availablePeriods.map((period, index) => {
                        const startDate = moment(period.start_date).format(displayDateFormat);
                        const endDate = moment(period.end_date).format(displayDateFormat);

                        return (
                            <Box key={index} sx={styles.periodsBlock}>
                                <Typography variant={'p4Bold'} sx={styles.periodTitle}>
                                    {period.title}:
                                </Typography>
                                <Typography variant={'p4'}>
                                    {`${startDate} - ${endDate}`}
                                </Typography>
                            </Box>
                        );
                    })
                }
            </Box>
            {
                item.terms &&
                <Terms data={item.terms}/>
            }

            <GeneralDetails item={item} periods={availablePeriods}/>
        </Box>
    )
};

export default DetailContent;