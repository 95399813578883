const styles = {
    announcementContentBox: (theme) => ({
        position: 'relative',
    }),
    announcementBox: (theme) => ({
        position: 'fixed',
        top: 40,
        right: 40,
        zIndex: theme.zIndex.drawer,
        width: '300px',
        backgroundColor: theme.palette.common.white,
        borderRadius: '5px',
        boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",

        [theme.breakpoints.down('md')]: {
            marginRight: 'auto',
            marginLeft: 'auto',
            top: theme.spacing(3),
        },
    }),
    closeIconBox: (theme) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        cursor: 'pointer',
    }),
    announcements: (theme) => ({
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    }),
    divider: (theme) => ({
        divider: {
            backgroundColor: theme.palette.common.black,
            height: '1px',
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
    }),
    announcementModal: (theme) => ({
        '& .MuiPaper-root': {
            maxWidth: 570,
            width: 570,
        }
    })
}

export default styles;