import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {parseToHTML} from "@/services/formatters/parseToHtml/parseToHtml";
import {Box, Collapse, Typography} from "@mui/material";
import ArrowDropDown from "@/icons/ArrowDropDown";
import ArrowDropUp from "@/icons/ArrowDropUp";
import styles from './styles/styles';
import useTranslate from "@/components/UseTranslate/useTranslate";

const Terms = props => {
    const {data} = props;
    const [open, setOpen] = useState(false);
    const {t} = useTranslate();

    const toggleCollapse = () => {
        setOpen(!open);
    };

    return (
        <Box sx={styles.root}>
            <Box onClick={toggleCollapse} sx={styles.btnBlock}>
                <Box sx={styles.viewTermsBtn}>
                    {open ? <ArrowDropDown color={'primary'}/> : <ArrowDropUp color={'primary'}/>}
                </Box>
                <Typography variant={'p4Bold'} sx={styles.title}>
                    {t('view_terms')}
                </Typography>
            </Box>
            <Collapse in={open} timeout={'auto'}>
                <Typography
                    component={'p'}
                    variant={'p3'}
                    sx={styles.description}
                    dangerouslySetInnerHTML={{
                        __html: parseToHTML(data)
                    }}>
                </Typography>
            </Collapse>
        </Box>
    )
};

Terms.propTypes = {
    data: PropTypes.string,
};

export default Terms;