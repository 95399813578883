import React from "react";
import {Suspense, useEffect, useState} from "react";
import {currentModal} from './currentModal'
import Loader from "@/components/Loader/Loader";

export const GlobalModalsRenderer = () => {
    const [modal, updateCurrentModal] = useState(null);
    useEffect(() => currentModal.subscribe(updateCurrentModal), []);

    if (modal) {
        const Modal = currentModal.get(modal.name);

        return (
            <Suspense fallback={<Loader/>}>
                <Modal {...modal?.props} name={modal?.name}/>
            </Suspense>
        )
    }

    return null;
}