import React from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import PlusIcon from '@/icons/Plus';
import MinusIcon from '@/icons/Minus';

import {styles} from './styles/styles';

const NumberPicker = props => {
    const {value, min, max, step, width, disabled, editable, onChange} = props;

    const increment = () => {
        if (disabled) {
            return;
        }

        const newValue = Math.min(max, value + step);

        if (value !== newValue) {
            onChange(newValue);
        }
    };

    const decrement = () => {
        if (disabled) {
            return;
        }

        const newValue = Math.max(min, value - step);

        if (value !== newValue) {
            onChange(newValue);
        }
    };

    const handleChange = e => {
        let val = parseInt(e.target.value) || 0;
        val = Math.min(max, Math.max(min, val));

        if (value !== val) {
            onChange(val);
        }
    };

    return (
        <Box display='flex' alignItems='center' justifyContent='center' style={{width: width}}>
            <MinusIcon onClick={decrement} sx={(theme) => styles.pickerIcons(theme, disabled)}/>
            <InputBase
                inputProps={{sx: styles.pickerInput}}
                disabled={disabled}
                readOnly={!editable}
                value={value}
                onChange={handleChange}
            />
            <PlusIcon onClick={increment} sx={(theme) => styles.pickerIcons(theme, disabled)}
            />
        </Box>
    )
};

NumberPicker.defaultProps = {
    value: 1,
    min: 1,
    max: Infinity,
    step: 1,
    width: 90,
    disabled: false,
    editable: true,
    onChange: () => undefined,
};

NumberPicker.propTypes = {
    value: PropTypes.number,
    max: PropTypes.number,
    min: PropTypes.number,
    width: PropTypes.number,
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    onChange: PropTypes.func,
};

export {NumberPicker};