import React from 'react';
import Box from '@mui/material/Box';
import LangSwitcher from "@/layouts/components/Header/components/Heading/components/LangSwitcher/LangSwitcher";

const RightSection = () => {

    return (
        <Box>
            <Box display={'flex'} alignItems={'center'}>
                <LangSwitcher mode={'light'}/>
            </Box>
        </Box>
    )
};

export default RightSection;