import Transformer from "./transformers/Transformer";

class Resource {
    /**
     *
     * @param {Transformer} transformer
     * @param {Object|array} data
     * @param {Object} meta
     */
    constructor(transformer, data, meta = {}) {
        if (!transformer instanceof Transformer) {
            throw new Error('transformer should be instance of Transformer');
        }

        if (!_.isObject(data) && !_.isArray(data)) {
            throw new Error('data should be an object or an array');
        }

        this.transformer = transformer;
        this.data = data;
        this.metaData = meta;
    }

    /**
     *
     * @param {Transformer} transformer
     * @param {Object|array} data
     * @param {Object} meta
     * @returns {{data, meta}}
     */
    static transform(transformer, data, meta = {}) {
        const resource = new Resource(transformer, data, meta);

        if (_.isArray(data)) {
            return resource.collection();
        }

        return resource.item();
    }

    /**
     *
     * @returns {{data: *, meta: *}}
     */
    item() {
        return {
            data: this.transformer.transform(this.data, this.metaData),
            meta: this.transformer.meta(this.metaData),
        }
    }

    /**
     *
     * @returns {{data: *, meta: *}}
     */
    collection() {
        return {
            data: this.data.map(item => this.transformer.transform(item, this.metaData)),
            meta: this.transformer.meta(this.metaData),
        }
    }
}

export default Resource;