import React from "react";
import useTranslate from "@/components/UseTranslate/useTranslate";
import notification from "@/services/notification/notification";
import styles from "@/layouts/components/Header/components/ProfileInformation/styles/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Dialog} from "@mui/material";
import {deleteMemberPhoneNumber} from "@/modules/UnionMember/services/api/phoneNumbers/phoneNumbers";

const DeletePhoneNumberModal = (props) => {
    const {open, onClose, onSuccess, data} = props;

    const {t} = useTranslate();

    const onDelete = () => {
        deleteMemberPhoneNumber(data.id)
            .then(() => {
                notification.success(t('alert_phone_delete_success'));
                onSuccess();
            })
            .catch(() => {
                notification.warning(t('something_wrong'));
            })
    }

    if (!data) {
        return null;
    }

    return (
        <Dialog
            open={open}
            sx={styles.emailModal}
            className={'modal'}
            onClose={onClose}
        >
            <Box className={'modalHeader'}>
                <Box className={'modalHeaderInner'}>
                    <Typography variant='h4'>
                        {t('title_delete_phone_number')}
                    </Typography>
                </Box>
            </Box>
            <Box display={'flex'} flexDirection={'column'} className={'modalBody'}>
                <Typography variant={'p3'} sx={styles.deleteModalText}>{t('delete_phone_number')}</Typography>
                <Typography variant={'p2'} sx={styles.deleteModalValue}>
                    {`+${data.code} ${data.number.slice(0, 3)}-${data.number.slice(3)}`}
                </Typography>
            </Box>
            <Box className={'modalFooter'}>
                <Box className={'modalFooterInner'}>
                    <Button variant='contained' color="secondary" onClick={onClose}>
                        {t('close')}
                    </Button>
                    <Button type={'button'} onClick={onDelete} variant='contained'>
                        {t('delete')}
                    </Button>
                </Box>
            </Box>

        </Dialog>
    )
}

export default DeletePhoneNumberModal;