import React from 'react';
import {withRouter} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import storage from "services/storage/storage";
import {getNonUnmAuthorizedUser, loginAsNonUnionMember} from "@/modules/NonUNM/services/api/auth/auth";
import useTranslate from "@/components/UseTranslate/useTranslate";

const Login = ({history}) => {
    const {t} = useTranslate();
    
    if (storage.get('nonUnmAuth')) {
        return history.push('/non_unm/applications');
    }

    const onSubmit = async (values, form) => {
        const response = await loginAsNonUnionMember(values.ssn);
        const authUser = await getNonUnmAuthorizedUser();

        await storage.set('nonUnmAuth', authUser.data);
        history.push('/non_unm/applications');
    }

    return (
        <Formik
            initialValues={{
                ssn: '',
            }}

            validationSchema={Yup.object().shape({
                ssn: Yup.string().min(10, 'min').required('required'),
            })}

            onSubmit={onSubmit}

            render={(form) => {
                const {values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit} = form;

                return (
                    <form onSubmit={handleSubmit} className="login-form">
                        <TextField
                            type="ssn"
                            name="ssn"
                            placeholder={t('ssn')}
                            error={!!(touched.ssn && errors.ssn)}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={!!(touched.ssn && errors.ssn) && t(`error_ssn_${errors.ssn}` || '', {
                                min: 10
                            })}
                            margin='normal'
                            fullWidth
                        />

                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Button disabled={isSubmitting} variant="contained" color="primary" type="submit">
                                {t('login_non_member')}
                            </Button>
                        </Box>
                    </form>
                )
            }}/>
    )
};

export default withRouter(Login);

