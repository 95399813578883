import React from "react";
import AnnouncementModal from "./components/AnnouncementModal/AnnouncementModal";

const GeneralAnnouncements = (props) => {
    const {announcements, onClose} = props;

    if (announcements.length === 0) {
        return null;
    }

    return (
        announcements.map((announcement, index) => {
            return (
                <AnnouncementModal
                    key={index}
                    open={!announcement.is_closed}
                    onClose={() => onClose(announcement)}
                    announcement={announcement}
                />
            )
        })
    )
}

export default GeneralAnnouncements;