import Transformer from 'services/transform/resource/transformers/Transformer';
import {
    AccountTransformer,
    Resource,
    UserTransformer,
} from 'services/transform/transform';


class MemberEmailTransformer extends Transformer {
    static transform(emails) {
        const {account, user, ...result} = emails;

        if (account) {
            result.account = Resource.transform(AccountTransformer, account.data, account.meta).data;
        }

        if (user) {
            result.user = Resource.transform(UserTransformer, user.data, user.meta).data;
        }

        return result;
    }
}

export default MemberEmailTransformer;