import React, {useEffect, useState} from 'react';
import Offer from "@/modules/PVC/pages/Offer/components/Offers/components/Offer/Offer";
import {Container, Grid} from "@mui/material";

const HotelInfoCard = () => {
    const [offer, setOffer] = useState();

    useEffect(() => {
        window.removeEventListener("message", receiveMessage);
        window.addEventListener("message", receiveMessage);
        parent.postMessage("loaded", "*");
    }, []);

    const receiveMessage = e => {
        setOffer(e.data);
    };

    if (!offer) {
        return null;
    }

    return (
        <Container fixed sx={{margin: 0, padding: 0}}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <Offer offer={offer}/>
                </Grid>
            </Grid>
        </Container>
    )
};

export default HotelInfoCard;