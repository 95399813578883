import Transformer from 'services/transform/resource/transformers/Transformer';
import {
    InvoiceTransformer,
    Resource,
    UserTransformer,
} from 'services/transform/transform';

import {PropertyTransformer} from 'modules/Accommodation/services/transform/transform'
import ServicesTransformer from "@/modules/Accommodation/services/transform/resource/transformers/Services/Services";

class BookingTransformer extends Transformer {
    static transform(booking, meta) {
        const {user, invoice, invoices, property, status, services, refund, ...result} = booking;

        if (user) {
            result.user = Resource.transform(UserTransformer, user.data).data;
        }

        if (property) {
            result.property = Resource.transform(PropertyTransformer, property.data).data;
        }

        if (status) {
            result.status = status.data;
        }

        if (invoice) {
            result.invoice = Resource.transform(InvoiceTransformer, invoice.data).data;
        }

        if (invoices) {
            result.invoices = Resource.transform(InvoiceTransformer, invoices.data).data;
        }

        if (services) {
            result.services = Resource.transform(ServicesTransformer, services.data).data
        }

        if (refund) {
            result.refund = refund.data
        }

        const start_date = new Date(result.start_date);
        const end_date = new Date(result.end_date);
        const dateDiff = new Date(end_date.getTime() - start_date.getTime());

        result.nights_count = dateDiff.getUTCDate() - 1;

        return result;
    }
}

export default BookingTransformer;