import Transformer from "services/transform/resource/transformers/Transformer";

class PeriodTransformer extends Transformer {
    static transform(period, meta) {
        const {renting, ...result} = period;

        if (renting) {
            result.renting = renting.data
        }

        return result;
    }
}

export default PeriodTransformer