import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Applications from "@/modules/NonUNM/pages/Applications/Applications";
import storage from "@/services/storage/storage";
import {default as NonUNMLogin} from "@/modules/NonUNM/pages/Login/Login";
import NotFound from "@/pages/NotFound/NotFound";

const PrivateNonUNMRoute = ({component: Component, ...rest}) => {
    let loggedIn = storage.get('nonUnmAuth', null);

    if (!loggedIn) {
        return <Redirect to={'/login'}/>;
    }
    
    return (
        <Route {...rest} render={props => {
            return <Component {...props}/>;
        }}/>
    );
};


const NonUNM = () => {
    return (
        <Switch>
            <PrivateNonUNMRoute
                component={Applications}
                path="/:lang(is|en)?/:module(non_unm)/:page(applications)/:action(w+)?"
            />
            <PrivateNonUNMRoute
                component={Applications}
                exact
                path='/:lang(is|en)?'
            />
            <Route path="/:lang(is|en)?/:module(non_unm)/:page(login)" component={NonUNMLogin}/>
            <Route path='/:lang(is|en)?/:page(not_found)' component={NotFound}/>
            
            <Redirect to="/not_found" />
        </Switch>
    )
};

export default NonUNM;