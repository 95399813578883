const drawerWidthDesktop = 652;
const drawerWidthTablet = 652;
const drawerWidthMobile = 375;

const styles = {
    langButton: (theme) => ({
        backgroundColor: theme.palette.hexToRGBA(theme.palette.common.white, 0.2),
        padding: '7px 10px 7px 8px',
        borderRadius: theme.spacing(0.5),
        color: theme.palette.grey[700],
    }),
    langIcon: (theme) => ({
        fontSize: `22px !important`,
        color: theme.palette.grey[700],
    }),
    profileModal: () => ({
        '& .MuiPaper-root': {
            maxWidth: 472,
            width: 472,
        }
    }),
    drawer: (theme) => ({
        flexShrink: 0,

        '& .MuiDrawer-paper': {
            width: drawerWidthDesktop,
            boxShadow: theme.shadows[16],
            backgroundColor: theme.palette.common.white,

            [theme.breakpoints.down('lg')]: {
                width: drawerWidthTablet,
            },

            [theme.breakpoints.down('md')]: {
                width: drawerWidthMobile,
            },

            [theme.breakpoints.down('sm')]: {
                width: '100%',
                boxShadow: 'none',
                borderRight: 'none',
            }
        },
    }),
}

export default styles;