import React, {forwardRef} from 'react';
import {Formik} from 'formik';
import PropTypes from 'prop-types';
import {transformErrors} from "services/transform/transform";

import ActiveInput from "./components/ActiveInput/ActiveInput";
import ActiveCheckbox from "./components/ActiveCheckbox/ActiveCheckbox";
import ActiveSelect from "./components/ActiveSelect/ActiveSelect";


class ActiveForm extends React.Component {
    static defaultProps = {
        data: null,
        validateOnChange: true,
        onSuccess: () => null,
        onError: () => null,
    };

    static propTypes = {
        data: PropTypes.object,
        onSuccess: PropTypes.func,
        onError: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        render: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {createMode: this.isCreate() ? 'single' : null}
    }

    onSubmit = (values, form) => {
        const {onSubmit, onSuccess, onError} = this.props;

        onSubmit(values, form).then(resp => {
            form.setSubmitting(false);

            onSuccess(this.state.createMode, form, values, resp)
        }).catch(resp => {
            const errors = transformErrors(resp.errors);
            form.setSubmitting(false);
            form.setErrors(errors);

            onError(this.state.createMode, form, values, errors, resp);
        })
    };

    isCreate = () => {
        return !this.props.data;
    };

    seCreateMode = (createMode) => {
        if (!this.isCreate()) {
            return;
        }

        this.setState({createMode: createMode})
    };

    renderChildren = (formProps) => {
        formProps.isCreate = this.isCreate();
        formProps.setCreateMode = this.seCreateMode;

        return this.props.render(formProps);
    };

    render() {
        const {data, validationSchema, initialValues, render, onSubmit, onSuccess, onError, innerRef, ...props} = this.props;

        return (
            <Formik
                {...props}
                ref={innerRef}
                validationSchema={validationSchema}
                initialValues={data || initialValues}
                onSubmit={this.onSubmit}
                render={this.renderChildren}
            />
        )
    }
}

export {ActiveInput, ActiveCheckbox, ActiveSelect};

export default forwardRef((props, ref) =>
    <ActiveForm
        innerRef={ref}
        {...props}
    />
);
