import React, {useState} from "react";
import useTranslate from "@/components/UseTranslate/useTranslate";
import {Box, Typography} from "@mui/material";
import styles from "@/layouts/components/Header/components/ProfileInformation/styles/styles";
import UpdateBankInformationModal
    from "@/layouts/components/Header/components/ProfileInformation/components/BankInformation/components/UpdateBankInformationModal";
import {useAuth} from "@/providers/Auth/AuthProvider";
import Pencil from "@/icons/Pencil";

const BankInformation = () => {
    const {t} = useTranslate();

    const {user, updateUser} = useAuth();

    const {bank_account_number, bank_account_type, bank_name} = user;

    const bankInformation = {
        bank_account_number,
        bank_account_type,
        bank_name
    };

    const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);

    const closeUpdateModal = () => {
        setIsOpenUpdateModal(false)
    }

    const openUpdateModal = () => {
        setIsOpenUpdateModal(true)
    }

    const handleOnSuccess = (data) => {
        user.bank_account_number = data.bank_account_number;
        user.bank_account_type = data.bank_account_type;
        user.bank_name = data.bank_name;

        updateUser(user)
    }


    return (
        <>
            <Box>
                <Typography variant={'p2'} className={'title'}>{t('title_bank_info')}</Typography>
                <Box sx={styles.itemBox}>
                    <Box sx={styles.itemNameBox}>
                        <Box sx={styles.preTitleBlock}>
                            <Typography variant={'p3Bold'}
                                        className={'item-name'}>{t('bank_information')}</Typography>
                            <Box className={'update-block'}>
                                <Pencil onClick={() => openUpdateModal(bankInformation)}
                                        color={'primary'}/>
                            </Box>
                        </Box>
                    </Box>
                    {
                        bank_name &&
                        <Typography variant={'p3'}>
                            {`${bank_name} - ${bank_account_type} - ${bank_account_number}`}
                        </Typography>
                    }
                </Box>
            </Box>

            <UpdateBankInformationModal
                bankInformation={bankInformation}
                open={isOpenUpdateModal}
                updateData={handleOnSuccess}
                onClose={closeUpdateModal}
            />
        </>
    )
}

export default BankInformation;