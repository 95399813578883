import Transformer from "../Transformer";

class PointTransactionTransformer extends Transformer {
    static transform(transaction, meta) {
        const {...result} = transaction;

        return result;
    }
}

export default PointTransactionTransformer;