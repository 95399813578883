import React from 'react';
import Box from '@mui/material/Box';

import LeftSection from './components/LeftSection/LeftSection';
import RightSection from './components/RightSection/RightSection';


const Heading = props => {
    const {openMenu} = props;

    return (
        <Box display={'flex'} flex={1} justifyContent={'space-between'} alignItems={'center'} height={'100%'}>
            <LeftSection onClick={openMenu}/>
            <RightSection/>
        </Box>
    )
};

export default Heading;