import React from 'react';
import styles from "@/layouts/components/Menu/styles/styles";
import storage from "@/services/storage/storage";
import {useAuth} from "@/providers/Auth/AuthProvider";

export default () => {
    const {checkAccess} = useAuth();

    return [
        {
            nodeId: '2',
            label: 'accommodation',
            sx: styles.itemRoot,
            show: checkAccess('acm_member_privileges'),
            items: [
                {
                    nodeId: '21',
                    label: 'offers',
                    path: `/${storage.get('lang')}/acm/properties/search`,
                    show: checkAccess('acm_member_privileges')
                },
                {
                    nodeId: '22',
                    label: 'overview_calendar',
                    path: `/${storage.get('lang')}/acm/overview_calendar`,
                    show: checkAccess('acm_member_privileges')
                },
            ]
        },
    ];
}