export const styles = {
    pickerIcons: (theme, disabled) => ({
        cursor: 'pointer',
        color: disabled ? theme.palette.action.disabled : theme.palette.grey[400],
    }),
    pickerInput: (theme) => ({
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        textAlign: 'center',
        ...theme.typography.p2,
        color: theme.palette.grey[600],
    }),
    count: (theme) => ({
        color: theme.palette.grey[600],
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    })
};