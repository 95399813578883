import React from 'react';
import styles from "@/layouts/components/Menu/styles/styles";
import storage from "@/services/storage/storage";
import {useAuth} from "@/providers/Auth/AuthProvider";

export default () => {
    const {checkAccess} = useAuth();

    return [
        {
            nodeId: '1',
            label: 'grant',
            path: `/${storage.get('lang')}/dk-plus/grants`,
            sx: styles.itemRoot,
            show: checkAccess('unm_member_privileges') && checkAccess('dk_plus_member_privileges'),
        },
    ];
}