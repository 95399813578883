const styles = {
    wrapper: (theme, footerHeight) => ({
        minHeight: `calc(100vh - ${footerHeight?.footerLGHeight}px)`,

        [theme.breakpoints.down('md')]: {
            minHeight: `calc(100vh - ${footerHeight?.footerMDHeight}px)`,
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: `calc(100vh - ${footerHeight?.footerSMHeight}px)`,
        },
    }),
}

export default styles