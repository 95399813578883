import React from 'react';
import {Route, Switch} from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute'
import Home from "pages/Home/Home";
import Loader from "@/components/Loader/Loader";

const PVC = React.lazy(() => import('@/modules/PVC/PVC'));
const Accommodation = React.lazy(() => import('@/modules/Accommodation/Accommodation'));
const UnionMember = React.lazy(() => import('@/modules/UnionMember/UnionMember'));
const Translations = React.lazy(() => import('@/pages/Translations/Translations'));
const Activities = React.lazy(() => import('@/pages/Activities/Activities'));
const Credit = React.lazy(() => import('@/pages/Credit/Credit'));
const Points = React.lazy(() => import('@/pages/Points/Points'));
const DkPlus = React.lazy(() => import('@/modules/DkPlus/DkPlus'));
const NotFound = React.lazy(() => import('@/pages/NotFound/NotFound'));

const Routes = () => {
    return (
        <React.Suspense fallback={<Loader/>}>
            <Switch>
                <PrivateRoute exact path='/:lang(is|en)?' component={Home}/>
                <PrivateRoute path={"/:lang(is|en)?/:module(pvc)/:page(\w+)?"} component={PVC}/>
                <PrivateRoute path={"/:lang(is|en)?/:module(acm)/:page(\w+)?"} component={Accommodation}/>
                <PrivateRoute path={"/:lang(is|en)?/:module(dk-plus)/:page(\w+)?"} component={DkPlus}/>
                <PrivateRoute path={"/:lang(is|en)?/:module(unm)/:page(\w+)?"} component={UnionMember}/>
                <PrivateRoute path={"/:lang(is|en)?/:page(home)"} component={Home}/>
                <PrivateRoute path={"/:lang(is|en)?/:page(credit)"} component={Credit}/>
                <PrivateRoute path={"/:lang(is|en)?/:page(points)"} component={Points}/>
                <PrivateRoute exact path={"/:lang(is|en)?/:page(translations)"} component={Translations}/>

                {/*Not Found page*/}
                <Route path="*"><NotFound/></Route>
            </Switch>
        </React.Suspense>
    )
};

export default Routes;