import React, {useState} from 'react';
import {withRouter} from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {Container, FormControlLabel, Switch} from "@mui/material";
import Box from "@mui/material/Box";
import PublicLayout from "@/layouts/Public/Public";
import {loginAudkenni, verifyAudkenni} from "@/services/api/auth/auth";
import styles from "@/pages/Login/components/LoginForm/styles/styles";
import ActiveForm, {ActiveInput} from "@/components/ActiveForm/ActiveForm";
import Divider from "@mui/material/Divider";
import useTranslate from "@/components/UseTranslate/useTranslate";
import notification from "@/services/notification/notification";

const TYPE_SIM = 0;
const TYPE_APP = 1;

const AudkenniLogin = (props) => {
    const [isSSN, setIsSSN] = useState(true);
    const [timer, setTimer] = useState(30);
    const [showCounter, setShowCounter] = useState(false);

    const {t} = useTranslate();

    const handleSwitchChange = () => {
        setIsSSN(!isSSN);
    };

    const login = async (values) => {
        const data = isSSN ? {ssn: values.ssn} : {phone: values.phone};
        const response = await loginAudkenni(data);

        return response.data;
    }

    const verify = async (token, code, authType) => {
        let codeString = `${code}`
        const data = {token, code:codeString, auth_type: authType};

        return await verifyAudkenni(data);
    }

    const handleSubmit = (values, form) => {
        form.setSubmitting(true);

        login(values)
            .then(resp => {
                setShowCounter(prevState => !prevState);

                const {token, code} = resp;
                const authType = isSSN ? TYPE_APP : TYPE_SIM;

                verify(token, code, authType)
                    .then(() => {
                        window.location.href = '/';
                    })
                    .catch((err) => notification.error('Something wrong'));
            })
            .catch(error => {
                form.setSubmitting(false);
            })
    }


    return (
        <PublicLayout
            page={'login'}
            HeaderProps={{
                hasOffset: false,
            }}
            showFooter={true}
        >
            <Container maxWidth="sm">
                <Box mt={4} textAlign="center">
                    <Typography variant="h5">Switch between SSN and Phone Input</Typography>
                    <FormControlLabel
                        control={<Switch checked={isSSN} onChange={handleSwitchChange}/>}
                        label={isSSN ? 'SSN' : 'Phone Number'}
                    />

                    <ActiveForm
                        initialValues={{
                            ssn: '',
                            phone: ''
                        }}
                        onSubmit={(values, form) => handleSubmit(values, form)}
                        render={(form) => {
                            const {values, errors, touched, isSubmitting, handleSubmit} = form;

                            return (
                                <form onSubmit={handleSubmit}>
                                    <Box sx={styles.formBox}>
                                        <Box sx={styles.inputBox}>
                                            <Box p={3}>
                                                {
                                                    isSSN && <ActiveInput
                                                        type="text"
                                                        name="ssn"
                                                        placeholder={t('ssn')}
                                                        label={t('ssn')}
                                                        error={!!(touched.ssn && errors.ssn)}
                                                        value={values.ssn}
                                                        margin='normal'
                                                        onError={error => t(`error_ssn_${error}`)}
                                                        fullWidth
                                                    />
                                                }
                                                {
                                                    !isSSN && <ActiveInput
                                                        type="phone"
                                                        name="phone"
                                                        placeholder={t('placeholder_phone')}
                                                        label={t('placeholder_phone')}
                                                        error={!!(touched.phone && errors.phone)}
                                                        value={values.phone}
                                                        margin='normal'
                                                        onError={error => t(`error_phone_${error}`)}
                                                        fullWidth
                                                    />
                                                }
                                            </Box>
                                        </Box>
                                        <Divider/>
                                        {
                                            showCounter &&
                                            <Typography variant='p2Bold' component={'p'} mb={3}>
                                                {'Login session will expire in 30s'}
                                            </Typography>
                                        }
                                        {
                                            !showCounter &&
                                            <Box sx={styles.buttonBox}>
                                                <Button fullWidth={true} disabled={isSubmitting} variant="contained"
                                                        color="primary"
                                                        type="submit">
                                                    {t('login')}
                                                </Button>
                                            </Box>
                                        }
                                    </Box>
                                </form>
                            )
                        }}
                    />
                </Box>
            </Container>
        </PublicLayout>
    );
};


export default withRouter(AudkenniLogin);