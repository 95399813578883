import React from 'react';
import PropTypes from 'prop-types';
import GeneralInfo from '../GeneralInfo/GeneralInfo';
import Offers from '../Offers/Offers';
import {groupOffersPricesWithPeriods, getGeneralInfoData} from '../../services/services';
import {Box} from "@mui/material";
import styles from './styles/styles';

const GeneralDetails = props => {
    const {item, periods} = props;

    return (
        <Box width={'100%'}>
            {item.branches.map((branch, index) => {
                const periodWithOffers = periods.filter(period => {
                    return branch.offers.some(offer => offer.status && offer.price && offer.period_id === period.id);
                });

                const offers = groupOffersPricesWithPeriods(branch.offers, periodWithOffers);

                const generalInfoData = getGeneralInfoData(branch, item.account);

                return (
                    <Box key={index} sx={styles.root}>
                        <GeneralInfo data={generalInfoData}/>
                        {!_.isEmpty(offers) && <Offers periods={periodWithOffers} offers={offers}/>}
                    </Box>
                );
            })}
        </Box>
    )
};

GeneralDetails.defaultProps = {
    periods: [],
};

GeneralDetails.propTypes = {
    periods: PropTypes.array,
};

export default GeneralDetails;