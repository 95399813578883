const styles = {
    menuButton:(theme) =>({
        height: 36,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        color: theme.palette.grey[50],
        backgroundColor: theme.palette.grey[800],

        '&:hover': {
            backgroundColor: theme.palette.grey[800],
        },

        '& .MuiButton-startIcon': {
            marginRight: '10px !important',
            marginLeft: `${theme.spacing(0)} !important`,
        }
    }),
}

export default styles;