import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiSwitch from '@material-ui/core/Switch';

const Switch = props => {
    const {value, label, color, checked, onChange} = props;

    const handleChange = (e, checked) => {
        onChange && onChange(checked, e.target.value,);
    };

    return (
        <FormControlLabel
            control={
                <MuiSwitch
                    checked={checked}
                    onChange={handleChange}
                    value={value}
                    color={color}
                />
            }
            label={label}
        />
    )
};

Switch.defaultProps = {
    color: 'primary',
    checked: false,
};

Switch.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    color: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

export {Switch};