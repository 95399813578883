import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Alert} from "@mui/material";
import styles from './styles/styles'
import Typography from "@mui/material/Typography";
import notificationTypes from "@/components/Notifications/staticData/StaticData";
import theme from "@/themes/main/main";

const NotificationAlert = props => {
    const {data, hideTimeout, onClose} = props;

    const Icon = notificationTypes[data.type].icon;
    const color = notificationTypes[data.type].color;

    useEffect(() => {
        setTimeout(() => {
            onClose(data);
        }, hideTimeout);
    }, []);

    return (
        <Alert sx={styles.alert(color,theme)} icon={<Icon/>} severity={data.type} variant="outlined"
               onClose={() => onClose(data)}>
            <Typography sx={styles.data}>{data.message}</Typography>
        </Alert>
    )
};

NotificationAlert.defaultProps = {
    hideTimeout: 9000,
};

NotificationAlert.propTypes = {
    hideTimeout: PropTypes.number
};

export default NotificationAlert;