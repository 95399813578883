const styles = {
    header: (theme, {hasShadow, opacity}) => ({
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.hexToRGBA(theme.palette.grey[50], 0.24)}`,

        '&.MuiPaper-root': {
            backgroundColor: opacity ? theme.palette.grey[800] : 'transparent',
            transition: 'background-color 300ms ease-out',
        }
    }),
    toolbar: () => ({
        height: 'auto',

        '&.MuiToolbar-root': {
            minHeight: 80,
        }
    }),
    offset: () => ({
        height: 80,
    }),
}

export default styles;