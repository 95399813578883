export const styles = {
    status: (theme, active) => ({
        width: 'fit-content',
        padding: '6px 12px',
        borderRadius: '5px',
        backgroundColor: active ? theme.palette.success.main : theme.palette.error.main,
    }),
    statusText: (theme) => ({
        color: theme.palette.grey[50],
    }),
};