import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';


import styles from './styles/styles';
import {Box, ClickAwayListener, MenuItem, Paper, Popper, TextField} from "@mui/material";
import ArrowDropDown from "@/icons/ArrowDropDown";
import ArrowDropUp from "@/icons/ArrowDropUp";

const SelectField = props => {
    const {name, value, label, options, onBlur, fullWidth, onChange, disabled, TextFieldProps} = props;
    const [open, setOpen] = useState(false);
    const inputRef = useRef();

    const onOptionClick = option => {
        onChange(name, option);
        setOpen(false);
    };

    const valueText = _.get(value, 'label');
    const IconComponent = open ? ArrowDropUp : ArrowDropDown;

    const onClickAway = () => {
        open && onBlur(name, value);
        setOpen(false);
    };

    const onClick = () => {
        setOpen(!open);
    };

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <Box sx={styles.selectFieldWrapper} flexGrow={+fullWidth}>
                <TextField
                    {...TextFieldProps}
                    fullWidth={fullWidth}
                    readOnly
                    id={name}
                    name={name}
                    label={label}
                    value={valueText || ''}
                    onClick={onClick}
                    InputProps={{
                        ref: inputRef,
                        endAdornment: <IconComponent sx={(theme) => styles.arrowIcon(theme, disabled)}/>,
                    }}
                />
                {!!options.length && <Popper
                    sx={styles.selectPopper}
                    open={open}
                    anchorEl={inputRef.current}
                    placement='bottom-start'
                    disablePortal={true}
                >
                    <Paper>
                        {options.map((option, i) => {
                            return (
                                <MenuItem key={i} onClick={() => onOptionClick(option)}>{option.label}</MenuItem>
                            );
                        })}
                    </Paper>
                </Popper>}
            </Box>
        </ClickAwayListener>
    )
};

SelectField.defaultProps = {
    options: [],
    fullWidth: true,
    onChange: () => undefined,
    onBlur: () => undefined,
    TextFieldProps: {},
};

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.object,
    options: PropTypes.array,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    TextFieldProps: PropTypes.object,
};

export {SelectField};