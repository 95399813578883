import React from "react";
import Box from "@mui/material/Box";
import {PAYMENT_STATUSES} from 'modules/Accommodation/staticData/staticData';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import Typography from "@mui/material/Typography";
import styles from './styles/styles'
import useTranslate from "@/components/UseTranslate/useTranslate";

const PaymentResult = (props) => {

    const {t} = useTranslate();

    const query = new URLSearchParams(props.location.search);
    const paymentStatus = query.get('paymentStatus');

    return (
        <Box sx={styles.paymentResultBox}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={3}>
                {
                    +paymentStatus === PAYMENT_STATUSES.COMPLETED &&
                    <React.Fragment>
                        <CheckCircleIcon
                            color={'success'}/>
                        <Box component={'span'} ml={'12px'}>{t('success_title')}</Box>
                    </React.Fragment>
                }

                {
                    +paymentStatus === PAYMENT_STATUSES.CANCELLED &&
                    <React.Fragment>
                        <WarningIcon color={'warning'}/>
                        <Box component={'span'} ml={'12px'}> {t('cancelled_title')}</Box>
                    </React.Fragment>
                }

                {
                    +paymentStatus === PAYMENT_STATUSES.FAILED &&
                    <React.Fragment>
                        <WarningIcon color={'warning'}/>
                        <Box component={'span'} ml={'12px'}>{t('failure')}</Box>
                    </React.Fragment>
                }
            </Box>

            <Box display={'flex'} justifyContent={'center'}>
                <Box>
                    {+paymentStatus === PAYMENT_STATUSES.COMPLETED &&
                        <Typography>{t('success_contract_sent')}</Typography>}
                    {+paymentStatus === PAYMENT_STATUSES.CANCELLED && t('cancelled_payment')}
                    {+paymentStatus === PAYMENT_STATUSES.FAILED && t('error_payment')}
                </Box>
            </Box>
        </Box>
    )
}

export default PaymentResult;