import React, {useEffect, useState} from 'react';
import ActiveForm, {ActiveInput, ActiveSelect} from "@/components/ActiveForm/ActiveForm";
import {Form} from "formik";
import notification from "@/services/notification/notification";
import useTranslate from "@/components/UseTranslate/useTranslate";
import * as Yup from "yup";
import {Dialog} from "@mui/material";
import styles from '../../../styles/styles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
    createMemberPhoneNumber, getMemberPhoneNumber,
    updateMemberPhoneNumber
} from "@/modules/UnionMember/services/api/phoneNumbers/phoneNumbers";
import ActiveAutoComplete from "@/components/ActiveForm/components/ActiveAutoComplete/ActiveAutoComplete";
import {getCountries} from "@/services/api/country/country";
import {
    ICELAND, phoneNumberTypeOptions
} from "@/layouts/components/Header/components/ProfileInformation/staticData/staticData";
import {API_STATUSES} from "@/services/api/statuses/statuses";
import DeletePhoneNumberModal
    from "@/layouts/components/Header/components/ProfileInformation/components/PhoneNumbers/components/DeletePhoneNumberModal";
import {Resource} from "@/services/transform/resource/resource";
import MemberPhoneNumberTransformer
    from "@/modules/UnionMember/services/transform/resource/transformers/PhoneNumber/MemberPhoneNumberTransformer";

const OTHER = 3;

const CreateOrUpdatePhoneNumberModal = (props) => {
    const {id, usedTypes, open} = props;
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const {t} = useTranslate();

    useEffect(() => {
        if (id) {
            fetchData()
                .then(resp => setPhoneNumber(resp))
                .catch(() => notification.warning(t('something_wrong')));
        }
    }, [id]);

    const modalName = id ? 'update' : 'create';

    const fetchData = async () => {
        const response = await getMemberPhoneNumber(id);
        return Resource.transform(MemberPhoneNumberTransformer, response.data, response.meta).data;
    }

    const fetchCodeData = () => getCountries({per_page: 300}).then(resp => {
        return resp?.data.map((item) => {
            return {value: item.phone_code, label: item.phone_code}
        })
    });

    const onCreate = async (data) => {
        return await createMemberPhoneNumber({...data, code: data.code.value.slice(1), type: data.type.value});
    }

    const onUpdate = async (data) => {
        return await updateMemberPhoneNumber(data.id, {...data, code: data.code.value.slice(1), type: data.type.value});
    }

    const onSuccess = (createMode, form, values, resp) => {
        notification.success(t(`alert_phone_${modalName}_success`));
        props.onSuccess(resp);
        onClose();
    }

    const onClose = () => {
        props.onClose();
        setPhoneNumber(null);
    }

    const openDeleteModal = () => {
        if (!phoneNumber.is_default) {
            setIsOpenDeleteModal(true)
        }
    }

    const onSuccessDelete = () => {
        setIsOpenDeleteModal(false)
        props.onSuccess()
        onClose()
    }

    const phoneNumberTypeTranslations = {
        mobile: t('mobile'),
        home: t('home'),
        work: t('work'),
        other: t('other'),
    };

    if (!phoneNumber && id) {
        return null;
    }

    const translatedPhoneNumberOptions = phoneNumberTypeOptions.map(option => {
        const isTypeUsed = usedTypes.includes(option.value);
        const shouldInclude = option.value === OTHER || option.value === phoneNumber?.type;

        if (!shouldInclude && isTypeUsed) {
            return;
        }

        return {...option, label: phoneNumberTypeTranslations[option.label]};
    }).filter(Boolean);

    return (
        <>
            <Dialog
                open={open}
                sx={styles.emailModal}
                className={'modal'}
                onClose={onClose}
            >
                <Box className={'modalHeader'}>
                    <Box className={'modalHeaderInner'}>
                        <Typography variant='h4'>
                            {t(`title_${modalName}_phone_number`)}
                        </Typography>
                    </Box>
                </Box>

                <ActiveForm
                    initialValues={phoneNumber ? {
                            ...phoneNumber,
                            code: {value: `+${phoneNumber.code}`, label: `+${phoneNumber.code}`},
                            type: _.find(translatedPhoneNumberOptions, item => item.value === phoneNumber.type)
                        } :
                        {
                            code: {label: ICELAND.code, value: ICELAND.code},
                            number: '',
                            type: '',
                            is_default: false
                        }}
                    onSubmit={id ? onUpdate : onCreate}
                    onSuccess={onSuccess}
                    validationSchema={Yup.object().shape({
                        type: Yup.object().required('required').typeError("required"),
                        code: Yup.object().typeError('required').required('required'),
                        number: Yup.number().min(1, 'min').required('required').typeError('type'),
                        is_default: Yup.boolean()
                    })}
                    onError={(createMode, form, values, errors, resp) => {
                        if (resp.status === API_STATUSES.CONFLICT) {
                            notification.error(t(`error_code_${resp.error_code}`))
                        } else {
                            notification.error(t('something_wrong'));
                        }
                    }}
                    render={(formProps) => {
                        const {handleSubmit, isValid, isSubmitting, values} = formProps;

                        return (
                            <Form onSubmit={handleSubmit} className='p-0'>
                                <Box className={'modalBody'}>
                                    <Box mb={3}>
                                        <ActiveSelect
                                            name="type"
                                            TextFieldProps={{
                                                placeholder: t('type')
                                            }}
                                            label={t('type')}
                                            title={t('type')}
                                            options={translatedPhoneNumberOptions}
                                            onError={key => t(`error_type_${key}` || '')}
                                            required
                                        />
                                        <Typography
                                            variant={'p5'}>{t('text_phone_number')}</Typography>
                                    </Box>
                                    <Box sx={styles.codeNumberRootBlock}>
                                        <Box className={'code'} width={'100%'} sx={styles.selectOptionBox}>
                                            <Typography variant={'p4'} sx={styles.countryLabel}>
                                                {t('placeholder_code')}
                                                <Box sx={styles.countryLabelSign}>*</Box>
                                            </Typography>
                                            <ActiveAutoComplete
                                                id="asynchronous-demo"
                                                name="code"
                                                defaultValue={_.isEmpty(phoneNumber) ? {
                                                    label: ICELAND.code,
                                                    value: ICELAND.code
                                                } : values.code}
                                                loadOptions={() => fetchCodeData()}
                                                placeholder={t('placeholder_code')}
                                                onError={errKey => t(`error_code_${errKey}`)}
                                            />
                                        </Box>
                                        <Box className={'number'}>
                                            <ActiveInput
                                                name="number"
                                                label={`${t('placeholder_number')}`}
                                                placeholder={t('placeholder_number')}
                                                title={t('placeholder_number')}
                                                onError={errKey => t(`error_code_${errKey}`, {
                                                    min: 1,
                                                    max: 15
                                                })}
                                                required
                                            />
                                        </Box>
                                    </Box>

                                    {
                                        !_.isEmpty(phoneNumber) &&
                                        <Box mt={3}>
                                            <Typography
                                                variant={'p4'}
                                                className={values?.is_default ? 'delete-email-btn-disabled' : 'delete-email-btn'}
                                                onClick={openDeleteModal}
                                            >
                                                {t('remove_phone_number')}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                                <Box className={'modalFooter'}>
                                    <Box className={'modalFooterInner'}>
                                        <Button variant='contained' color="secondary" onClick={onClose}>
                                            {t('close')}
                                        </Button>
                                        <Button type={'submit'} variant='contained'
                                                disabled={!isValid || isSubmitting}>
                                            {t(`buttons_${modalName}`)}
                                        </Button>
                                    </Box>
                                </Box>
                            </Form>
                        )
                    }}
                />
            </Dialog>
            <DeletePhoneNumberModal
                open={isOpenDeleteModal}
                onClose={() => setIsOpenDeleteModal(false)}
                onSuccess={onSuccessDelete}
                data={phoneNumber}
            />
        </>
    )
}

export default CreateOrUpdatePhoneNumberModal;