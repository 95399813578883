import * as api from 'services/api/api';

export const getSeasons = (data) => {
    return api.getRequest(`/acm/seasons`, data)
};

export const getSeason = (id, data) => {
    return api.getRequest(`/acm/seasons/${id}`, data)
};

export const getAvailablePeriodsOfApplicationPeriod = (id) => {
    return api.getRequest(`/acm/application_periods/${id}/periods_availability`);
};

export const getApplications = (data) => {
    return api.getRequest(`/acm/applications`, data)
}

export const getApplication = (id, data) => {
    return api.getRequest(`/acm/applications/${id}`, data)
}

export const createApplication = (data) => {
    return api.postRequest(`/acm/applications`, data)
}

export const updateApplication = (id, data) => {
    return api.putRequest(`/acm/applications/${id}`, data)
}