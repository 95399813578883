const styles = {
    footer: (theme, footerHeight) => ({
        backgroundColor: theme.palette.grey[100],
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        paddingTop: '0 !important',
        height: footerHeight?.footerLGHeight,

        [theme.breakpoints.down('md')]: {
            height: footerHeight?.footerMDHeight,
        },
        [theme.breakpoints.down('sm')]: {
            height: footerHeight?.footerSMHeight,
        },
    }),
    linkFooterItem: (theme) => ({
        padding: '0 !important',
        width: 'auto !important',
        color: theme.palette.grey[700],
        '&:not(:last-child)': {
            marginRight: theme.spacing(3),
        },
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },

        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(0.5)
        },

        '& .account-url': {
            '& a': {
                fontSize: 14
            }
        }
    }),
    link: (theme) => ({
        '& a': {
            color: `${theme.palette.primary.main} !important`,
        },
    }),
    footerTopList: (theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '0 !important',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },

        '& .email': {
            cursor: 'pointer'
        }
    }),

    footerRightBlock: (theme) => ({
        display: 'flex',
        alignItems: 'flex-end',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    }),
    footerBottomList: (theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 !important',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    }),
    footerList: (theme) => ({
        padding: '0 !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        },
    }),
    footerTop: (theme) => ({
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    }),
    boldTextItem: (theme, isSpecificUnion) => ({
        marginBottom: isSpecificUnion ? theme.spacing(2) : 0,
        '& p': {
            fontFamily: 'OpenSansBold',
        },
    }),
    logoFooterItem: (theme) => ({
        marginRight: theme.spacing(2),
        padding: '0 !important',
        width: 'auto !important',
    }),
    footerBottom: (theme) => ({
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }),
    logo: (theme) => ({
        maxWidth: 200,
        maxHeight: 43,

        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(2)
        },
    }),
    smallText: (theme) => ({
        color: theme.palette.grey[500],
        '& p': {
            fontSize: 12,
        }
    }),
}

export default styles