import {Resource} from "@/services/transform/resource/resource";
import Transformer from 'services/transform/resource/transformers/Transformer';
import {PropertyTransformer} from "modules/Accommodation/services/transform/transform";

class RentingPropertiesAvailablePeriodTransformer extends Transformer {
    static transform(rentingPropertiesAvailablePeriod, meta) {
        const {property, ...result} = rentingPropertiesAvailablePeriod;

        if (property) {
            result.property = Resource.transform(PropertyTransformer, property.data).data;
        }

        return result;
    }
}

export default RentingPropertiesAvailablePeriodTransformer;