import Transformer from 'services/transform/resource/transformers/Transformer';

class BookingDetailTransformer extends Transformer {
    static transform(bookingDetail, meta) {
        const {...result} = bookingDetail;

        return result;
    }
}

export default BookingDetailTransformer;