const styles = {
    wrapper: (theme, footerHeight) => ({
        minHeight: `calc(100vh - ${footerHeight?.footerLGHeight}px)`,

        [theme.breakpoints.down('md')]: {
            minHeight: `calc(100vh - ${footerHeight?.footerMDHeight}px)`,

            '&.page-property-view': {
                '& + .footer': {
                    marginBottom: theme.spacing(20.5)
                }
            }
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: `calc(100vh - ${footerHeight?.footerSMHeight}px)`,
        },
    }),
    root: (theme) => ({
        '& .layout-main, .layout-landing': {
            position: 'relative',
            minHeight: '100vh',
            height: '100%',

            '&:not(.withoutFooter)': {
                '& .page': {
                    paddingBottom: '80px !important',

                    '&.page-property-view': {
                        paddingBottom: '0 !important',
                    },

                    '&.page-not-found': {
                        padding: '0 !important',
                    }
                }
            },


            '&.withoutFooter': {
                paddingBottom: 0,
            },

            '& footer': {
                '& .nav': {
                    '& .nav-title': {
                        width: '100%',
                        fontSize: '16px',
                    }
                }
            }
        }
    }),
}

export default styles