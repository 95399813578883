import Transformer from "../Transformer";

class AccountTransformer extends Transformer {
    static transform(account, meta) {
        const {...result} = account;

        return result;
    }
}

export default AccountTransformer;