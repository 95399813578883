import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const ActiveCheckbox = (props) => {
    const {checked, onChange, name, color, label, disabled} = props;

    return (
        <FormControlLabel
            disabled={disabled}
            control={
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                    name={name}
                    color={color}
                />
            }
            label={label}
        />
    )
}

export default ActiveCheckbox;