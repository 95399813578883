import React, {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from "@mui/material/InputLabel";
import FormGroup from '@mui/material/FormGroup';
import Dialog from "@mui/material/Dialog";

import styles from "./styles/styles";
import {createRefund, getRefundForecast} from "@/modules/PVC/services/api/refund/refund";
import useTranslate from "@/components/UseTranslate/useTranslate";
import notification from "@/services/notification/notification";
import {kronaFormatter} from "@/services/formatters/kronaFromatter/kronaFormatter";
import {useAuth} from "@/providers/Auth/AuthProvider";
import BankAccountForm from "modules/UnionMember/components/BankAccountForm/BankAccountForm";
import {getAuthData} from "services/api/auth/auth";

const RefundModal = props => {
    const {open, onClose} = props;

    const [forecastData, setForecastData] = useState(null);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const {t} = useTranslate();

    const {user, updateUser} = useAuth();
    const bankAccountFormRef = useRef();

    useEffect(() => {
        if (open) {
            getRefundForecast()
                .then(response => {
                    setForecastData(response.data);
                })
                .catch(() => notification.error(t('something_wrong')));
        }
    }, [open])

    const onClick = () => {
        bankAccountFormRef.current.handleSubmit();
    };

    const handleRefund = () => {
        setIsFormSubmitted(!isFormSubmitted);

        createRefund()
            .then(() => {
                notification.success(t('msg_request_submitted'));
                onClose();
                getAuthData().then(data => updateUser(data.user.data));
            })
            .catch(() => {
                notification.error(t('something_wrong'));
            });
    }

    if (forecastData === null) {
        return null;
    }

    let pvc = kronaFormatter(user.pvc, false, 0);
    let amount = kronaFormatter(forecastData?.amount, false, 0);
    let points = kronaFormatter(forecastData?.points, false, 0);

    let hasPVC = +pvc > 0;

    return (
        <Dialog
            open={open}
            maxWidth={false}
            sx={styles.refundModal}
            className={'modal'}
            onClose={onClose}
        >
            <Box className={'modalHeader'}>
                <Box className={'modalHeaderInner'}>
                    <Typography variant={'h4'}>{t('title_request_refund')}</Typography>
                </Box>
            </Box>
            <Box className={'modalBody'}>
                <Box mb={3}>
                    <Typography variant={'p3'}>
                        {hasPVC ? t('details', {pvc, amount, points}) : t('no_pvc_active')}
                    </Typography>
                </Box>
                <Box>
                    <FormGroup>
                        <Grid container spacing={3} mb={3}>
                            <Grid item xs={12}>
                                <InputLabel disabled={true}>
                                    {t('ssn')}
                                </InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    name='ssn'
                                    placeholder={t('ssn')}
                                    label={`${t('ssn')}`}
                                    disabled={true}
                                    value={user.ssn}
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>

                    <BankAccountForm
                        ref={bankAccountFormRef}
                        onUpdate={handleRefund}
                    />
                </Box>
            </Box>
            <Box className={'modalFooter'}>
                <Box className={'modalFooterInner'}>
                    <Button
                        type={'button'}
                        variant={'contained'}
                        color={'secondary'}
                        onClick={onClose}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        type={'button'}
                        variant='contained'
                        disabled={isFormSubmitted || !hasPVC}
                        onClick={onClick}
                    >
                        {t('save')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
};

export default RefundModal;