import Transformer from 'services/transform/resource/transformers/Transformer';
import {Resource} from 'services/transform/transform';
import {PropertyTplTransformer} from 'modules/Accommodation/services/transform/transform';
import {ApplicationPeriodTransformer} from 'modules/Accommodation/services/transform/transform';

class PropertyTransformer extends Transformer {
    static transform(property) {
        const {mainImage, images, paramVals, filter, template, days, periods, activeFCFGPeriods, ...data} = property;

        const result = {};

        result.id = data.id;
        result.name = data.name || '';
        result.address = data.address || '';
        result.address2 = data.address2 || '';
        result.security_code = data.security_code || '';
        result.order = data.order || '';
        result.key_storage = data.key_storage || '';
        result.latitude = data.latitude || '';
        result.longitude = data.longitude || '';
        result.description = data.description || '';
        result.details = data.details || '';
        result.regions = data.regions && data.regions.data;
        result.short_description = data.short_description || ''

        if (images && images.data) {
            result.images = handleImages(images.data);
        }

        if (mainImage && mainImage.data) {
            result.main_image = handleImageSize(mainImage.data);
        }

        if (paramVals) {
            result.param_values = paramVals.data;
        }

        if (filter) {
            result.filter = filter.data;
        }

        if (template) {
            result.template = Resource.transform(PropertyTplTransformer, template.data, template.meta).data;
        }

        if (days) {
            result.days = days.data;
        }

        if (periods) {
            result.periods = periods.data;
        }

        if (activeFCFGPeriods) {
            result.activeFCFGPeriods = Resource.transform(ApplicationPeriodTransformer, activeFCFGPeriods.data, activeFCFGPeriods.meta).data
        }

        return result;
    }
}

const handleImages = images => {
    if (!images.length) {
        return [];
    }

    return images.map(handleImageSize);
};

const handleImageSize = image => {
    const {src, ...rest} = image;
    let {extra_small, small, middle, large, extra_large, original} = src;

    small = small || middle || extra_small;
    middle = middle || small;
    large = large || original;
    extra_large = extra_large || large;

    rest.src = {extra_small, small, middle, large, extra_large};

    return rest;
};

export default PropertyTransformer;