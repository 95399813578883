import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {styles} from './styles/styles';

const ProfileImage = React.forwardRef((props, ref) => {
    const {className, src, size, iconSize, user} = props;
    const userNameFirstLetter = user?.name.charAt(0).toUpperCase();

    return (
        <Box component={'span'}
             sx={(theme) => styles.root(theme, {size: size, iconSize: iconSize})}
             className={`${className}`}
             ref={ref}
        >
            {src ?
                <Avatar src={src} sx={styles.avatar}/> :

                <Avatar sx={[styles.avatar, styles.defaultAvatar]}>
                    <Typography variant={'p3Bold'}>{userNameFirstLetter}</Typography>
                </Avatar>
            }
        </Box>
    )
});

ProfileImage.defaultProps = {
    className: '',
    src: '',
    showStatus: true,
    size: 40,
    iconSize: 18,
};

ProfileImage.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    status: PropTypes.number,
    showStatus: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.number,
    iconSize: PropTypes.number,
};

export default ProfileImage;