import React, {useEffect, useState} from 'react';
import _ from "lodash";
import useKeyTranslationModal from "@/components/GlobalModals/hooks/useKeyTranslationModal";
import {useAppState} from "@/app/state/state";
import storage from "@/services/storage/storage";
import {defaultLang} from "@/config";

const useTranslate = () => {
    const [state, actions] = useAppState();
    const [language, setLanguage] = useState(storage.get('lang') || defaultLang);
    const editModeEnabled = state.translationEditMode;

    useEffect(() => {
        storage.watch('lang', (lang) => {
            setLanguage(lang);
        });
    }, []);

    const savedTranslations = actions.getSavedTranslations();

    const getTranslation = (key) => {
        let translated = null;

        if (key in savedTranslations) {
            translated = savedTranslations[key][language]; // value from translation service
        }

        if (translated === null || translated === undefined) {
            translated = '{' + key + '}';
        }

        return translated;
    };

    const createMarkup = text => ({__html: text});

    const handleContextMenu = (e, key) => {
        e.preventDefault();

        const openKeyTranslationModal = useKeyTranslationModal();
        let translationPageUrl = `${window.location.origin}/translations&key=${key}`;

        openKeyTranslationModal(key, translationPageUrl);
    };

    const t = (key, replace = {}, withHtml = false) => {
        let translated = getTranslation(key);

        const result = _.template(translated, {interpolate: /\{\{(.+?)\}\}/g})(replace);

        if (withHtml) {
            return <span dangerouslySetInnerHTML={createMarkup(result)}/>
        }

        if (editModeEnabled) {
            return (
                <span
                    onContextMenu={(event) => handleContextMenu(event, key)}
                    style={{outline: '1px solid'}}
                    title={'Use right click to fix translation'}
                >
                    {result}
                </span>
            )
        }

        return result;
    };

    return {t};
};

export default useTranslate;
