import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export const parseToHTML = (description) => {
    if (!description) {
        return '';
    }

    const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(description)));
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    return draftToHtml(rawContentState);
};