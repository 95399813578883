import React, {forwardRef} from "react";
import * as Yup from "yup";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";

import ActiveForm, {ActiveInput} from "components/ActiveForm/ActiveForm";
import useTranslate from "components/UseTranslate/useTranslate";
import {getAuthData, updateAuthUser} from "services/api/auth/auth";
import {useAuth} from "providers/Auth/AuthProvider";
import notification from "services/notification/notification";

const ERROR_BANK_ACCOUNT_NOT_FOUND = 7001;

const BankAccountForm = forwardRef((props, ref) => {
    const {onUpdate} = props;
    const {user, updateUser} = useAuth();

    const {t} = useTranslate();

    const onSubmit = async (values) => {
        const nameChanged = user.bank_name !== values.bank_name;
        const typeChanged = user.bank_account_type !== values.bank_account_type;
        const numberChanged = user.bank_account_number !== values.bank_account_number;

        const shouldUpdateMemberData = nameChanged || typeChanged || numberChanged;

        if (shouldUpdateMemberData) {
            const data = new FormData();

            data.append('bank_name', values.bank_name);
            data.append('bank_account_type', values.bank_account_type);
            data.append('bank_account_number', values.bank_account_number);

            await updateAuthUser(data)
                .then(() => {
                    onUpdate();
                    getAuthData().then(data => updateUser(data.user.data));
                })
                .catch((resp) => {
                    if (resp.error_code === ERROR_BANK_ACCOUNT_NOT_FOUND) {
                        notification.error(t('error_account_not_exist'));
                    } else {
                        notification.error(t('something_wrong'));
                    }
                });

            return;
        }

        onUpdate();
    }

    return (
        <ActiveForm
            ref={ref}
            onSubmit={async values => onSubmit(values)}
            enableReinitialize={true}
            initialValues={{
                bank_name: user.bank_name || '',
                bank_account_type: user.bank_account_type || '',
                bank_account_number: user.bank_account_number || '',
            }}

            validationSchema={Yup.object().shape({
                bank_name: Yup.string().required('required').length(4, 'length'),
                bank_account_type: Yup.string().required('required').length(2, 'length'),
                bank_account_number: Yup.string().required('required').length(6, 'length'),
            })}

            render={form => {
                return (
                    <form onSubmit={form.handleSubmit}>
                        <FormGroup>
                            <Grid container spacing={3} mb={3}>
                                <Grid item xs={6}>
                                    <ActiveInput
                                        name='bank_name'
                                        placeholder={t('bank')}
                                        label={`${t('bank')}`}
                                        required
                                        onError={error => t(`error_bank_name_${error}`, {
                                            length: 4
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ActiveInput
                                        fullWidth
                                        name='bank_account_type'
                                        placeholder={t('ledger')}
                                        label={`${t('ledger')}`}
                                        required
                                        onError={error => t(`error_account_type_${error}`, {
                                            length: 2
                                        })}
                                    />
                                </Grid>
                            </Grid>

                        </FormGroup>
                        <FormGroup>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <ActiveInput
                                        fullWidth
                                        name='bank_account_number'
                                        placeholder={t('account_number')}
                                        label={`${t('account_number')}`}
                                        required
                                        onError={error => t(`error_account_number_${error}`, {
                                            length: 6
                                        })}
                                    />
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </form>
                )
            }}
        />
    )
});

export default BankAccountForm;