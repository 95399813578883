import axios from "axios";

const baseURL = process.env.TRANSLATIONS_API_URL;
const projectId = process.env.PROJECT_ID;
const token = process.env.PROJECT_TOKEN;

export const getTranslations = () => {
    return axios.get(`${baseURL}/projects/${projectId}/translations`, {
        withCredentials: false,
        headers: {'Authorization-Token': token}
    });
};

export const getByKey = (key) => {
    return axios.get(`${baseURL}/project_keys/${projectId}/${key}`, {
        withCredentials: false,
        headers: {'Authorization-Token': token}
    });
}

export const updateKeyValues = (id, data) => {
    return axios.put(`${baseURL}/project_keys/${id}/update`, data, {
        withCredentials: false,
        headers: {'Authorization-Token': token}
    });
}