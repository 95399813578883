import React from 'react';
import storage from "@/services/storage/storage";
import styles from "@/layouts/components/Menu/styles/styles";
import {useAuth} from "@/providers/Auth/AuthProvider";

export default () => {
    const {checkAccess, settings} = useAuth();

    return [
        {
            nodeId: '4',
            label: 'educations',
            sx: styles.itemRoot,
            show: _.get(settings, 'disable_in_member_interface.value', null) && checkAccess('ed_member_privileges'),
            items: [
                {
                    nodeId: '41',
                    label: 'home',
                    path: `/${storage.get('lang')}/ed/educations/home`,
                    show: checkAccess('ed_member_privileges'),
                },
                {
                    nodeId: '42',
                    label: 'add_education',
                    path: `/${storage.get('lang')}/ed/educations/add_education`,
                    show: checkAccess('ed_member_privileges'),
                }, {
                    nodeId: '43',
                    label: 'request_estimation',
                    path: `/${storage.get('lang')}/ed/educations/add_ed_app`,
                    show: checkAccess('ed_member_privileges'),
                }]
        },
    ];
}