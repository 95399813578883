import React from "react";
import {Modals} from "@/components/GlobalModals/modalConfigs";
import {useModal} from "@/components/GlobalModals/useModal";

const useKeyTranslationModal = () => {
    const keyTranslationModal = useModal(Modals.KeyTranslationModal);

    return (translationKey, translationPageUrl) => {
        keyTranslationModal.open({
            open: true,
            onClose: () => {
                keyTranslationModal.close();
            },
            translationKey,
            translationPageUrl
        })
    }
}
export default useKeyTranslationModal;