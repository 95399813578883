const styles = {
    header: (theme, {hasShadow, opacity}) => ({
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        maxHeight:"80px",

        '&.MuiPaper-root': {
            boxShadow: hasShadow ? theme.shadows[4] : 'none',
            backgroundColor: opacity ? theme.palette.hexToRGBA(theme.palette.common.white, opacity) : 'transparent',
            transition: 'background-color 300ms ease-out',
        }
    }),
    offset: () => ({
        height: 80,
    }),
}

export default styles;