import React from 'react';
import useGlobalHook from 'components/UseGlobalHook/UseGlobalHook';
import storage from "services/storage/storage";

const initialState = {
    projectTranslations: {},
    translations: {},
    savedTranslations: [],
    translationEditMode: false,
};

export const actions = {
    setTranslations: (store, translations, callBack) => {
        store.setState({translations}, callBack)
    },
    setProjectTranslations: (store, projectTranslations, callBack) => {
        store.setState({projectTranslations}, callBack)
    },
    setSavedTranslations: (store, translations, callBack) => {
        store.setState({savedTranslations: translations}, callBack);
    },
    getSavedTranslations: (store) => {
        return store.state.savedTranslations;
    },
    setTranslationEditMode: (store, mode) => {
        store.setState({translationEditMode: mode})
    }
};

const useAppState = useGlobalHook(React, initialState, actions);

export {useAppState};