import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

const Link = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M14 3C14 2.44772 14.4477 2 15 2H21C21.5523 2 22 2.44772 22 3V3.0228V3.02413V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.41426L10.7071 14.7072C10.3166 15.0977 9.68342 15.0977 9.29289 14.7072C8.90237 14.3166 8.90237 13.6835 9.29289 13.2929L18.5858 4H15C14.4477 4 14 3.55228 14 3ZM4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H5C4.20435 5 3.44129 5.31607 2.87868 5.87868C2.31607 6.44129 2 7.20435 2 8V19C2 19.7957 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H16C16.7957 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7957 19 19V13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V8C4 7.73478 4.10536 7.48043 4.29289 7.29289Z" fill={props.color}/>
        </SvgIcon>
    );
};

export default Link;