import React from 'react';
import Box from '@mui/material/Box';
import RightSection from './components/RightSection/RightSection';
import Logo from "@/layouts/Public/components/Header/components/Heading/components/logo/logo";


const Heading = () => {

    return (
        <Box display={'flex'} flex={1} justifyContent={'space-between'} alignItems={'center'} height={'100%'} sx={theme => ({
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingBottom: "22px",
            paddingTop: "22px"
        })}>
            <Logo/>
            <RightSection/>
        </Box>
    )
};

export default Heading;