import {keyframes} from '@mui/system';

const size = 86.4;

export const styles = {
    root: (theme) => ({
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        width: '100%',
        height: '100%',
    }),
    loader: (theme) => ({
        width: size,
        height: size,
        display: 'grid',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        gridTemplateColumns: "repeat(3, 1fr)",
        gridTemplateRows: "repeat(3, 1fr)",

        '& div': {
            animation: `${flipping} 1.7999999999999998s calc(var(--delay) * 1.2s) infinite backwards`,
            backgroundColor: theme.palette.primary.main,

            '&:nth-of-type(1)': {
                '--delay': 0.12,
            },

            '&:nth-of-type(2)': {
                '--delay': 0.24,
            },

            '&:nth-of-type(3)': {
                '--delay': 0.36,
            },

            '&:nth-of-type(4)': {
                '--delay': 0.48,
            },

            '&:nth-of-type(5)': {
                '--delay': 0.6,
            },

            '&:nth-of-type(6)': {
                '--delay': 0.72,
            },

            '&:nth-of-type(7)': {
                '--delay': 0.84,
            },

            '&:nth-of-type(8)': {
                '--delay': 0.96,
            },

            '&:nth-of-type(9)': {
                '--delay': 1.08,
            },
        }
    }),
}

const flipping = keyframes`
  0% {
    transform: perspective(${size}px) rotateX(-90deg);
  }
  50%, 75% {
    transform: perspective(${size}px) rotateX(0);
  }
  100% {
    opacity: 0;
    transform: perspective(${size}px) rotateX(0);
  }
`;