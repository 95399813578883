import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Box, Typography} from "@mui/material";
import styles from './styles/styles';
import ArrowDropDown from "@/icons/ArrowDropDown";
import ArrowDropUp from "@/icons/ArrowDropUp";
import OfferByPeriod from "@/modules/PVC/pages/Offer/components/Details/components/Offers/components/OfferByPeriod";
import moment from "moment";

const Offers = props => {
    const {periods, offers} = props;
    const [open, setOpen] = useState(false);
    const [periodId, setPeriodId] = useState(null);

    useEffect(() => {
        if (periods.length === 1) {
            setOpen(true);
        }
    }, []);

    const toggleCollapse = (id) => {
        if (open && id) {
            return setPeriodId(id);
        }
        setPeriodId(id)
        setOpen(!open);
    };

    const multiplePeriods = (id) => {
        if (periods.length === 1) {
            return true;
        }

        return id === periodId;
    }

    return (
        <Box display={'flex'} flexDirection={'column'}>
            {periods.map((period, index) => {
                const format = 'DD.MM.YY';
                const startDate = moment(period.start_date).format(format);
                const endDate = moment(period.end_date).format(format);

                return (
                    <Box key={index} sx={styles.valueBox}>
                        <Box sx={styles.periodBox}>
                            <Typography variant={'p3Bold'} sx={styles.periodTitle}>
                                {period.title} {startDate} - {endDate}
                            </Typography>
                            {
                                periods.length > 1 &&
                                <Box sx={styles.viewOfferBtn}>
                                    {
                                        open && periodId === period.id
                                            ? <ArrowDropDown onClick={() => toggleCollapse(null)} color={'primary'}/>
                                            : <ArrowDropUp onClick={() => toggleCollapse(period.id)} color={'primary'}/>
                                    }
                                </Box>
                            }
                        </Box>
                        {
                            multiplePeriods(period.id) &&
                            <OfferByPeriod
                                offers={offers}
                                periods={periods}
                                periodId={periodId ? periodId : period.id}
                                open={periods.length > 1 ? open : true}
                            />
                        }
                    </Box>
                );
            })}
        </Box>
    )
};

Offers.defaultProps = {
    periods: [],
    offers: [],
};

Offers.propTypes = {
    periods: PropTypes.array,
    offers: PropTypes.array,
};

export default withRouter(Offers);