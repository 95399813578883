import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from "@mui/material/Dialog";
import useTranslate from "@/components/UseTranslate/useTranslate";
import Button from "@mui/material/Button";
import styles from "@/pages/Login/components/IslandisLoginPage/styles/styles";


const NotFoundMemberModal = props => {
    const {open, onClose, isSpecificUnion} = props;
    const {t} = useTranslate();


    return (
        <Dialog
            open={open}
            maxWidth={false}
            className={'modal'}
            onClose={onClose}
            sx={styles.not_found_member_modal}
        >

            <Box className={'modalHeader'}>
                <Box className={'modalHeaderInner'}>
                    <Typography variant={'h4'}>{t('title_user_not_registered')}</Typography>
                </Box>
            </Box>
            <Box className={'modalBody'}>
                <Typography variant={'p3'}>
                    {t(`${isSpecificUnion ? 'body_grendavik' : 'body_not_registered'}`)}
                </Typography>
            </Box>
            <Box className={'modalFooter'}>
                <Box className={'modalFooterInner'}>
                    <Button
                        type={'button'}
                        variant={'contained'}
                        color={'secondary'}
                        onClick={onClose}
                        fullWidth
                    >
                        {t('close')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
};

export default NotFoundMemberModal;