export const styles = {
    root: (theme, {size}) => ({
        cursor: 'pointer',

        '& .MuiAvatar-root': {
            width: size,
            height: size,
        }
    }),
    avatar: (theme) => ({
        border: `2px solid ${theme.palette.hexToRGBA(theme.palette.common.white, 0.72)}`,
    }),
    defaultAvatar: (theme) => ({
        backgroundColor: theme.palette.hexToRGBA(theme.palette.grey[600]),
        border: 'none !important',

        '& span': {
            color: theme.palette.common.white
        }
    }),
}
