import Transformer from 'services/transform/resource/transformers/Transformer';

class PropertyAvailabilityTransformer extends Transformer {
    static transform(day, meta) {
        const {...result} = day;

        return result;
    }
}

export default PropertyAvailabilityTransformer;