import React, {useEffect, useState} from 'react';
import ActiveForm, {ActiveInput, ActiveSelect} from "@/components/ActiveForm/ActiveForm";
import {Form} from "formik";
import notification from "@/services/notification/notification";
import useTranslate from "@/components/UseTranslate/useTranslate";
import * as Yup from "yup";
import {Dialog} from "@mui/material";
import styles from '../../../styles/styles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {createMemberEmail, getMemberEmail, updateMemberEmail} from "@/modules/UnionMember/services/api/emails/emails";
import {API_STATUSES} from "@/services/api/statuses/statuses";
import DeleteEmailModal
    from "@/layouts/components/Header/components/ProfileInformation/components/Emails/components/DeleteEmailModal";
import {Resource} from "@/services/transform/resource/resource";
import MemberEmailTransformer
    from "@/modules/UnionMember/services/transform/resource/transformers/Email/MemberEmailTransformer";
import {emailTypeOptions} from "@/layouts/components/Header/components/ProfileInformation/staticData/staticData";

const OTHER = 2;

const CreateOrUpdateEmailModal = (props) => {
    const {id, usedTypes, open} = props;
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [email, setEmail] = useState(null);
    const {t} = useTranslate();

    useEffect(() => {
        if (id) {
            fetchData()
                .then(resp => setEmail(resp))
                .catch(() => notification.warning(t('something_wrong')));
        }
    }, [id]);

    const modalName = id ? 'update' : 'create';

    const fetchData = async () => {
        const response = await getMemberEmail(id);
        return Resource.transform(MemberEmailTransformer, response.data, response.meta).data;
    }

    const onCreate = async (data) => {
        return await createMemberEmail({...data, type: data.type.value});
    }

    const onUpdate = async (data) => {
        return await updateMemberEmail(data.id, {...data, type: data.type.value});
    }

    const onClose = () => {
        props.onClose();
        setEmail(null);
    }

    const openDeleteModal = () => {
        if (!email.is_default) {
            setIsOpenDeleteModal(true)
        }
    }

    const onSuccessDelete = () => {
        setIsOpenDeleteModal(false)
        props.onSuccess()
        onClose()
    }

    const onSuccess = (createMode, form, values, resp) => {
        notification.success(t(`alert_email_${modalName}_success`));
        props.onSuccess(resp);
        onClose();
    }

    const emailTypeTranslations = {
        personal: t('personal'),
        work: t('work'),
        other: t('other'),
    };

    if (!email && id) {
        return null;
    }

    const translatedEmailOptions = emailTypeOptions.map(option => {
        const isTypeUsed = usedTypes.includes(option.value);
        const shouldInclude = option.value === OTHER || option.value === email?.type;

        if (!shouldInclude && isTypeUsed) {
            return;
        }

        return {...option, label: emailTypeTranslations[option.label]};
    }).filter(Boolean);

    return (
        <>
            <Dialog
                open={open}
                sx={styles.emailModal}
                className={'modal'}
                onClose={onClose}
            >
                <Box className={'modalHeader'}>
                    <Box className={'modalHeaderInner'}>
                        <Typography variant='h4'>
                            {t(`title_${modalName}_email`)}
                        </Typography>
                    </Box>
                </Box>

                <ActiveForm
                    initialValues={email ?
                        {...email, type: _.find(translatedEmailOptions, item => item.value === email.type)} :
                        {email: '', type: '', is_default: false}}
                    onSubmit={id ? onUpdate : onCreate}
                    onSuccess={onSuccess}
                    validationSchema={Yup.object().shape({
                        type: Yup.object().required('required').typeError("required"),
                        email: Yup.string().email('invalid').required('required').typeError("required"),
                        is_default: Yup.boolean()
                    })}
                    onError={(createMode, form, values, errors, resp) => {
                        if (resp.status === API_STATUSES.CONFLICT) {
                            notification.error(t(`error_code_${resp.error_code}`))
                        } else {
                            notification.error(t('something_wrong'));
                        }
                    }}
                    render={(formProps) => {
                        const {handleSubmit, isValid, isSubmitting, values} = formProps;

                        return (
                            <Form onSubmit={handleSubmit} className='p-0'>
                                <Box className={'modalBody'}>
                                    <Box mb={3}>
                                        <ActiveSelect
                                            name="type"
                                            TextFieldProps={{
                                                placeholder: t('type')
                                            }}
                                            label={t('type')}
                                            title={t('type')}
                                            options={translatedEmailOptions}
                                            onError={key => t(`error_type_${key}` || '')}
                                            required
                                        />
                                        <Typography variant={'p5'}>{t('text_email')}</Typography>
                                    </Box>
                                    <Box>
                                        <ActiveInput
                                            type="email"
                                            name="email"
                                            placeholder={t('email')}
                                            label={`${t('email')}`}
                                            onError={errKey => t(`error_type_${errKey}`)}
                                            margin='normal'
                                            fullWidth
                                            required
                                        />
                                    </Box>

                                    {
                                        !_.isEmpty(email) &&
                                        <Box mt={3}>
                                            <Typography
                                                variant={'p4'}
                                                className={values?.is_default ? 'delete-email-btn-disabled' : 'delete-email-btn'}
                                                onClick={openDeleteModal}
                                            >
                                                {t('remove_email')}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                                <Box className={'modalFooter'}>
                                    <Box className={'modalFooterInner'}>
                                        <Button variant='contained' color="secondary" onClick={onClose}>
                                            {t('close')}
                                        </Button>
                                        <Button type={'button'} onClick={handleSubmit} variant='contained'
                                                disabled={!isValid || isSubmitting}>
                                            {t(`buttons_${modalName}`)}
                                        </Button>
                                    </Box>
                                </Box>
                            </Form>
                        )
                    }}
                />
            </Dialog>

            <DeleteEmailModal
                open={isOpenDeleteModal}
                onClose={() => setIsOpenDeleteModal(false)}
                onSuccess={onSuccessDelete}
                data={email}
            />
        </>
    )
}

export default CreateOrUpdateEmailModal;