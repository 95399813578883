import Transformer from 'services/transform/resource/transformers/Transformer';

class FundTransformer extends Transformer {
    static transform(fund, meta) {
        const {...result} = fund;

        return result;
    }
}

export default FundTransformer;