import React from 'react';
import {Card, Skeleton, CardContent, Box, CardHeader} from '@mui/material';
import styles from "./styles/styles"

const OfferSkeleton = () => {

    return (
        <Card sx={styles.card}>
            <CardHeader
                sx={styles.cardHeader}
                title={
                    <Box sx={styles.cardHeaderBox}>
                        <Box sx={styles.cardHeaderLocation}>
                            <Skeleton sx={styles.smallIcon} variant={'rectangular'} animation={'wave'}/>
                            <Skeleton sx={styles.region} variant={'rectangular'} animation={'wave'}/>
                        </Box>
                        <Box sx={{
                            width: 71,
                            display: "flex",
                            justifyContent: "space-around"
                        }}>
                            <Skeleton sx={styles.smallIcon} variant={'rectangular'} animation={'wave'}/>
                            <Skeleton sx={styles.size} variant={'rectangular'} animation={'wave'}/>
                        </Box>
                    </Box>
                }
            />
            <Skeleton sx={styles.offerImage} variant={'rectangular'} animation={'wave'}/>
            <CardContent sx={styles.cardContent}>
                <Box>
                    <Skeleton sx={styles.title} variant={'rectangular'} animation={'wave'}/>
                </Box>
                <Box sx={styles.description}>
                    <Skeleton sx={styles.fullRaw} variant={'rectangular'} animation={'wave'}/>
                    <Skeleton sx={styles.fullRaw} variant={'rectangular'} animation={'wave'}/>
                    <Skeleton sx={styles.halfRaw} variant={'rectangular'} animation={'wave'}/>
                </Box>
                <Box sx={styles.descriptionContentBox}>
                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <Skeleton sx={styles.bigIcon} variant={'rectangular'} animation={'wave'}/>
                        <Skeleton sx={styles.bigIcon} variant={'rectangular'} animation={'wave'}/>
                    </Box>
                    <Skeleton sx={styles.button} variant={'rectangular'}
                              animation={'wave'}/>
                </Box>
            </CardContent>
        </Card>
    )
}

export default OfferSkeleton;