import storage from 'services/storage';

const notification = {};

const pushNotification = notification => {
    const notifications = storage.get('notifications', []);
    notifications.push(notification);

    storage.set('notifications', notifications);
};

const removeNotification = notification => {
    let notifications = storage.get('notifications', []);
    notifications = notifications.filter(n => n.id !== notification.id);

    storage.set('notifications', notifications);
};

notification.success = (message) => {
    notification.show('success', message);
};

notification.warning = (message) => {
    notification.show('warning', message);
};

notification.error = (message) => {
    notification.show('error', message);
};

notification.info = (message) => {
    notification.show('info', message);
};

notification.show = (type, message) => {
    pushNotification({
        id: _.uniqueId('notification'),
        type: type,
        message: message,
    });
};

notification.remove = (notification) => {
    removeNotification(notification);
};

export default notification;